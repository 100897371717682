import { useState, useEffect } from "react";
import SmartEditor from "../../components/SmartEditor/SmartEditor";
import "./Update.css";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const Update = () => {
  const [title, setTitle] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [attachFile, setAttachFile] = useState(null);
  const [content, setContent] = useState("");
  const location = useLocation();
  const navigator = useNavigate();
  const [selectedOption, setSelectedOption] = useState("");

  const { id } = useParams();
  const { category } = location.state || {};

  useEffect(() => {
    const fetchData = async () => {
      try {
        let getApi;
        if (category) {
          switch (category) {
            case "announce":
              getApi = `https://forcatmung.kr/api/notice/detail?noticeid=${id}`;
              setSelectedOption("option1");
              break;
            case "news":
              getApi = `https://forcatmung.kr/api/news/detail?newsid=${id}`;
              setSelectedOption("option2");

              break;
            case "uselist":
              getApi = `https://forcatmung.kr/api/dntExpense/detail?dntExpenseid=${id}`;
              setSelectedOption("option3");

              break;
            default:
              setSelectedOption("");
              return;
          }
        }
        const response = await axios.get(getApi);
        const data = response.data;

        setTitle(data.title);
        setContent(data.content);
      } catch (error) {
        console.error("데이터 가져오기 실패", error);
      }
    };

    fetchData();
  }, [category, id]);

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!title || !content || !selectedOption) {
      alert("제목, 내용, 카테고리를 모두 입력해주세요");
      return;
    }

    const isConfirmed = window.confirm("수정하시겠습니까?");

    if (isConfirmed) {
      const formData = new FormData();
      const jsonData = { title: title, content: content };
      if (imageFile) formData.append("imageData", imageFile);
      if (attachFile) formData.append("attachData", attachFile);

      let apiUrl;
      switch (selectedOption) {
        case "option1":
          apiUrl = `https://forcatmung.kr/api/notice/update?noticeid=${id}`;
          formData.append(
            "notice",
            new Blob([JSON.stringify(jsonData)], { type: "application/json" })
          );
          break;
        case "option2":
          apiUrl = `https://forcatmung.kr/api/news/update?newsid=${id}`;
          formData.append(
            "news",
            new Blob([JSON.stringify(jsonData)], { type: "application/json" })
          );
          break;
        case "option3":
          apiUrl = `https://forcatmung.kr/api/dntExpense/update?dntExpenseid=${id}`;
          formData.append(
            "dntExpense",
            new Blob([JSON.stringify(jsonData)], { type: "application/json" })
          );
          break;
        default:
      }

      try {
        const response = await axios.put(apiUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("게시글 수정 성공", response.data);
        switch (selectedOption) {
          case "option1":
            navigator("/announce/list");
            window.scrollTo(0, 0);
            break;
          case "option2":
            navigator("/news/list");
            window.scrollTo(0, 0);
            break;
          case "option3":
            navigator("/uselist/list");
            window.scrollTo(0, 0);
            break;
          default:
        }
      } catch (error) {
        console.error("수정 실패", error);
        alert("수정에 실패하였습니다.");
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <main className="update-main">
        <div className="update-header">
          <h2>게시글 수정</h2>
        </div>
        <div className="update-category">
          <select
            className="update-select"
            value={selectedOption}
            onChange={handleSelectChange}
          >
            <option value="" disabled>
              게시판 선택
            </option>
            <option value="option1" className="update-select-option">
              공지사항
            </option>
            <option value="option2" className="update-select-option">
              센터소식
            </option>
            <option value="option3" className="update-select-option">
              후원내역
            </option>
          </select>
        </div>
        <div className="update-title">
          <p>제목</p>
          <textarea
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="제목을 입력해주세요."
          />
        </div>
        <div className="update-img-select">
          <p>대표 이미지 선택</p>
          <input
            type="file"
            id="imageInput"
            accept="image/*"
            onChange={(e) => setImageFile(e.target.files[0])}
            placeholder="이미지 선택"
          />
        </div>
        <p style={{ fontSize: "12px" }}>&#8251;10MB이하의 사진만 넣어주세요.</p>
        <div className="update-attachment-select">
          <p>첨부파일 선택</p>
          <input
            type="file"
            id="attachmentInput"
            onChange={(e) => setAttachFile(e.target.files[0])}
            placeholder="이미지 선택"
          />
        </div>
        <p style={{ fontSize: "12px" }}>
          &#8251;대표 이미지와 첨부파일은 교체를 원할시 넣어주세요.
          <br />
          선택하지 않으면 기존의 파일로 적용됩니다.
        </p>
        <div className="update-content">
          <p>내용</p>
          <SmartEditor initialContent={content} onContentChange={setContent} />
        </div>
      </main>
    </form>
  );
};

export default Update;
