const CompanySponsorshipBanner = () => {
  return (
    <section className="company-sponsorship-banner">
      <div className="company-sponsorship-banner__contant">
        <h1 className="company-sponsorship-banner__title">기업후원</h1>
        <h2 className="company-sponsorship-banner__title company-sponsorship-banner__title--sub">
          유기동물들의 복지 개선을 위해
          <br />
          기업들의 따뜻한 손길을 기다립니다.
        </h2>
      </div>
    </section>
  );
};

export default CompanySponsorshipBanner;
