import React, { useEffect, useState } from 'react';
import './FanClubInputComponent.css';
import Modal from '../MyPage/Modal';
import FanClubDonateConsentContent from './FanClubDonateConsentContent';
import FanClubDonateTermsContent from './FanClubDonateTermsContent';

const FanClubInputComponent = ({ onDataChange, handleNextButtonState }) => {
    const [formData, setFormData] = useState({
        certificateName: '',
        certificateDate: '',
        story: '',
        donationType: '계좌입금',
        payerName: '',
        paymentDate: { year: '', month: '', day: '' },
        email: { username: '', domain: 'naver.com' },
        phone: { prefix: '010', middle: '', last: '' },
    });
    const [consent, setConsent] = useState(false);
    const [terms, setTerms] = useState(false);
    const [isConsentModalOpen, setConsentModalOpen] = useState(false);
    const [isTermsModalOpen, setTermsModalOpen] = useState(false);

    // paymentDate에 현재 날짜를 기본값으로 설정
    useEffect(() => {
        const today = new Date();
        const currentYear = today.getFullYear();
        const currentMonth = today.getMonth() + 1; // getMonth는 0부터 시작하므로 1을 더함
        const currentDay = today.getDate();

        // 날짜가 비어있으면 현재 날짜로 설정
        setFormData((prev) => ({
            ...prev,
            paymentDate: {
                year: prev.paymentDate.year || currentYear,
                month: prev.paymentDate.month || currentMonth,
                day: prev.paymentDate.day || currentDay,
            },
        }));
    }, []);

    useEffect(() => {
        const formattedData = {
            ...formData,
            paymentDate: `${formData.paymentDate.year}-${String(formData.paymentDate.month).padStart(2, '0')}-${String(formData.paymentDate.day).padStart(2, '0')}`,
            phone: `${formData.phone.prefix}-${formData.phone.middle}-${formData.phone.last}`,
            email: `${formData.email.username}@${formData.email.domain}`,
        };
        onDataChange(formattedData);

        const isFormValid =
            formData.donationType &&
            formData.payerName &&
            formData.paymentDate.year &&
            formData.paymentDate.month &&
            formData.paymentDate.day &&
            formData.email.username &&
            formData.email.domain &&
            formData.phone.prefix &&
            formData.phone.middle &&
            formData.phone.last;
        //동의 체크 여부를 전달
        handleNextButtonState(consent && terms, isFormValid);
    }, [formData, onDataChange, handleNextButtonState, consent, terms]);

    //날짜 드롭다운을 위한 배열 생성
    const years = Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i);
    const months = Array.from({ length: 12 }, (_, i) => i + 1);
    const days = Array.from({ length: 31 }, (_, i) => i + 1);

    //이메일 도메인 드롭다운 배열
    const emailDomains = ['naver.com', 'gmail.com', 'daum.net', 'hanmail.net'];

    //핸드폰 앞자리 드롭다운 배열
    const phonePrefixes = ['010', '011', '016', '017', '018', '019'];

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name.includes('.')) {
            const [parent, child] = name.split('.');
            setFormData((prev) => {
                const updatedData = {
                    ...prev,
                    [parent]: { ...prev[parent], [child]: value },
                };
                return updatedData;
            });
        } else {
            setFormData((prev) => {
                const updatedData = { ...prev, [name]: value };
                return updatedData;
            });
        }
    };

    //MARK: 이용약관 모달창 여닫음 관리
    const handleConsentModalOpen = () => setConsentModalOpen(true);
    const handleTermsModalOpen = () => setTermsModalOpen(true);
    const handleModalClose = () => {
        setConsentModalOpen(false);
        setTermsModalOpen(false);
    };

    return (
        <div>
            <div className="input-form-container">
                <hr />
                {/*MARK: 후원증서 정보 기입 */}
                <div className="input-sponsorship-certificate-container">
                    <h1>후원 증서를 위한 정보 기입</h1>
                    <form>
                        <div className="sponsorship-certificate-form-row">
                            <label>증서에 기재될 이름</label>
                            <textarea
                                name="certificateName"
                                value={formData.certificateName}
                                onChange={handleChange}
                                placeholder="스타이름 또는 팬클럽 이름"
                            />
                        </div>
                        <div className="sponsorship-certificate-form-row">
                            <label>증서에 기재될 날짜</label>
                            <textarea
                                name="certificateDate"
                                value={formData.certificateDate}
                                onChange={handleChange}
                                placeholder="스타 생일 혹은 기념일"
                            />
                        </div>
                        <div className="sponsorship-certificate-form-row">
                            <label>후원 사연</label>
                            <textarea
                                name="story"
                                value={formData.story}
                                onChange={handleChange}
                                className="sponsorship-large-input"
                                placeholder="후원사연을 입력해주세요."
                            />
                        </div>
                    </form>
                </div>
                <hr />
                <div className="input-sponsorship-check-container">
                    <h1>후원 확인을 위한 정보 기입</h1>
                    <form>
                        {/*MARK: 후원금액 */}
                        <div className="sponsorship-check-form-row">
                            <label>후원금액</label>
                            <div className="sponsorship-check-button-container">
                                <button
                                    type="button"
                                    className={`sponsorship-check-toggle-button ${formData.donationType === '계좌입금' ? 'active' : ''}`}
                                    onClick={() => setFormData((prev) => ({ ...prev, donationType: '계좌입금' }))}>
                                    계좌 입금
                                </button>
                                <button
                                    type="button"
                                    className={`sponsorship-check-toggle-button ${formData.donationType === '일시후원' ? 'active' : ''}`}
                                    onClick={() => setFormData((prev) => ({ ...prev, donationType: '일시후원' }))}>
                                    일시 후원
                                </button>
                            </div>
                        </div>
                        {/*MARK: 입금자 성함 */}
                        <div className="sponsorship-check-form-row">
                            <label>입금자 성함</label>
                            <input
                                type="text"
                                name="payerName"
                                value={formData.payerName}
                                onChange={handleChange}
                                placeholder="정확하게 기입해주세요"
                                required
                            />
                        </div>
                        {/*MARK: 입금 날짜 */}
                        <div className="sponsorship-check-form-row">
                            <label>입금 날짜</label>
                            <div className="sponsorship-check-date-form">
                                <select name="paymentDate.year" value={formData.paymentDate.year} onChange={handleChange}>
                                    {years.map((year) => (
                                        <option key={year} value={year}>
                                            {year}년
                                        </option>
                                    ))}
                                </select>
                                <select name="paymentDate.month" value={formData.paymentDate.month} onChange={handleChange}>
                                    {months.map((month) => (
                                        <option key={month} value={month}>
                                            {month}월
                                        </option>
                                    ))}
                                </select>
                                <select name="paymentDate.day" value={formData.paymentDate.day} onChange={handleChange}>
                                    {days.map((day) => (
                                        <option key={day} value={day}>
                                            {day}일
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        {/*MARK: 입금자 이메일 */}
                        <div className="sponsorship-check-form-row">
                            <label>입금자 이메일</label>
                            <div className="sponsorship-check-email-form">
                                <input
                                    type="text"
                                    name="email.username"
                                    value={formData.email.username}
                                    onChange={handleChange}
                                    placeholder="이메일을 작성해주세요"
                                />
                                <span>@</span>
                                <select name="email.domain" value={formData.email.domain} onChange={handleChange}>
                                    {emailDomains.map((domain) => (
                                        <option key={domain} value={domain}>
                                            {domain}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        {/*MARK: 입금자 연락처 */}
                        <div className="sponsorship-check-form-row">
                            <label>입금자 연락처</label>
                            <div className="sponsorship-check-phone-form">
                                <select name="phone.prefix" value={formData.phone.prefix} onChange={handleChange}>
                                    {phonePrefixes.map((prefix) => (
                                        <option key={prefix} value={prefix}>
                                            {prefix}
                                        </option>
                                    ))}
                                </select>
                                -
                                <input
                                    type="number"
                                    name="phone.middle"
                                    value={formData.phone.middle}
                                    onChange={handleChange}
                                    maxLength="4"
                                />
                                -
                                <input
                                    type="number"
                                    name="phone.last"
                                    value={formData.phone.last}
                                    onChange={handleChange}
                                    maxLength="4"
                                />
                            </div>
                        </div>
                    </form>
                </div>
                <hr />

                {/*MARK: 동의사항 체크여부 */}
                <div className="sponsorship-consent-container">
                    <div className="consent-checkbox">
                        <input
                            type="checkbox"
                            name="terms"
                            checked={terms}
                            onChange={(e) => setTerms(e.target.checked)}
                        />
                        <label>[필수] 이용약관 동의 <span onClick={handleTermsModalOpen}>[보기]</span></label>
                    </div>
                    <div className="consent-checkbox">
                        <input
                            type="checkbox"
                            name="consent"
                            checked={consent}
                            onChange={(e) => setConsent(e.target.checked)}
                        />
                        <label>[필수] 개인정보처리방침 동의 <span onClick={handleConsentModalOpen}>[보기]</span></label>
                    </div>

                    {/*MARK: 동의사항 모달 컴포넌트 */}
                    <Modal isOpen={isTermsModalOpen} onClose={handleModalClose}>
                        <FanClubDonateTermsContent />
                    </Modal>
                    <Modal isOpen={isConsentModalOpen} onClose={handleModalClose}>
                        <FanClubDonateConsentContent />
                    </Modal>
                </div>
            </div>
        </div>

    );
};

export default FanClubInputComponent;
