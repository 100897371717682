import { useLocation } from 'react-router-dom';
import './OneToManyPaginationComponent.css'
import React from 'react';

function OneToManyPaginationComponent({ currentPage, totalPages, onPageChange }) {
    const location = useLocation();
    // 특정 경로에서만 버튼 표시
    const shouldShowButton = location.pathname.startsWith('/mypage/') || location.pathname.startsWith('/customdonation/');

    const handlePageClick = (page) => {
        if (page >= 1 && page <= totalPages) {
            onPageChange(page);
            if (!shouldShowButton) return window.scrollTo(0, 0);
        }
    };

    return (
        <div className="pagination">
            {/* 이전 페이지 버튼 */}
            {currentPage > 1 && (
                <button onClick={() => handlePageClick(currentPage - 1)}>
                    <span className='arrow'>&lt;</span>
                </button>
            )}
            {Array.from({ length: totalPages }, (_, index) => (
                <button
                    key={index}
                    onClick={() => handlePageClick(index + 1)} s
                    className={currentPage === index + 1 ? 'active' : ''}
                >
                    {index + 1}
                </button>
            ))}
            {/* 다음 페이지 버튼 */}
            {currentPage < totalPages && (
                <button onClick={() => handlePageClick(currentPage + 1)}>
                    <span className='arrow'>&gt;</span>
                </button>
            )}
        </div>
    );
}

export default OneToManyPaginationComponent;
