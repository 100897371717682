import HistoryHeader from "./HistoryHeader";
import MyPageTable from "./MyPageTable";
import usePagination from "./UsePagination";
import useResponsivePage from "./UseResponsivePage";

const OneTimeDonation = () => {
    const headers = ['번호', '후원금 결제 일시', '결제방법', '결제금액'];
    const allData = [
        ['1', '2024-12-23', '카드결제', '220,000원'],
        ['2', '2023-12-23', '카드결제', '110,000원'],
        ['3', '2023-12-23', '카드결제', '110,000원'],
        ['4', '2024-12-23', '카드결제', '220,000원'],
        ['5', '2024-12-23', '카드결제', '220,000원'],
        ['6', '2024-12-23', '계좌이체', '220,000원'],
        ['7', '2024-12-23', '계좌이체', '220,000원'],
        ['8', '2024-12-23', '계좌이체', '220,000원'],
        ['9', '2024-12-23', '계좌이체', '220,000원'],
    ];
    const itemsPerPage = useResponsivePage(8, 5)

    const { currentData, currentPage, totalPages, handlePageChange } = usePagination(allData, itemsPerPage);

    const handleCellClick = (rowIndex, cellIndex, cellValue) => {
        console.log(`Row: ${rowIndex}, Cell: ${cellIndex}, Value: ${cellValue}`);
    };

    return (
        <div>
            <HistoryHeader donateType={"일시 후원내역"} />
            <MyPageTable
                headers={headers}
                data={currentData}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                totalPage={totalPages}
                onPageChange={handlePageChange}
                onCellClick={handleCellClick}
            />
        </div>
    );
};
export default OneTimeDonation;