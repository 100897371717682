import React from 'react'
import './EditOneToMany.css'
import SmartEditor from '../../SmartEditor/SmartEditor'

const EditOneToMany = () => {
    return (
        <div className='edit-one-to-many-container'>
            <p className='edit-one-to-many-page-text'>1:N 후원 글 작성 및 수정 페이지 입니다.</p>
            <SmartEditor />
        </div>
    )
}

export default EditOneToMany