import React from 'react'
import './FanClubModalFrame.css'

const FanClubModalFrame = ({ children, onClose, onNext, onPrev, currentStep, inputData, isChecked, isValid }) => {
    const handleNext = () => {
        if (currentStep === 2) {
            if (!isChecked && !isValid) {
                alert('입력해주세요');
            } else if (!isChecked) {
                alert('모두 동의해주세요');
            } else if (!isValid) {
                alert('입력값을 확인해주세요');
            } else {
                // 서버에 데이터 전송
                onNext();
            }
        } else {
            onNext(); // 일반적인 단계 이동
        }
    };

    return (
        <div className='frame-container'>
            {!(currentStep === 3) && <div className='frame-header-container'>
                <div className='frame-header-image'>
                </div>
                <div className='frame-header-text'>
                    <h1>스타 팬클럽 후원이란?</h1>
                    <h3>스타의 특별한 날 동물보호에 팬클럽분들과 함께 동참하는 기념하는 후원입니다.</h3>
                </div>
            </div>}
            <div className='frame-body-container'>
                {children}
            </div>
            <div className='frame-button-container'>
                {currentStep === 1 && <button className='button close' onClick={onClose}>닫기</button>}
                {currentStep === 2 && <button className='button close' onClick={onPrev}>이전</button>}
                {currentStep !== 3 && <button className='button next' onClick={handleNext}>다음으로</button>}
            </div>
        </div>
    )
}

export default FanClubModalFrame             