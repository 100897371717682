import React from 'react';

const FanClubDonateConsentContent = () => {
    return (
        <div style={{
            width: 'clamp(200px, 30vw, 400px)',
            height: '500px',
            overflow: 'auto',
            padding: '10px',
            backgroundColor: '#f9f9f9',
            borderRadius: '8px',
            border: '1px solid #ddd'
        }}>
            <h2 style={{ margin: '20px auto', fontSize: 'clamp(12px, 5vw, 22px)', textAlign: 'center', fontWeight: 700 }}>개인정보처리방침</h2>
            <p>제1조 (개인정보의 수집 및 이용 목적)<br />
                회사는 이용자의 개인정보를 다음의 목적을 위해 수집하고 이용합니다.<br />
                - 기부 인증 및 후원 서비스 제공<br />
                - 고객문의 응대 및 서비스 개선
            </p>
            <br />
            <p>제2조 (수집하는 개인정보 항목)<br />
                회사는 서비스 이용을 위해 다음과 같은 개인정보를 수집할 수 있습니다.<br />
                - 이름, 이메일, 연락처, 후원 내역
            </p>
            <br />
            <p>제3조 (개인정보의 보유 및 이용 기간)<br />
                회사는 수집된 개인정보를 이용 목적이 달성될 때까지 보유하며, 법령에 따라 일정 기간 보관이 필요한 경우 해당 기간 동안 보관합니다.
            </p>
            <br />
            <p>제4조 (개인정보의 파기)<br />
                회사는 개인정보의 이용 목적이 달성된 후에는 지체 없이 해당 정보를 파기합니다.
            </p>
            <br />
            <p>제5조 (이용자의 권리)<br />
                이용자는 자신의 개인정보에 대해 열람, 정정, 삭제를 요청할 권리가 있습니다. 이에 대한 요청은 회사의 고객센터를 통해 가능합니다.
            </p>
        </div>
    );
};

export default FanClubDonateConsentContent;
