import { useEffect } from "react";

const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <h1 style={{ justifyContent: "center" }}>정관</h1>
      <p>2024. 9. 8. 제정</p>
      <h2>제1장 총 칙</h2>
      <h3>제1조(명칭)</h3>
      <p>
        이 법인은 “사단법인 포캣멍센터”(이하 “본회”라 한다)라 하며, 영문명은
        “Forcatmung Center(약칭 : FCM)”으로 한다.
      </p>
      <h3>제2조(소재지)</h3>
      <p>
        본회의 사무소는 경기도 안산시 상록구 광덕1로 369, 센타프라자 2층 205호에
        둔다.
      </p>
      <h3>제3조(목적)</h3>
      <p>
        본회는 본 법인은 「민법」제3장 및 「농림축산식품부장관 및 그 소속청장
        소관 비영리법인의 설립 및 감독에 관한 규칙」에 의거 생명존중의 정신으로
        지역사회에서 동물복지 증진을 위한 활동으로 사람과 동물이 함께하는 행복한
        공존에 이바지힘을 목적으로 한다.
      </p>
      <h3>제4조(사업)</h3>
      <p>본회는 제3조의 목적을 달성하기 위하여 다음의 사업을 한다.</p>
      <ol>
        <li>유기동물의 구조, 보호, 입양 사업</li>
        <li>길고양이 돌봄 사업</li>
        <li>동물보호·복지 관련 정책개선 제안, 홍보 및 교육사업</li>
        <li>법인의 목적 달성에 필요한 경비 마련을 위한 바자회 개최사업</li>
        <li>동물보호단체 및 지자체들과의 협력 및 교류</li>
        <li>기타 본회의 목적달성에 필요한 사업</li>
      </ol>
      <h2>제2장 회 원</h2>
      <h3>제5조(회원의 자격)</h3>
      <p>
        본회의 회원은 본회의 설립취지에 찬동하고 소정의 입회신청서를 제출하여
        이사회의 승인을 얻은 자로 한다.
      </p>
      <h3>제6조(회원의 권리)</h3>
      <p>회원은 총회를 통하여 본회의 운영에 참여할 권리를 가진다.</p>
      <h3>제7조(회원의 의무)</h3>
      <p>회원은 다음의 의무를 진다.</p>
      <ol>
        <li>본회의 정관 및 제 규약의 준수</li>
        <li>총회 및 이사회의 결의사항 이행</li>
        <li>회비 및 제 부담금의 납부</li>
      </ol>
      <h3>제8조(회원의 탈퇴)</h3>
      <p>회원은 이사장에게 탈퇴서를 제출함으로써 자유롭게 탈퇴할 수 있다.</p>
      <h3>제9조(회원의 상벌)</h3>
      <ol>
        <li>
          본회의 회원으로서 본회의 발전에 기여한 자에 대하여는 이사회의 의결을
          거쳐 포상할 수 있다.
        </li>
        <li>
          본회의 회원으로서 본회의 목적에 위배되는 행위 또는 명예와 위신에
          손상을 가져오는 행위를 하거나 제7조의 의무를 이행하지 아니한 자에
          대하여는 이사회 또는 총회의 의결을 거쳐 이사장이 제명·견책 등의 징계를
          할 수 있다.
        </li>
      </ol>
      <h2>제3장 임 원</h2>
      <section>
        <h3>제10조(임원의 종류와 정수)</h3>
        <p>본회는 다음의 임원을 둔다.</p>
        <ol>
          <li>이사장 1인</li>
          <li>이사 5인(이사장을 포함한다)</li>
          <li>감사 1인</li>
        </ol>
      </section>
      <section>
        <h3>제11조(임원의 선임)</h3>
        <ol>
          <li>
            ① 임원은 총회에서 선출하고, 그 취임에 관하여 지체 없이 주무관청에
            보고하여야 한다.
          </li>
          <li>
            ② 임원의 보선은 결원이 발생한 날로부터 2개월 이내에 하여야 한다.
          </li>
          <li>③ 새로운 임원의 선출은 임기 만료 2개월 전까지 하여야 한다.</li>
        </ol>
      </section>
      <section>
        <h3>제12조(임원의 해임)</h3>
        <p>
          임원이 다음 각호의 1에 해당하는 행위를 한 때에는 총회의 의결을 거쳐
          해임할 수 있다.
        </p>
        <ol>
          <li>본회의 목적에 위배되는 행위</li>
          <li>임원 간의 분쟁·회계부정 또는 현저한 부당행위</li>
          <li>본회의 업무를 방해하는 행위</li>
        </ol>
      </section>
      <section>
        <h3>제13조(임원의 선임 제한)</h3>
        <ol>
          <li>
            ① 임원의 선임에 있어서 이사 상호간에 민법 제777조에 규정된
            친족관계에 있는 자가 이사정수의 1/5를 초과할 수 없다.
          </li>
          <li>
            ② 감사는 감사 상호간 또는 이사와 민법 제777조에 규정된 친족관계가
            없어야 한다.
          </li>
        </ol>
      </section>
      <section>
        <h3>제14조(상임이사)</h3>
        <ol>
          <li>
            ① 본회의 목적사업을 전담하게 하기 위하여 상임이사를 둘 수 있다.
          </li>
          <li>
            ② 상임이사는 이사회의 의결을 거쳐 이사장이 이사중에서 선임한다.
          </li>
        </ol>
      </section>
      <section>
        <h3>제15조(임원의 임기)</h3>
        <ol>
          <li>① 임원의 임기는 3년으로 하며, 연임할 수 있다.</li>
          <li>
            ② 보선에 의하여 취임한 임원의 임기는 전임자의 잔여기간으로 한다.
          </li>
        </ol>
      </section>
      <section>
        <h3>제16조(임원의 직무)</h3>
        <ol>
          <li>
            ① 이사장은 본회를 대표하고 본회의 업무를 총괄하며, 총회 및 이사회의
            의장이 된다.
          </li>
          <li>
            ② 이사는 이사회에 출석하여 본회의 업무에 관한 사항을 의결하며 이사회
            또는 이사장으로부터 위임받은 사항을 처리한다.
          </li>
          <li>
            ③ 감사는 다음의 직무를 행한다.
            <ol>
              <li>본회의 재산 상황을 감사하는 일</li>
              <li>총회 및 이사회의 운영과 그 업무에 관한 사항을 감사하는 일</li>
              <li>
                부정 또는 부당한 점 발견 시 시정을 요구하고 주무관청에 보고하는
                일
              </li>
              <li>필요 시 총회 또는 이사회의 소집을 요구하는 일</li>
              <li>재산 상황과 업무에 관해 의견을 진술하는 일</li>
            </ol>
          </li>
        </ol>
      </section>
      <section>
        <h3>제17조(이사장의 직무대행)</h3>
        <ol>
          <li>
            ① 이사장이 사고가 있을 때는 이사장이 지명하는 이사가 이사장의 직무를
            대행한다.
          </li>
          <li>
            ② 이사장이 궐위 시에는 이사회에서 선출된 이사가 이사장의 직무를
            대행한다.
          </li>
          <li>③ 직무대행자는 지체 없이 이사장 선출의 절차를 밟아야 한다.</li>
        </ol>
      </section>
      <section>
        <h2>제4장 총 회</h2>
        <article>
          <h3>제18조(총회의 구성)</h3>
          <p>총회는 본회의 최고의결기관이며 회원으로 구성한다.</p>
        </article>
        <article>
          <h3>제19조(구분 및 소집)</h3>
          <ol>
            <li>
              총회는 정기총회와 임시총회로 구분하며, 이사장이 이를 소집한다.
            </li>
            <li>
              정기총회는 매 회계연도 개시 후 2개월 이내에 개최하며, 임시총회는
              이사장이 필요하다고 인정할 때 소집한다.
            </li>
            <li>
              총회의 소집은 이사장이 회의 안건·일시·장소 등을 명기하여 회의 개시
              7일 전까지 문서로 각 회원에게 통지하여야 한다.
            </li>
          </ol>
        </article>
        <article>
          <h3>제20조(총회소집의 특례)</h3>
          <ol>
            <li>
              이사장은 다음 각 호의 1에 해당하는 소집요구가 있을 때에는 그 소집
              요구일로부터 14일 이내에 총회를 소집하여야 한다.
              <ol>
                <li>
                  재적이사 과반수가 회의의 목적을 제시하여 소집을 요구한 때
                </li>
                <li>
                  제16조 제3항 제4호의 규정에 의하여 감사가 소집을 요구한 때
                </li>
                <li>
                  재적회원 5분의 1 이상이 회의의 목적을 제시하여 소집을 요구한
                  때
                </li>
              </ol>
            </li>
            <li>
              총회 소집권자가 궐위되거나 이를 기피함으로써 7일 이상 총회소집이
              불가능한 때에는 재적이사 과반수 또는 재적회원 3분의 1 이상의
              찬성으로 총회를 소집할 수 있다.
            </li>
            <li>
              제2항의 규정에 의한 총회는 출석이사 중 최연장자의 사회 아래 그
              의장을 선출한다.
            </li>
          </ol>
        </article>
        <article>
          <h3>제21조(의결정족수)</h3>
          <ol>
            <li>
              총회는 재적회원 과반수의 출석으로 개의하고 출석회원 과반수의
              찬성으로 의결한다.
            </li>
            <li>
              회원의 의결권은 총회에 참석하는 다른 회원에게 위임할 수 있다. 이
              경우 위임장은 총회 개시 전까지 의장에게 제출하여야 한다.
            </li>
          </ol>
        </article>
        <article>
          <h3>제22조(총회의 기능)</h3>
          <p>총회는 다음의 사항을 의결한다.</p>
          <ol>
            <li>임원의 선출 및 해임에 관한 사항</li>
            <li>본회의 해산 및 정관변경에 관한 사항</li>
            <li>법인 재산의 처분 및 취득과 자금의 차입에 관한 사항</li>
            <li>예산 및 결산의 승인</li>
            <li>사업계획의 승인</li>
            <li>기타 중요사항 및 이사회에서 부의한 사항</li>
          </ol>
        </article>
        <article>
          <h3>제23조(총회의결 제척사유)</h3>
          <ol>
            <li>임원의 선출 및 해임에 있어 자신에 관한 사항을 의결할 때</li>
            <li>
              금전 및 재산의 수수 또는 소송 등에 관련되는 사항으로서 자신과
              본회의 이해가 상반될 때
            </li>
          </ol>
        </article>
      </section>
      <section>
        <h2>제5장 이 사 회</h2>
        <article>
          <h3>제24조(이사회의 구성)</h3>
          <p>이사회는 이사장과 이사로 구성한다.</p>
        </article>
        <article>
          <h3>제25조(구분 및 소집)</h3>
          <ol>
            <li>
              이사회는 정기이사회와 임시이사회로 구분하며, 이사장이 이를
              소집한다.
            </li>
            <li>
              정기이사회는 매 회계연도 개시 후 2개월까지 소집하며, 임시이사회는
              이사장이 필요하다고 인정할 때에 소집한다.
            </li>
            <li>
              이사회의 소집은 이사장이 회의 안건·일시·장소 등을 명기하여 회의
              개시 7일 전까지 문서로 각 이사 및 감사에게 통지하여야 한다.
            </li>
          </ol>
        </article>
        <article>
          <h3>제26조(이사회 소집의 특례)</h3>
          <ol>
            <li>
              이사장은 다음 각 호의 1에 해당하는 소집요구가 있는 때에는 그 소집
              요구일로부터 20일 이내에 이사회를 소집하여야 한다.
              <ol>
                <li>
                  재적이사 과반수가 회의의 목적을 제시하여 소집을 요구한 때
                </li>
                <li>
                  제16조 제3항 제4호의 규정에 의하여 감사가 소집을 요구한 때
                </li>
              </ol>
            </li>
            <li>
              이사회 소집권자가 궐위되거나 이를 기피함으로써 7일 이상 이사회
              소집이 불가능할 때에는 재적이사 과반수의 찬성으로 이사회를 소집할
              수 있다.
            </li>
            <li>
              제2항의 규정에 의한 이사회는 출석이사 중 최연장자의 사회 아래 그
              의장을 선출한다.
            </li>
          </ol>
        </article>
        <article>
          <h3>제27조(서면결의)</h3>
          <ol>
            <li>
              이사장은 이사회에 부의할 사항 중 경미한 사항 또는 긴급을 요하는
              사항에 관하여는 이를 서면으로 의결할 수 있다. 이 경우에 이사장은
              그 결과를 차기 이사회에 보고하여야 한다.
            </li>
            <li>
              제1항의 서면결의 사항에 대하여 재적이사 과반수가 이사회에 부의할
              것을 요구하는 때에는 이사장은 이에 따라야 한다.
            </li>
          </ol>
        </article>
        <article>
          <h3>제28조(의결정족수)</h3>
          <ol>
            <li>
              이사회는 재적이사 과반수의 출석으로 개의하고 출석이사 과반수의
              찬성으로 의결한다. 다만, 가부동수인 경우에는 의장이 결정한다.
            </li>
            <li>이사회의 의결권은 위임할 수 없다.</li>
          </ol>
        </article>
        <article>
          <h3>제29조(이사회의 의결사항)</h3>
          <p>이사회는 다음의 사항을 심의·의결한다.</p>
          <ol>
            <li>업무집행에 관한 사항</li>
            <li>사업계획의 운영에 관한 사항</li>
            <li>예산·결산서의 작성에 관한 사항</li>
            <li>정관변경에 관한 사항</li>
            <li>재산관리에 관한 사항</li>
            <li>총회에 부의할 안건의 작성</li>
            <li>총회에서 위임받은 사항</li>
            <li>정관의 규정에 의하여 그 권한에 속하는 사항</li>
            <li>기타 본회의 운영상 중요하다고 이사장이 부의하는 사항</li>
          </ol>
        </article>
      </section>
      <section>
        <h2>제6장 재산과 회계</h2>
        <article>
          <h3>제30조(재산의 구분)</h3>
          <p>① 본회의 재산은 다음과 같이 기본재산과 보통재산으로 구분한다.</p>
          <ol>
            <li>
              기본재산은 본회 설립시 그 설립자가 출연한 재산과 이사회에서
              기본재산으로 정한 재산으로 하며, 그 목록은 “별지 1”과 같다.
            </li>
            <li>보통재산은 기본재산 이외의 재산으로 한다.</li>
          </ol>
        </article>
        <article>
          <h3>제31조(기본재산의 처분 등)</h3>
          <p>
            본회의 기본재산을 처분(매도․증여․교환을 포함한다)하고자 할 때에는
            제41조의 규정에 의한 정관변경 허가의 절차를 거쳐야 한다.
          </p>
        </article>
        <article>
          <h3>제32조(수입금)</h3>
          <p>
            본회의 수입금은 회원의 회비 및 기부금, 기타의 수입으로 충당한다.
          </p>
        </article>
        <article>
          <h3>제33조(회계연도)</h3>
          <p>본회의 회계연도는 정부의 회계연도에 따른다.</p>
        </article>
        <article>
          <h3>제34조(예산편성)</h3>
          <p>
            본회의 세입·세출 예산은 매 회계연도 개시 2개월 이내에 편성하여
            이사회의 의결을 거쳐 총회의 승인을 얻어 정한다.
          </p>
        </article>
        <article>
          <h3>제35조(결산)</h3>
          <p>
            본회는 매 회계연도 종료 후 2개월 이내에 결산서를 작성하여 이사회의
            의결을 거쳐 총회의 승인을 얻어야 한다.
          </p>
        </article>
        <article>
          <h3>제36조(회계감사)</h3>
          <p>감사는 회계감사를 연 1회 이상 실시하여야 한다.</p>
        </article>
        <article>
          <h3>제37조(회계의 공개)</h3>
          <ol>
            <li>
              본회의 예산과 결산은 이사회가 따로 정하는 방법에 따라 공개한다.
            </li>
            <li>
              연간 기부금 모금액 및 활용실적은 인터넷 홈페이지를 통해 매년 3월
              공개한다.
            </li>
          </ol>
        </article>
        <article>
          <h3>제38조(임원의 보수)</h3>
          <p>
            사업의 운영을 전담하는 상임이사를 제외한 임원에 대하여는 보수를
            지급하지 아니한다. 다만, 업무수행에 필요한 실비는 지급할 수 있다.
          </p>
        </article>
      </section>
      <section>
        <h2>제7장 사무부서</h2>
        <article>
          <h3>제39조(사무국)</h3>
          <ol>
            <li>
              이사장의 지시를 받아 본회의 사무를 처리하기 위하여 사무국을 둔다.
            </li>
            <li>사무국에 사무국장 1인과 필요한 직원을 둘 수 있다.</li>
            <li>사무국장은 이사회의 의결을 거쳐 이사장이 임면한다.</li>
            <li>
              사무국의 조직 및 운영에 관한 사항은 이사회의 의결을 거쳐 별도로
              정한다.
            </li>
          </ol>
        </article>
      </section>
      <section>
        <h2>제8장 보 칙</h2>
        <article>
          <h3>제40조(법인해산)</h3>
          <ol>
            <li>
              본회가 해산하고자 할 때에는 총회에서 재적회원 4분의 3 이상의
              찬성으로 의결하여 해산하고, 그 해산에 관하여 주무관청에 신고하여야
              한다.
            </li>
            <li>
              본회가 해산한 때의 잔여재산은 총회의 의결을 거쳐 국가,
              지방자치단체 또는 본회와 유사한 목적을 가진 다른 비영리법인에게
              귀속하도록 한다.
            </li>
          </ol>
        </article>
        <article>
          <h3>제41조(정관변경)</h3>
          <p>
            이 정관을 변경하고자 할 때에는 총회에서 재적회원 3분의 2 이상의
            찬성으로 의결하여 주무관청의 허가를 받아야 한다.
          </p>
        </article>
        <article>
          <h3>제42조(규칙제정)</h3>
          <p>
            이 정관에 정한 것 외에 본회의 운영에 관하여 필요한 사항은 이사회의
            의결을 거쳐 규칙으로 정한다.
          </p>
        </article>
      </section>
      <section>
        <h2>부 칙</h2>
        <article>
          <h3>제1조(시행일)</h3>
          <p>
            이 정관은 주무관청의 허가를 받아 법원에 등기를 한 날부터 시행한다.
          </p>
        </article>
      </section>
    </div>
  );
};
export default Terms;
