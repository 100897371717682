import React from 'react'

const AdoptionPhotographyConsent = () => {
    return (
        <div style={{ width: 'clamp(200px, 30vw, 400px)', height: '500px', overflow: 'auto', padding: '10px', backgroundColor: '#f9f9f9', borderRadius: '8px', border: '1px solid #ddd' }}>
            <h2 style={{ margin: '20px auto', fontSize: 'clamp(12px, 4vw, 22px)', textAlign: 'center' }}>촬영 및 콘텐츠 업로드 동의서</h2>
            <p style={{ fontSize: 'clamp(12px, 2vw, 20px)', margin: '20px auto' }}>
                본 동의서는 입양 과정 중 촬영된 사진 및 영상의 사용과 관련된 내용을 포함합니다.
                아래 내용을 숙지하시고 동의 여부를 신중히 결정해 주시기 바랍니다.
            </p>
            <br />
            <h3>1. 촬영 목적</h3>
            <p>
                촬영은 입양 과정을 기록하고, 서비스 제공자의 SNS 채널(유튜브, 인스타그램, 홈페이지 등)을 통해
                공유함으로써 입양 문화를 확산하고, 관련 정보를 제공하는 것을 목적으로 합니다.
            </p>
            <br />
            <h3>2. 활용 범위</h3>
            <p>
                - 촬영된 사진 및 영상은 유튜브, 인스타그램, 홈페이지 등 공식 채널에 업로드될 수 있습니다.<br />
                - 개인정보 보호를 위해 요청 시 모자이크 처리가 가능합니다.<br />
                - 콘텐츠는 입양 문화 홍보 및 기록 보관 이외의 목적으로 사용되지 않습니다.
            </p>
            <br />
            <h3>3. 동의 철회</h3>
            <p>
                - 촬영 및 업로드에 대한 동의는 언제든 철회할 수 있으며, 이 경우 해당 콘텐츠는 즉시 삭제 또는 비공개 처리됩니다.<br />
                - 철회 요청은 서비스 제공자의 고객센터를 통해 가능합니다.
            </p>
            <br />
            <h3>4. 책임의 한계</h3>
            <p>
                - 업로드된 콘텐츠는 공익적인 목적을 위해 사용되며, 서비스 제공자는 이용자의 명시적 동의 없이
                다른 용도로 사용하지 않습니다.<br />
                - 제3자에 의한 콘텐츠 무단 사용에 대해 서비스 제공자는 법적 책임을 지지 않습니다.
            </p>
            <br />
            <p style={{ fontWeight: 'bold', color: '#005be8', textAlign: 'center' }}>
                본 동의서의 내용을 모두 이해하였으며, 촬영 및 업로드에 동의합니다.
            </p>
        </div>
    );
}
export default AdoptionPhotographyConsent