import './AdoptionField.css'

const AdoptionPhoneField = ({ formData, setFormData }) => {

    const phonePrefixes = ['010', '011', '016', '017', '018', '019'];

    const handlePhoneChange = (e, part) => {
        const phoneParts = formData.carerPhone.split('-');
        phoneParts[part] = e.target.value;

        // 전화번호의 prefix가 비어있을 경우 기본값 '010'으로 설정
        if (part === 0 && !phoneParts[0]) {
            phoneParts[0] = '010';
        }

        setFormData((prevData) => ({
            ...prevData,
            carerPhone: phoneParts.join('-'),
        }));
    };

    return (
        <div className='adoption-phone-form-row'>
            <label>연락처</label>
            <select
                name="phone.prefix"
                value={formData.carerPhone.split('-')[0] || ''}
                onChange={(e) => handlePhoneChange(e, 0)}
            >
                {phonePrefixes.map((prefix) => (
                    <option key={prefix} value={prefix}>{prefix}</option>
                ))}
            </select>
            -
            <input
                type="number"
                name="phone.middle"
                value={formData.carerPhone.split('-')[1] || ''}
                onChange={(e) => handlePhoneChange(e, 1)}
                maxLength="4"
            />
            -
            <input
                type="number"
                name="phone.last"
                value={formData.carerPhone.split('-')[2] || ''}
                onChange={(e) => handlePhoneChange(e, 2)}
                maxLength="4"
            />
        </div>
    )
}

export default AdoptionPhoneField