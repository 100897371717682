const DonationBanner = () => {
  return (
    <>
      <section className="history-info">
        <div className="history-info__contant">
          <img src="/images/kangTe-nim.png" alt="강태님" className="history-info__image" />
          <div className="history-info__text-contant">
            <p className="history-info__description">
              노령, 장애, 각종 질병의 아이들에게는 <br />
              가족을 만날 수 있는 기회조차 어렵습니다.
              <br /> 아이들의 마지막 순간은 해피엔딩이 될 수 있도록
            </p>
            <h1 className="history-info__title">동물보호의 뜻을 함께 해주세요!</h1>
          </div>
        </div>
      </section>
    </>
  );
};

export default DonationBanner;
