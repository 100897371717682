import './AdoptionField.css'

const AdoptionCarerFamilyMemberField = ({ formData, setFormData }) => {
    return (
        <div className='adoption-family-form-row'>
            <label>가족구성원 <br />
                <strong >(반려동물 포함)</strong>
            </label>
            <textarea
                value={formData.carerFamilyMember}
                style={{}}
                placeholder='ex ) 아빠 42살 중학교 선생님 / 엄마(신청인) 39살 주부 / 아들 15살 중학생 / 딸 8살 초등학생 /
                                          강아지 말티즈 7살 / 고양이 코숏 2살'
                onChange={(e) => setFormData({ ...formData, carerFamilyMember: e.target.value })}
            />
        </div>
    )
}

export default AdoptionCarerFamilyMemberField