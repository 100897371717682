import DonationBanner from "./DonationBanner";

const AboutHistory = () => {
  return (
    <>
      <section className="history-timeline">
        <div class="timeline__line"></div>

        <div class="timeline__event timeline__event--right">
          <h3 class="timeline__date">2024 하반기</h3>
          <p class="timeline__description">6개 위탁보호소, 31마리 입보 및 입양활동</p>
          <div className="timeline-image__contant">
            <img src="/images/2024_1st.png" alt="timeline-img" className="timeline-image" />
          </div>
        </div>

        <div class="timeline__event timeline__event--left">
          <h3 class="timeline__date">2024. 09. 08</h3>
          <p class="timeline__description">사단법인 포캣멍센터 창립총회</p>
          <div className="timeline-image__contant">
            <img src="/images/2024_2nd.png" alt="timeline-img" className="timeline-image" />
          </div>
        </div>

        <div class="timeline__event timeline__event--right">
          <p class="timeline__description"></p>
        </div>

        <div class="timeline__event timeline__event--right">
          <h3 class="timeline__date">2024. 11. 07</h3>
          <p class="timeline__description">경기도청 '사단법인 포캣멍센터' 등기</p>
          <div className="timeline-image__contant">
            <img src="/images/2024_3rd_1.png" alt="timeline-img" className="timeline-image" />
          </div>
        </div>

        <div class="timeline__event timeline__event--right"></div>

        <div class="timeline__event timeline__event--left">
          <h3 class="timeline__date">2024. 11.</h3>
          <p class="timeline__description">경기도 동물 보호 정식구조 시작</p>
          <div className="timeline-image__contant">
            <img src="/images/2024_4th.png" alt="timeline-img" className="timeline-image" />
          </div>
        </div>

        <div class="timeline__event timeline__event--left">
          <div className="timeline-image__contant">
            <img src="/images/2024_5th.png" alt="timeline-img" className="timeline-image" />
          </div>
        </div>

        <div class="timeline__event timeline__event--right"></div>

        <div class="timeline__event timeline__event--right">
          <h3 class="timeline__date">2024. 12.</h3>
          <p class="timeline__description">
            경기도 6개시 위탁보호소
            <br /> 장애,노령 동물 4마리 보호인계
          </p>
          <div className="timeline-image__contant">
            <img src="/images/2024_6th.png" alt="timeline-img" className="timeline-image" />
          </div>
        </div>
      </section>
      <DonationBanner />
    </>
  );
};

export default AboutHistory;
