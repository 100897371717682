import React from 'react'
import './MyActivitySlider.css'

const MyActivitySlider = ({ sliderImage, catName, donateCategory, donation }) => {
    return (
        <div className='myactivity-slider-card-container'>
            <div className='myacitivity-slider-img-container'>
                <img src={sliderImage} alt={`Slide ${sliderImage}`} />
            </div>
            <h2>{catName}</h2>
            <div className='myactivity-slider-card-p-container'>
                <p>{donateCategory}후원</p>
                <p>(매달 {donation}만원)</p>
            </div>
        </div>
    )
}

export default MyActivitySlider