import content from './assets/body_content.png'

//TODO: 에디터에서 입력한 데이터가 어떤 형태로 들어오는지
const OneToManyBody = () => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '50px 0',
            }}>
            <img
                src={content}
                alt='내용'
                style={{
                    width: '60%',
                }}>
            </img>
        </div >
    );
}

export default OneToManyBody;