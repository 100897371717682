// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-one-to-many-container {
    margin-top: 10vw;
}

.edit-one-to-many-page-text {
    text-align: center;
    margin-bottom: 2vw;
    margin-top: -2.5vw;
    font-size: 1vw;
}`, "",{"version":3,"sources":["webpack://./src/components/Support/OneToMany/EditOneToMany.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,kBAAkB;IAClB,cAAc;AAClB","sourcesContent":[".edit-one-to-many-container {\n    margin-top: 10vw;\n}\n\n.edit-one-to-many-page-text {\n    text-align: center;\n    margin-bottom: 2vw;\n    margin-top: -2.5vw;\n    font-size: 1vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
