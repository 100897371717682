import React from "react";
import "./AdoptionHeader.css";

const AdoptionHeader = () => {
  return (
    <div>
      <div className="adoption-header-container">
        <p className="adoption-header-mini-text">
          아이들의 가족이 되어주실 분들을 찾습니다.
        </p>
        <p className="adoption-header-main-text">입양하기</p>
      </div>
      <hr className="adoption-header-hr" />
    </div>
  );
};

export default AdoptionHeader;
