import './AdoptionField.css'

const AdoptionCarerCommentField = ({ formData, setFormData }) => {
    return (
        <div className='adoption-comment-form-row'>
            <label>그 외 하실 말씀</label>
            <textarea
                value={formData.carerComment}
                placeholder='그 외 하실 말씀 작성 부탁드립니다.'
                onChange={(e) => setFormData({ ...formData, carerComment: e.target.value })}
            />
        </div>
    )
}

export default AdoptionCarerCommentField