import RewardItemList from "../RewardItemList/RewardItemList";
import HeritageHeader from "./HeritageHeader";

const HeritageHowToParticipates = [
  {
    id: 1,
    number: "STEP 01",
    name: (
      <>
        유산기부상담
        <br /> ( 전화 / 이메일 )
      </>
    ),
    icon: "/icons/heritage-chat.svg",
  },
  { id: 2, number: "STEP 02", name: "유산기부서약", icon: "/icons/heritage-doc.svg" },
  { id: 3, number: "STEP 03", name: "증권", icon: "/icons/heritage-raw.svg" },
  { id: 4, number: "STEP 04", name: "보험", icon: "/icons/heritage-raw2.svg" },
];

const HeritageHowToParticipate = () => {
  return (
    <section>
      <HeritageHeader subTitle={"참여방법"} />
      <div className="support-participate__content">
        <div className="support-participation__grid">
          {HeritageHowToParticipates.map((item) => (
            <div key={item.id} className="support-participation__card">
              <div>
                <span className="support-participation__card-number">{item.number}</span>
                <div>
                  <p className="support-participation__card-name">{item.name}</p>
                </div>
                <img src={item.icon} alt={item.name} className="support-participation__card-icon" />
              </div>
            </div>
          ))}
        </div>
        <RewardItemList />
      </div>
    </section>
  );
};

export default HeritageHowToParticipate;
