import './AdoptionField.css'

const AdoptionEmailField = ({ formData, setFormData }) => {

    const emailDomains = ['naver.com', 'gmail.com', 'daum.net', 'hanmail.net'];

    const handleUsernameChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            carerEmail: `${e.target.value}@${formData.carerEmail.split('@')[1] || 'naver.com'}`,
        }));
    };

    const handleDomainChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            carerEmail: `${formData.carerEmail.split('@')[0] || ''}@${e.target.value}`,
        }));
    };
    return (
        <div className='adoption-email-form-row'>
            <label>이메일</label>
            <div className='adoption-email-input-container'>
                <input
                    type="text"
                    name="username"
                    placeholder="이메일을 작성해주세요"
                    value={formData.carerEmail.split('@')[0] || ''}
                    style={{ height: 25, paddingLeft: 10 }}
                    onChange={handleUsernameChange}
                />
                <span>@</span>
                <select
                    name="domain"
                    value={formData.carerEmail.split('@')[1] || ''}
                    onChange={handleDomainChange}
                >
                    {emailDomains.map((domain) => (
                        <option key={domain} value={domain}>{domain}</option>
                    ))}
                </select>
            </div>
        </div>
    )
}

export default AdoptionEmailField