import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      {/* <div className="footer-history">
        <div>
          <Link to="https://www.nts.go.kr/">
            <img
              src="/images/revenue.png"
              alt="국세청"
              className="footer-history-img"
            />
          </Link>
        </div>
      </div> */}
      <div className="footer-info">
        <p>
          주소 : 경기도 안산시 상록구 광덕1로 369, 2층 205호(이동, 센타프라자) |
          사업자번호 : 889-82-00563 | 대표 : 양인영 | 개인정보관리담당자: 홍길동
        </p>
        <p>
          전화 : 0507-1307-9338 | 팩스 : 02-1234-56-78 | 이메일 :
          forcatmungcenter@naver.com
        </p>
        <p>Copyright © 2024 포켓멍센터. All rights reserved.</p>
        {/* <p>
          - 문의사항이 있다면 이메일 forcatmungcenter@naver.com 으로
          문의해주세요
        </p>
        <p>
          단체명: (사)포캣멍센터 | 주소: 경기도 안산시 상록구 광덕1로 369, 2층
          205호(이동, 센타프라자)
          <br />
          사업자번호: 889-82-00563 | 대표: 양인영 | 전화: 0507-1307-9338
        </p>
        <p>Copyright © 2024 포켓멍. All rights reserved.</p> */}
      </div>
      <div>
        <Link to="https://www.nts.go.kr/">
          <img
            src="/images/revenue.png"
            alt="국세청"
            className="footer-history-img"
          />
        </Link>
      </div>
    </footer>
  );
};
export default Footer;
