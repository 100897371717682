import "./SupportPage.css";
import {
  SupportPageBanner,
  SupportPageQA,
  SupportPageType,
  SupportPageUse,
} from "../../../components/Support/SupportPage";
import { useEffect } from "react";

const SupportPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <main className="supportPage-main">
        <SupportPageBanner />
        <SupportPageType />
        <SupportPageUse />
        <SupportPageQA />
      </main>
    </>
  );
};
export default SupportPage;
