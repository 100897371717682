import React from "react";
import TalentSlider from "./TalentSlider";
import Objective from "../Objective/objective";
import { objectivedummydata } from "../Objective/objectivedummydata";
import "./TalentSlider.css"

const TalentComponents = () => {
  return (
    <>
      <div className="mainPage">
        <TalentSlider />
      </div>
      <div className="objective-content-talent">
        <Objective data={objectivedummydata} />
      </div>
    </>
  );
};

export default TalentComponents;
