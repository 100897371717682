// src/TalentSliderData.js

const TalentSliderData = () => {
  return [
    {
      image: "https://via.placeholder.com/100x100/ffffff/000000?text=Image+1",
      tooltip: "첫 번째 이미지에 대한 말풍선 내용입니다.",
    },
    {
      image: "https://via.placeholder.com/100x100/ffffff/000000?text=Image+2",
      tooltip: "두 번째 이미지에 대한 말풍선 내용입니다.",
    },
    {
      image: "https://via.placeholder.com/100x100/ffffff/000000?text=Image+3",
      tooltip: "세 번째 이미지에 대한 말풍선 내용입니다.",
    },
    {
      image: "https://via.placeholder.com/100x100/ffffff/000000?text=Image+4",
      tooltip: "네 번째 이미지에 대한 말풍선 내용입니다.",
    },
    {
      image: "https://via.placeholder.com/100x100/ffffff/000000?text=Image+5",
      tooltip: "다섯 번째 이미지에 대한 말풍선 내용입니다.",
    },
    {
      image: "https://via.placeholder.com/100x100/ffffff/000000?text=Image+6",
      tooltip: "여섯 번째 이미지에 대한 말풍선 내용입니다.",
    },
    {
      image: "https://via.placeholder.com/100x100/ffffff/000000?text=Image+7",
      tooltip: "일곱 번째 이미지에 대한 말풍선 내용입니다.",
    },
    {
      image: "https://via.placeholder.com/100x100/ffffff/000000?text=Image+8",
      tooltip: "여덟 번째 이미지에 대한 말풍선 내용입니다.",
    },
    {
      image: "https://via.placeholder.com/100x100/ffffff/000000?text=Image+9",
      tooltip: "아홉 번째 이미지에 대한 말풍선 내용입니다.",
    },
    {
      image: "https://via.placeholder.com/100x100/ffffff/000000?text=Image+10",
      tooltip: "열 번째 이미지에 대한 말풍선 내용입니다.",
    },
  ];
};

export default TalentSliderData;
