const CompanySponsorshipHeader = ({ discription, subTitle }) => {
  return (
    <div className="company-sponsorship-header ">
      <div className="company-sponsorship-header__line"></div>
      <div>
        <p className="company-sponsorship-header__discription">
          {discription}
          <br />
        </p>
        <h2 className="company-sponsorship-header__sub-title">{subTitle}</h2>
      </div>
    </div>
  );
};

export default CompanySponsorshipHeader;
