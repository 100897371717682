// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../Support/SupportPage/assets/support_banner_img.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.temporary-banner-container {
  position: relative;
  width: 100%;
  height: 700px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  border-bottom: 12px solid #005bc0;
}

.temporary-banner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.temporary-banner-main-text {
  font-weight: bold;
  font-size: 35px;
}

.temporary-banner-explain-text {
  font-size: 14px;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Payment/TemporaryPay/TemporaryBanner.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,yDAAgF;EAChF,sBAAsB;EACtB,2BAA2B;EAC3B,4BAA4B;EAC5B,gBAAgB;EAChB,iCAAiC;AACnC;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,YAAY;EACZ,kBAAkB;EAClB,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,+BAA+B;EAC/B,2CAA2C;EAC3C,gBAAgB;AAClB","sourcesContent":[".temporary-banner-container {\n  position: relative;\n  width: 100%;\n  height: 700px;\n  background-image: url(\"../../Support/SupportPage/assets/support_banner_img.png\");\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n  overflow: hidden;\n  border-bottom: 12px solid #005bc0;\n}\n\n.temporary-banner-text {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  color: white;\n  text-align: center;\n  z-index: 2;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.temporary-banner-main-text {\n  font-weight: bold;\n  font-size: 35px;\n}\n\n.temporary-banner-explain-text {\n  font-size: 14px;\n  line-height: 1.5;\n  color: rgba(255, 255, 255, 0.9);\n  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
