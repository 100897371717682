// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    /* 배경 어둡게 */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* 다른 콘텐츠 위에 표시 */
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1001;
    margin: 10px;
}

.modal-content-button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.modal-close {
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 40px;
    cursor: pointer;
}

.modal-close:hover {
    color: red;
}

.modal-content-box {
    margin: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/MyPage/Modal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,oCAAoC;IACpC,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,kBAAkB;IAClB,yCAAyC;IACzC,kBAAkB;IAClB,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,SAAS;IACT,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".modal-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: rgba(0, 0, 0, 0.5);\n    /* 배경 어둡게 */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n    /* 다른 콘텐츠 위에 표시 */\n}\n\n.modal-content {\n    background: white;\n    padding: 20px;\n    border-radius: 8px;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n    position: relative;\n    z-index: 1001;\n    margin: 10px;\n}\n\n.modal-content-button-container {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-end;\n}\n\n.modal-close {\n    top: 10px;\n    right: 10px;\n    background: none;\n    border: none;\n    font-size: 40px;\n    cursor: pointer;\n}\n\n.modal-close:hover {\n    color: red;\n}\n\n.modal-content-box {\n    margin: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
