import React, {useState} from 'react';
import './Volunteering.css';
import VolunteeringBottomBanner from './VolunteeringBottomBanner';
import VolunteeringModal from './VolunteeringModal';

const VolunteeringWalk = () => {
  const componentsData = {
    image: "/images/volunteering-recruitment-content-image3.png", state: "예정", kind: "산책", currentPerson: 0, totalPerson: 3, startDate: "2023-02-01", endDate: "2023-02-28"
}

const dateStr1 = componentsData.startDate;
const dateStr2 = componentsData.endDate;

// 문자열을 '-'를 기준으로 쪼갭니다.
const [year1, month1, day1] = dateStr1.split('-');
const [year2, month2, day2] = dateStr2.split('-');

// 원하는 형식으로 조합합니다.
const formattedDate1 = `${year1}년 ${month1}월 ${day1}일`;
const formattedDate2 = `${year2}년 ${month2}월 ${day2}일`;

const [isModalOpen, setIsModalOpen] = useState(false);

const openModal = () => setIsModalOpen(true);
const closeModal = () => setIsModalOpen(false);

const getBackgroundClass = (state) => {
  switch(state) {
    case '모집 중': return 'bg-green';
    case '예정': return 'bg-yellow';
    case '마감': return 'bg-gray';
    default: return '';
  }
};

const buttonConfig = {
  "모집 중": {
    text: "신청하기",
    onClick: openModal,
    disabled: false
  },
  "예정": {
    text: "모집예정",
    onClick: closeModal,
    disabled: true
  },
  "마감": {
    text: "신청마감",
    onClick: closeModal,
    disabled: true
  }
};

const currentConfig = buttonConfig[componentsData.state] || {
  text: "로딩 중",
  onClick: () => {},
  disabled: true
};

  return (
    <div className="volunteering-detail-container">
      <div className="upper-section">
        <div className="section-upper-content">
          <div className="image-area">
            <img src={componentsData.image} alt="Description" />
          </div>
          <div className="volunteering-detail-text-area">
            <p className={`volunteering-detail-box ${getBackgroundClass(componentsData.state)}`}>{componentsData.state}</p>
            <p className="volunteering-detail-title">24년도 말 ~ 25년도 포캣멍센터 {componentsData.kind} 봉사자 상시 모집</p>
            <div className="volunteering-detail-split-text">
              <span>활동기간</span><span>{componentsData.startDate} ~ {componentsData.endDate} 중 하루</span>
            </div>
            <div className="volunteering-detail-split-text">
              <span>모집인원</span><span>{componentsData.totalPerson}명</span>
            </div>
            <div className="volunteering-detail-split-text">
              <span>나이조건</span><span>만 19세 이상 ~ 만 65세 이하</span>
            </div>
            <div className="volunteering-detail-split-text">
              <span>주소</span><span>경기도 안산시 OO동 OO건물 OO호</span>
            </div>
            <div className="volunteering-detail-split-text">
              <span>신청마감</span><span>인원 충족 시 신청 마감</span>
            </div>
            <button className={`action-button ${
    currentConfig.text === "신청하기" ? "action-button-actived" :
    currentConfig.text === "모집예정" ? "action-button-expected" : ""
  }`} onClick={currentConfig.onClick} disabled={currentConfig.disabled}>{currentConfig.text}</button>

<VolunteeringModal isOpen={isModalOpen} onClose={closeModal} startDate={componentsData.startDate} endDate={componentsData.endDate} kind= {componentsData.kind}/>

          </div>
        </div>
      </div>
      <div className="lower-section">
        <div className="section-lower-content">
          <h2 className="lower-title">상세 정보</h2>
          <p>24년도 말 ~ 25년도 포캣멍센터 {componentsData.kind} 봉사자 상시 모집</p>
          <p>-활동기간</p>
          <p>{formattedDate1} ~ {formattedDate2} 중 하루</p>
          <p>-모집인원</p>
          <p>{componentsData.totalPerson}명</p>
          <p>-나이조건</p>
          <p>만 19세 이상 ~ 만 65세 이하</p>
          <p>-활동장소</p>
          <p>경기도 안산시 OO동 OO건물 OO호</p>
          <p>-신청마감</p>
          <p>인원 충족 시 신청 마감</p>
          <p>-신청방법</p>
          <p>인원 초과 시 선착순으로 마감되며, 참여 가능한 날짜를 메모란에 기재해주세요.</p>
          <p className="list-intro">-유의사항</p>
          <ol className='details-note'>
            <li className='details-note-li'>신청 전 가능 일정을 충분히 고려해주세요.</li>
            <li className='details-note-li'>선발 후 불참이나 연락 두절은 삼가주시기 바랍니다.</li>
            <li className='details-note-li'>봉사 위치가 멀기 대문에 참여 가능 여부를 신중히 검토해주세요.</li>
            <li className='details-note-li'>발열 증상이 있는 경우 입장이 제한될 수 있습니다.</li>
            <li className='details-note-li'>개인 준비물: 컵/텀블러, 장갑, 앞치마, 갈아입을 옷, 신발 등</li>
          </ol>
          <p>-문의</p>
          <p>forcatmungcenter.naver.com (월 ~ 금 10:00 ~ 17:00)</p>
        </div>
      </div>

      <VolunteeringBottomBanner />

    </div>
  );
};

export default VolunteeringWalk;