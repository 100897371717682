import "./DonationUseList.css";

const DonationUseListPrimaryContent = ({imageUrl, title, date, description }) => {
  return (
    <div className="donation-uselist-primary-items">
    <div className="donation-uselist-primary-image-container">
    <img src={imageUrl} alt="주요 소식" className="donation-uselist-primary-image"></img>
    </div>
    <div className="donation-uselist-primary-middle-container">
        <span className="donation-uselist-primary-middle-title">{title}</span>
        <span className="donation-uselist-primary-middle-date">게시일: {date}</span>
    </div>
    <div style={{marginBottom:"30px"}}>
        <p className="donation-uselist-primary-bottom-explain">{description}</p>
    </div>
    </div>

  );
};

export default DonationUseListPrimaryContent;
