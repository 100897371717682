import React from "react";
import "./Main.css";
// import Floating from "../../components/Floating/floating";
import Percent from "../../components/Main/Percent/percent";
import Sliders from "../../components/Main/ImageSlider/ImageSlider";
import DonateGoalImage from "../../components/Main/Percent/DonateGoalImage";
import OneToMany from "../../components/Main/OneToMany/OneToMany";
import AboutIntro from "../../components/AboutPage/AboutIntro";
import TalentComponents from "../../components/Main/Talent/TalentComponents";
// import OneToOne from "../../components/Main/OneToOne/OneToOne";

const MainPage = () => {
  return (
    <div className="md-main">
      <main>
        <Sliders />
        <AboutIntro />
        {/* <OneToOne /> */}
        <DonateGoalImage />
        <TalentComponents />
        <OneToMany />
        <Percent />
      </main>
      {/* <Floating /> */}
    </div>
  );
};

export default MainPage;
