import React from "react";
import "./AskForHelpBanner.css";

const AskForHelpBanner = () => {
  return (
    <div className="ask-for-help-banner">
      <div className="ask-for-help-content">
        <h1 className="ask-for-help-title">구조/학대 제보</h1>
        <h2 className="ask-for-help-subtitle">
          구조가 필요한 동물들을 제보해주세요
        </h2>
      </div>
    </div>
  );
};

export default AskForHelpBanner;
