import React from "react";
import "./AnimalInfo.css";

const AnimalInfo = ({ animalData }) => {
  if (!animalData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="animal-info">
      <table>
        <tbody>
          <tr>
            <th>동물번호</th>
            <td>{animalData.animalNumber}</td>
            <td colSpan="2"></td>
          </tr>
          <tr>
            <th>품종</th>
            <td colSpan="3">{animalData.breeds}</td>
          </tr>
          <tr>
            <th>성별</th>
            <td>{animalData.gender}</td>
            <th>중성화 여부</th>
            <td>{animalData.isNeutered}</td>
          </tr>
          <tr>
            <th>나이</th>
            <td>{animalData.age}</td>
            <th>건강 특징</th>
            <td>{animalData.healthInfo}</td>
          </tr>
          <tr>
            <th>체중</th>
            <td>{animalData.weight}(KG)</td>
            <th>기타 사항</th>
            <td>{animalData.addtionInfo}</td>
          </tr>
          <tr>
            <th>발견장소</th>
            <td colSpan="3">{animalData.foundlocation}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AnimalInfo;
