import React from 'react';
import './Volunteering.css';

const VolunteeringTermsofUse = () => {
  return (
  <div>
    <p>Hello World!(이용 약관 페이지)</p>
  </div>
  );
};

export default VolunteeringTermsofUse;