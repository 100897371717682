import { useState } from "react";
import FanClubDonateConsentContent from "../FanClub/FanClubDonateConsentContent";
import FanClubDonateTermsContent from "../FanClub/FanClubDonateTermsContent";
import Modal from "../MyPage/Modal";
import AdoptionPhotographyConsent from "./AdoptionPhotographyConsent";

const AdoptionConsentsComponent = ({
    formData,
    setFormData
}) => {

    const [isPhotographyConsentModalOpen, setIsPhotographyConsentModalOpen] = useState(false);
    const [isConsentModalOpen, setConsentModalOpen] = useState(false);
    const [isTermsModalOpen, setTermsModalOpen] = useState(false);

    //MARK: 이용약관 모달창 여닫음 관리
    const handleConsentModalOpen = () => setConsentModalOpen(true);
    const handleTermsModalOpen = () => setTermsModalOpen(true);
    const handlePhotographyConsentModalOpen = () => setIsPhotographyConsentModalOpen(true);
    const handleModalClose = () => {
        setIsPhotographyConsentModalOpen(false);
        setConsentModalOpen(false);
        setTermsModalOpen(false);
    };
    return (
        <>
            {/*MARK: 동의사항 체크여부 */}
            <div className="adoption-consent-container">
                <div className="consent-checkbox">
                    <input
                        type="checkbox"
                        name="photographyConsent"
                        checked={formData.agreeForFilming}
                        onChange={(e) => setFormData({ ...formData, agreeForFilming: e.target.checked })}
                    />
                    <label style={{ lineHeight: '1.2' }}>[필수]입양시에는 촬영이 되어 SNS에 업로드 될 수 있음을 동의 합니다.
                        <br />(유튜브,인스타,홈페이지 등)모자이크 가능
                        <span onClick={handlePhotographyConsentModalOpen}>[보기]</span>
                    </label>
                </div>
            </div>
            <hr />
            <div className="adoption-consent-container">
                <div className="consent-checkbox">
                    <input
                        type="checkbox"
                        name="terms"
                        checked={formData.agreeToTermsOfUse}
                        onChange={(e) => setFormData({ ...formData, agreeToTermsOfUse: e.target.checked })}
                    />
                    <label>[필수] 이용약관 동의 <span onClick={handleTermsModalOpen}>[보기]</span></label>
                </div>
                <div className="consent-checkbox">
                    <input
                        type="checkbox"
                        name="consent"
                        checked={formData.agreePersonalInformation}
                        onChange={(e) => setFormData({ ...formData, agreePersonalInformation: e.target.checked })}
                    />
                    <label>[필수] 개인정보처리방침 동의 <span onClick={handleConsentModalOpen}>[보기]</span></label>
                </div>

                {/*MARK: 동의사항 모달 컴포넌트 */}
                <Modal isOpen={isTermsModalOpen} onClose={handleModalClose}>
                    <FanClubDonateTermsContent />
                </Modal>
                <Modal isOpen={isConsentModalOpen} onClose={handleModalClose}>
                    <FanClubDonateConsentContent />
                </Modal>
                <Modal isOpen={isPhotographyConsentModalOpen} onClose={handleModalClose}>
                    <AdoptionPhotographyConsent />
                </Modal>
            </div>
        </>
    );
}

export default AdoptionConsentsComponent