import "./banner.css";

const SupportBanner = () => {
  return (
    <section class="intro-banner">
      <div class="intro-banner__content">
        <h1 class="intro-banner__title">동물 보호의 뜻을 함께 해주세요!</h1>
        <p class="intro-banner__description">동물 소외계층 아이들에게 큰 힘이 되어주실래요?</p>
      </div>
      <div class="intro-banner__image-container">
        <img src="/images/tera-nim.png" alt="미용하는 태라님" class="intro-banner__image" />
      </div>
      <div className="intro-banner__circle-content">
        <div className="intro-banner__circle"></div>
      </div>
      <div className="intro-banner__circle-content circle-sm-contant">
        <div className="intro-banner__circle circle-sm"></div>
      </div>
    </section>
  );
};

export default SupportBanner;
