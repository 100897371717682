import OneToOneMidBanner from "./OneToOneMidBanner";
import OneToOneDetailAward from "./OneToOneDetailAward";
import OneToOnePetReward from "./OneToOnePetReward";
import OneToOneDetailInfo from "./OneToOneDetailInfo";
import OneToOneDetailNav from "./OneToOneDetailNav";

const OneToOneDetail = () => {
  return (
    <main className="one-to-one-detail">
      <OneToOneDetailNav />
      <OneToOneDetailInfo />
      <OneToOneMidBanner />
      <OneToOneDetailAward />
      <OneToOnePetReward />
    </main>
  );
};

export default OneToOneDetail;
