// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/images/pet-reward-bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fan-club-main {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-position: bottom;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 1025px) {
    .fan-club-main {
        background-position: bottom;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/FanClub/FanClub.css"],"names":[],"mappings":"AAAA;IACI,yDAAyD;IACzD,2BAA2B;IAC3B,4BAA4B;IAC5B,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI;QACI,2BAA2B;IAC/B;AACJ","sourcesContent":[".fan-club-main {\n    background-image: url(\"/public/images/pet-reward-bg.png\");\n    background-position: bottom;\n    background-repeat: no-repeat;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n@media (max-width: 1025px) {\n    .fan-club-main {\n        background-position: bottom;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
