import './AdoptionField.css'

const AdoptionAddressField = ({ formData, setFormData }) => {
    return (
        <div className='adoption-form-row'>
            <label>주소</label>
            <input
                placeholder='정확하게 작성해주세요'
                value={formData.carerAddress}
                style={{ height: 30, width: '60%', paddingLeft: 10 }}
                onChange={(e) => setFormData({ ...formData, carerAddress: e.target.value })}
            />
        </div>
    )
}

export default AdoptionAddressField