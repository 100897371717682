import {DonationUseListPrimary, DonationUseListList} from "../../components/DonationUseList"

const DonationUseListMainPage = () => {
  return (
    <>
        <DonationUseListPrimary />
        <DonationUseListList />
    </>
  );
};
export default DonationUseListMainPage;


