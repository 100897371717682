import React from 'react';

const AdminAniModal = ({ item, onClose, onDelete }) => {
  return (
    <div className="volunteering-modal">
      <div className="volunteering-modal-content">
        <span className="close-button" onClick={onClose}>&times;</span>
        <h2>{item.name}</h2>
        {/* 상세 정보 표시 */}
        <button onClick={() => onDelete(item.id)}>삭제</button>
        <button onClick={() => {/* 수정 페이지로 이동하는 로직 */}}>수정</button>
      </div>
    </div>
  );
};

export default AdminAniModal;