import React from "react";

const VolunteeringRecruitmentContent = ({image, state, kind, currentPerson, totalPerson, startDate, endDate}) => {
  const getBackgroundClass = (state) => {
  switch(state) {
    case '모집 중': return 'bg-green';
    case '예정': return 'bg-yellow';
    case '마감': return 'bg-gray';
    default: return '';
  }
};

    return (
<div className="volunteering-recruitment-content">
<div className="volunteering-recruitmentcontent-image-container">
<img src={image} alt="ComponentBImage" className="volunteering-recruitment-content-image" />
<div className={`volunteering-recruitment-content-image-overlay ${getBackgroundClass(state)}`}>
  <p className={"volunteering-recruitment-content-small-text-top"}>{state}</p>
  </div>
</div>
<div className="volunteering-recruitment-content-text-section">
  <p className="volunteering-recruitment-content-small-text-bottom1">포캣멍센터 {kind} 봉사 모집</p>
  <p className="volunteering-recruitment-content-small-text-bottom2">{currentPerson}명 / {totalPerson}명</p>
  <p className="volunteering-recruitment-content-small-text-bottom3">{startDate} ~ {endDate}</p>
</div>
</div>
    )
}

export default VolunteeringRecruitmentContent;