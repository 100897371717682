const CompanySponsorshipMidBanner = () => {
  return (
    <section className="mid-banner-section">
      <div className="award-upper-info">
        <div className="gradiant__white">
          <div className="one-to-one-mid__contant">
            <div className="one-to-one-mid__title title-blue">아이들의 인생을 지켜줄수있는</div>
            <div className="one-to-one-mid__title">후원기업이 되어주세요</div>
            <p>아이들이 건강하게 가정을 찾을 수 있게 도와주세요</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CompanySponsorshipMidBanner;
