import React from 'react';
import './OneToMany.css'
import { useLocation, useNavigate } from 'react-router-dom';

const OneToManyContent = ({ id, title, catName, description, imageUrl }) => {
    const location = useLocation();
    const navigate = useNavigate();
    // 특정 경로에서만 버튼 표시
    const shouldShowButton = location.pathname === '/onetomanypage/list';

    const handleOneToManyDetailButton = () => {
        navigate(`/onetomanypage/detail/${id}`)
        window.scrollTo(0, 0);
    };

    //const handleOneToManyDonateButton = () => {
    //};

    return (
        <div className="one-to-many-body">
            <div className="one-to-many-content-wrapper">
                <div className="one-to-many-content">
                    <div className="one-to-many-content-text">
                        <span style={{ fontSize: 34, fontWeight: 700 /*maxWidth: '70%'*/ }}>
                            {title}<span style={{ color: "#005EB8" }}> {catName}</span>
                        </span>
                        <span style={{ maxWidth: '80%' }}>{description}</span>
                    </div>
                    <div className="one-to-many-content-button">
                        <button className="one-to-many-detail-button" onClick={handleOneToManyDetailButton}>자세히보기</button>
                        {shouldShowButton && <button className="one-to-many-donate-button">1:N 후원하기</button>}
                    </div>
                </div>
                <div className="one-to-many-content-image">
                    <img src={imageUrl} alt="content-image" />
                </div>
            </div>
            {!shouldShowButton && <hr className="divider" />}
        </div>
    );
};

export default OneToManyContent;
