const FaqBanner = () => {
  return (
    <div>
      <>
        <section className="faq-banner">
          <div className="faq-banner__content">
            <h1 className="faq-banner__title">FAQ</h1>
            <h2 className="faq-banner__title faq-banner__title--sub">궁금하신 내용에 대해서 알려드립니다.</h2>
          </div>
        </section>
      </>
    </div>
  );
};

export default FaqBanner;
