import { useNavigate } from "react-router-dom";
import OneToManyBody from "./OneToManyBody";
import OneToManyDetail from "./OneToManyDetail";
import OneToManyFooter from "./OneToManyFooter";
import SupportListOneToMany from "./SupportListOneToMany";

const OneToManyDetailPage = () => {
    const navigate = useNavigate()
    const clickHandler = () => {
        navigate('/onetomanypage/list')
        window.scrollTo(0, 0);
    }
    return (
        <div style={{ backgroundColor: '#fff' }}>
            <OneToManyDetail
                name={"메롱이"}
                description={"메롱이는 10살 추정 여자아이 입니다."}
                animalId={"DY00001"}
                history={"24년 09월 23일 안양시의 길에서 구조되었어요."}
                personality={"저는 사람을 너무 좋아해요"}
                needs={"치아 전발치 수술이 필요해요."}
                onClick={clickHandler} />
            <OneToManyBody />
            <OneToManyFooter name={"메롱이"} />
            <SupportListOneToMany onclick={clickHandler} />
        </div>
    );
}

export default OneToManyDetailPage;