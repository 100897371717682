// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/images/one-to-one-banner.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.one-to-one-banner {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: right 35% bottom 60%;
  background-repeat: repeat;
  min-height: 600px;

  position: relative;
}

.one-to-one-banner__content {
  position: absolute;
  top: 50%;
  left: 5%;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
}

.one-to-one-banner__title {
  color: #ffffff;
  font-size: 3rem;
  font-weight: 600;
}

.one-to-one-banner__title--sub {
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: right;
  text-shadow: 0px 4px 19px rgba(0, 0, 0, 0.25);
}
`, "",{"version":3,"sources":["webpack://./src/components/Support/OneToOne/OneToOneBanner.css"],"names":[],"mappings":"AAAA;EACE,yDAA6D;EAC7D,yCAAyC;EACzC,yBAAyB;EACzB,iBAAiB;;EAEjB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,QAAQ;;EAER,aAAa;EACb,sBAAsB;EACtB,qBAAqB;EACrB,QAAQ;AACV;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,iBAAiB;EACjB,6CAA6C;AAC/C","sourcesContent":[".one-to-one-banner {\n  background-image: url(\"/public/images/one-to-one-banner.png\");\n  background-position: right 35% bottom 60%;\n  background-repeat: repeat;\n  min-height: 600px;\n\n  position: relative;\n}\n\n.one-to-one-banner__content {\n  position: absolute;\n  top: 50%;\n  left: 5%;\n\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n  gap: 8px;\n}\n\n.one-to-one-banner__title {\n  color: #ffffff;\n  font-size: 3rem;\n  font-weight: 600;\n}\n\n.one-to-one-banner__title--sub {\n  font-size: 1rem;\n  line-height: 1.5rem;\n  text-align: right;\n  text-shadow: 0px 4px 19px rgba(0, 0, 0, 0.25);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
