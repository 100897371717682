import { Outlet } from "react-router-dom";

const CustomDonationPage = () => {
    return (
        <>
            <main className="md-main">
                <Outlet />
            </main>
        </>
    );
}

export default CustomDonationPage;