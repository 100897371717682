import HeritageHeader from "./HeritageHeader";

const HeritageSupportTypes = [
  { id: 1, number: "01", name: "현금", icon: "/icons/money.svg" },
  { id: 2, number: "02", name: "부동산", icon: "/icons/property.svg" },
  { id: 3, number: "03", name: "증권", icon: "/icons/stock.svg" },
  { id: 4, number: "04", name: "보험", icon: "/icons/insurance.svg" },
  { id: 5, number: "05", name: "신탁", icon: "/icons/trust.svg" },
];

const SupporTypeCard = ({ number, name, icon }) => {
  return (
    <div className="support-type-grid__card">
      <div className="support-type-grid__content">
        <span className="support-type-grid__card-number">{number}</span>
        <span className="support-type-grid__card-name">{name}</span>
        <img src={icon} alt={name} className="support-type-grid__card-icon" />
      </div>
    </div>
  );
};

const HeritageSupportType = () => {
  return (
    <section className="support-section">
      <HeritageHeader subTitle={"후원형태"} />
      <div className="support-section__content">
        <div className="support-type-grid">
          {HeritageSupportTypes.map((item) => (
            <SupporTypeCard key={item.id} number={item.number} name={item.name} icon={item.icon} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default HeritageSupportType;
