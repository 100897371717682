// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.supportPage-main {
  width: 60%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 700px) {
  .supportPage-main {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Support/SupportPage/SupportPage.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,cAAc;EACd,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".supportPage-main {\n  width: 60%;\n  margin: 0 auto;\n  display: flex;\n  flex-direction: column;\n}\n\n@media screen and (max-width: 700px) {\n  .supportPage-main {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
