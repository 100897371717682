import { useCallback, useState } from "react";
import FanClubAward from "./FanClubAward";
import FanClubBanner from "./FanClubBanner";
import FanClubGrid from "./FanClubGrid";
import FanClubMiddleBanner from "./FanClubMiddleBanner";
import FanClubModalFrame from "./FanClubModalFrame";
import FanClubReword from "./FanClubReword";
import Modal from '../MyPage/Modal';
import FanClubCheckList from "./FanClubCheckList";
import FanClubInputComponent from "./FanClubInputComponent";
import FanClubCompleteComment from "./FanClubCompleteComment";

import './FanClub.css'
const FanClub = () => {
    const [isModalOpen, setModalOpen] = useState(false); // 모달 열림 여부
    const [currentStep, setCurrentStep] = useState(1);  // 현재 모달 단계
    const [isChecked, setIsChecked] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [inputData, setInputData] = useState({});

    const openModal = () => {
        setModalOpen(true);
        setCurrentStep(1); // 초기 단계로 설정
    };

    const closeModal = () => {
        setModalOpen(false);
        setCurrentStep(1); // 단계 초기화
    };

    const nextModal = () => {
        setCurrentStep((prevStep) => prevStep + 1); // 다음 단계로
    };

    const prevModal = () => {
        setCurrentStep((prevStep) => Math.max(prevStep - 1, 1)); // 이전 단계로
    };

    // useCallback으로 래핑하여 불필요한 리렌더링 방지
    const handleDataChange = useCallback((data) => {
        setInputData(data); // 데이터 상태 업데이트
    }, []);

    const handleNextButtonState = useCallback((checked, isValidInput) => {
        setIsChecked(checked);
        setIsValid(isValidInput)
    }, []);

    return (
        <div className="fan-club-main">
            <FanClubBanner />
            <FanClubMiddleBanner onOpenModal={openModal} />
            {isModalOpen ? (
                currentStep === 3 ? (
                    <Modal isOpen={isModalOpen} onClose={closeModal}>
                        <FanClubCompleteComment onclose={closeModal} />
                    </Modal>
                ) : (
                    <Modal isOpen={isModalOpen} onClose={closeModal}>
                        <FanClubModalFrame
                            currentStep={currentStep}
                            inputData={inputData}
                            onClose={closeModal}
                            onNext={nextModal}
                            onPrev={currentStep > 1 ? prevModal : null}
                            isChecked={isChecked}
                            isValid={isValid}>
                            {currentStep === 1 && <FanClubCheckList />}
                            {currentStep === 2 &&
                                <FanClubInputComponent
                                    formData={inputData}
                                    onDataChange={handleDataChange}
                                    handleNextButtonState={handleNextButtonState}
                                />}
                        </FanClubModalFrame>
                    </Modal>
                )
            ) : null}
            <FanClubGrid />
            <FanClubAward />
            <FanClubReword onOpenModal={openModal} />
        </div>
    );
};

export default FanClub;
