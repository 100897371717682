// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.support-use-main {
  background-color: white;
  display: flex;
  align-items: center;
}

.support-use-line {
  width: 150px;
  height: 4px;
  background-color: #005bc0;
  border: none;
}

.support-text {
  text-align: left;
  font-size: 20px;
  margin-left: 15px;
  color: #005bc0;
  line-height: 1.3;
  font-weight: bold;
}

.support-use-main span {
  font-size: 35px;
}

@media screen and (max-width: 1380px) {
  .support-use-line {
    width: 100px;
  }

  .support-text {
    font-size: 18px;
  }

  .support-use-main span {
    font-size: 24px;
  }
}

@media screen and (max-width: 852px) {
  .support-use-main {
    padding-top: 20px;
  }
  .support-use-line {
    width: 60px;
  }

  .support-text {
    font-size: 16px;
    margin-left: 10px;
  }

  .support-use-main span {
    font-size: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Support/SupportPage/SupportPageUse.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE;IACE,YAAY;EACd;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,iBAAiB;EACnB;EACA;IACE,WAAW;EACb;;EAEA;IACE,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":[".support-use-main {\n  background-color: white;\n  display: flex;\n  align-items: center;\n}\n\n.support-use-line {\n  width: 150px;\n  height: 4px;\n  background-color: #005bc0;\n  border: none;\n}\n\n.support-text {\n  text-align: left;\n  font-size: 20px;\n  margin-left: 15px;\n  color: #005bc0;\n  line-height: 1.3;\n  font-weight: bold;\n}\n\n.support-use-main span {\n  font-size: 35px;\n}\n\n@media screen and (max-width: 1380px) {\n  .support-use-line {\n    width: 100px;\n  }\n\n  .support-text {\n    font-size: 18px;\n  }\n\n  .support-use-main span {\n    font-size: 24px;\n  }\n}\n\n@media screen and (max-width: 852px) {\n  .support-use-main {\n    padding-top: 20px;\n  }\n  .support-use-line {\n    width: 60px;\n  }\n\n  .support-text {\n    font-size: 16px;\n    margin-left: 10px;\n  }\n\n  .support-use-main span {\n    font-size: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
