import React, { useState } from "react";
import "./CoporationSlider.css";

const CoporationSlider = () => {
  const [currentPage, setCurrentPage] = useState(0);

  const images = [
    {
      src: "https://via.placeholder.com/100x100/ffffff/000000?text=Default+1",
      text: "목욕용품 기부",
    },
    {
      src: "https://via.placeholder.com/100x100/ffffff/000000?text=Default+1",
      text: "고양이 사료 기부",
    },
    {
      src: "https://via.placeholder.com/100x100/ffffff/000000?text=Default+1",
      text: "강아지 사료 기부",
    },
    {
      src: "https://via.placeholder.com/100x100/ffffff/000000?text=Default+1",
      text: "강아지 용품 기부",
    },
    {
      src: "https://via.placeholder.com/100x100/ffffff/000000?text=Default+1",
      text: "강아지 사료 기부",
    },
    {
      src: "https://via.placeholder.com/100x100/ffffff/000000?text=Default+1",
      text: "이미지 6",
    },
    {
      src: "https://via.placeholder.com/100x100/ffffff/000000?text=Default+1",
      text: "이미지 7",
    },
    {
      src: "https://via.placeholder.com/100x100/ffffff/000000?text=Default+1",
      text: "이미지 8",
    },
    {
      src: "https://via.placeholder.com/100x100/ffffff/000000?text=Default+1",
      text: "이미지 9",
    },
    {
      src: "https://via.placeholder.com/100x100/ffffff/000000?text=Default+1",
      text: "이미지 10",
    },
    {
      src: "https://via.placeholder.com/100x100/ffffff/000000?text=Default+1",
      text: "이미지 11",
    },
    {
      src: "https://via.placeholder.com/100x100/ffffff/000000?text=Default+1",
      text: "이미지 12",
    },
    {
      src: "https://via.placeholder.com/100x100/ffffff/000000?text=Default+1",
      text: "이미지 13",
    },
    {
      src: "https://via.placeholder.com/100x100/ffffff/000000?text=Default+1",
      text: "이미지 14",
    },
    {
      src: "https://via.placeholder.com/100x100/ffffff/000000?text=Default+1",
      text: "이미지 15",
    },
  ];

  const itemsPerPage = 5;
  const totalPages = Math.ceil(images.length / itemsPerPage);

  const handlePageChange = (index) => {
    setCurrentPage(index);
  };

  const renderDots = () => {
    return Array.from({ length: totalPages }).map((_, index) => (
      <div
        key={index}
        className={`dot ${currentPage === index ? "active" : ""}`}
        onClick={() => handlePageChange(index)}
      />
    ));
  };

  const renderImages = () => {
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return images.slice(startIndex, endIndex).map((image, index) => (
      <div className="talent-slider-image-item" key={index}>
        <img src={image.src} alt={`Slide ${startIndex + index}`} />
        <p
          style={{
            color: "#cd6700",
          }}
        >
          {image.text}
        </p>
      </div>
    ));
  };

  return (
    <div className="slider">
      <div className="talent-slider-images-container">{renderImages()}</div>
      <div className="talent-slider-dots-container">{renderDots()}</div>
    </div>
  );
};

export default CoporationSlider;
