import React from "react";
import "./AdoptionDetail.css";
import numberIcon from "./assets/number.png";
import storyIcon from "./assets/history.png";
import favorite from "./assets/favorite.png";
import personalityIcon from "./assets/personality.png";
import medicalIcon from "./assets/medical.png";
import AnimalInfo from "./AnimalInfo";

const AdoptionDetail = ({ handleClose, onNext, animalData }) => {
  // animalData가 제대로 전달되었는지 확인
  // console.log(animalData);

  // animalData가 없는 경우 기본값 설정
  const data = animalData || {
    name: "정보 없음",
    animalNumber: "정보 없음",
    age: "정보 없음",
    breed: "정보 없음",
    gender: "정보 없음",
    isNeutered: false,
    healthInfo: "정보 없음",
    additionalInfo: "정보 없음",
    birthDate: "정보 없음",
    weight: "정보 없음",
  };

  return (
    <div className="adoption-modal-container">
      <div className="adoption-detail-whole-container">
        <div className="adoption-header">
          <img src={favorite} className="adoption-icon-image" alt="" />
          <h1 className="adoption-detail-title">입양하기</h1>
        </div>
        <div className="protection-adoption-title">
          아이들의 가족이 되어주실 분들을 찾습니다.
        </div>

        <div className="adoption-detail-divider"></div>

        <div className="adoption-detail-container">
          <div className="adoption-detail-information">
            <img
              src={data.imageurl}
              alt="입양하기"
              className="adoption-detail-animal-image"
            />
          </div>
          <div className="adoption-container">
            <div className="adoption-detail-greeting">안녕하세요!</div>
            <div className="adoption-detail-name">
              저는 {data.animalNm}입니다!
            </div>
            <div className="adoption-detail-intro">
              {data.animalNm}는 {data.age}살 {data.gender}아이 입니다.
            </div>

            <div className="adoption-detail-all">
              <img src={numberIcon} alt="" />
              동물번호 {data.animalNumber}
            </div>
            <div className="adoption-detail-all">
              <img src={storyIcon} alt="동물기록" />
              {data.birthDate || "출생일 정보 없음"}
            </div>
            <div className="adoption-detail-all">
              <img src={personalityIcon} alt="동물성격" />
              {data.addtionInfo || "성격 정보 없음"}
            </div>
            <div className="adoption-detail-all">
              <img src={medicalIcon} alt="의료기록" />
              {data.healthInfo || "의료 기록 없음"}
            </div>
          </div>
        </div>

        <AnimalInfo animalData={data} />

        <div className="adoption-detail-buttons">
          <button
            className="adoption-button close-button"
            onClick={handleClose}
          >
            닫기
          </button>
          <button
            className="adoption-button apply-button"
            onClick={() => onNext(data.animalNumber)}
          >
            입양 신청하기
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdoptionDetail;
