// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fan-club-card {
    display: flex;
    margin: 8px 0;
    background: linear-gradient(to bottom, rgba(0, 94, 184, 0.11), #FFFFFF);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.fan-club-card__image {
    flex: 0 0 200px;
    margin-right: 30px;
}

.fan-club-card__image img {
    width: 100%;
    object-fit: cover;
}

.fan-club-card__content {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    justify-content: center;
    text-align: start;
    padding: 8px 0;
}

.fan-club-card__header h2 {
    font-size: 1.2rem;
    margin: 0 0 8px 0;
}

.fan-club-card__date {
    font-size: 0.9rem;
    color: #555;
    margin: 4px 0;
}

.fan-club-card__message {
    font-size: 1rem;
    color: #333;
    margin: 8px 0;
}

@media (max-width: 1024px) {
    .fan-club-card__image {
        flex: 0 0 150px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/FanClub/FanClubCard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,uEAAuE;IACvE,wCAAwC;AAC5C;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAO;IACP,uBAAuB;IACvB,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,aAAa;AACjB;;AAEA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".fan-club-card {\n    display: flex;\n    margin: 8px 0;\n    background: linear-gradient(to bottom, rgba(0, 94, 184, 0.11), #FFFFFF);\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.fan-club-card__image {\n    flex: 0 0 200px;\n    margin-right: 30px;\n}\n\n.fan-club-card__image img {\n    width: 100%;\n    object-fit: cover;\n}\n\n.fan-club-card__content {\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n    justify-content: center;\n    text-align: start;\n    padding: 8px 0;\n}\n\n.fan-club-card__header h2 {\n    font-size: 1.2rem;\n    margin: 0 0 8px 0;\n}\n\n.fan-club-card__date {\n    font-size: 0.9rem;\n    color: #555;\n    margin: 4px 0;\n}\n\n.fan-club-card__message {\n    font-size: 1rem;\n    color: #333;\n    margin: 8px 0;\n}\n\n@media (max-width: 1024px) {\n    .fan-club-card__image {\n        flex: 0 0 150px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
