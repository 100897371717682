import './OneToManyDetail.css';
import image from './assets/메롱이.png';
import iconId from './assets/icon_id.png';
import iconHistory from './assets/icon_history.png';
import iconPersonality from './assets/icon_personality.png';
import iconNeeds from './assets/icon_needs.png';
//import { useParams } from 'react-router-dom';

const OneToManyDetail = ({ name, animalId, description, history, personality, needs, onClick }) => {
    //const { id } = useParams(); //게시물 id인데 해당 id로 동물값가져오기
    return (
        <div className="one-to-many-page">
            <div className="one-to-many-header" style={{ flexDirection: 'column' }}>
                <div className="one-to-many-header-content-wrapper">
                    <div className="one-to-many-header-text">
                        <span className="one-to-many-small-text">
                            아이들의 가족이 되어주세요
                        </span>
                        <span className="one-to-many-large-text">
                            1:N 후원 동물
                        </span>
                    </div>
                    <div className="many-header-button">
                        <button
                            className="one-to-many-detail-button"
                            onClick={onClick}
                            style={{ boxShadow: 'none', width: 110 }}
                        >
                            목록으로
                        </button>
                    </div>
                </div>
                <hr className="many-detail-divider" />
            </div>
            <div className="many-detail-content-wrapper">
                <div className="many-detail-images">
                    <img src={image} alt="1:N 페이지 배너 이미지" />
                </div>
                <div className="many-detail-text">
                    <div className="many-detail-content-title">
                        <span style={{ color: '#0260BA' }}>안녕하세요!<br /></span>
                        <span>저는 {name} 입니다!<br /></span>
                        <span
                            style={{
                                color: '#545454',
                                fontSize: 13,
                                opacity: 0.49,
                            }}>
                            {description}
                        </span>
                    </div>
                    <div className="many-detail-content-body">
                        <div className="many-detail-content-body-item">
                            <img src={iconId} alt="동물번호 아이콘" className="many-detail-content-body-icon" />
                            <p><strong>동물번호:</strong> {animalId}</p>
                        </div>
                        <div className="many-detail-content-body-item">
                            <img src={iconHistory} alt="히스토리 아이콘" className="many-detail-content-body-icon" />
                            <p>{history}</p>
                        </div>
                        <div className="many-detail-content-body-item">
                            <img src={iconPersonality} alt="성격 아이콘" className="many-detail-content-body-icon" />
                            <p>{personality}</p>
                        </div>
                        <div className="many-detail-content-body-item">
                            <img src={iconNeeds} alt="니즈 아이콘" className="many-detail-content-body-icon" />
                            <p>{needs}</p>
                        </div>
                    </div>
                    <div className="many-detail-content-footer">
                        <div className="many-detail-content-footer-text">
                            <p>아이들에게 안정적인 하루 하루를 선물해 주세요.</p>
                            <p>저의 따뜻한 후원자님을 기다립니다.</p>
                        </div>
                        <div className="many-detail-content-footer-button">
                            <button
                                className="one-to-many-donate-button"
                                style={{
                                    borderRadius: 6,
                                    width: '185px',
                                    height: '40px',
                                    boxShadow: 'none'
                                }}
                            >
                                1:N후원하기
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OneToManyDetail;
