import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import "./AnnounceList.css";
import AnnounceItem from "./AnnounceItem.jsx";
import { Link } from "react-router-dom";

const AnnounceList = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState("");
  const itemsPerPage = 10;
  const group = 5;

  // 디폴트 이미지 수정하기
  const defaultImage = `${process.env.PUBLIC_URL}/Forcatmung.png`;

  const fetchAnnouncements = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await axios.get(
        `https://forcatmung.kr/api/notice/list?page=${
          currentPage - 1
        }&size=${itemsPerPage}`
      );
      const {
        currentPage: apiCurrentPage,
        totalPages,
        noticeList,
      } = response.data;
      setAnnouncements(noticeList);
      setCurrentPage(apiCurrentPage + 1);
      setTotalPages(totalPages);
    } catch (error) {
      console.error("Error fetching announcements:", error);
      setError("공지사항을 불러오는 데 실패했습니다. 다시 시도해 주세요.");
    } finally {
      setLoading(false);
    }
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    fetchAnnouncements();
  }, [fetchAnnouncements]);

  const fetchSearchResults = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await axios.get(
        `https://forcatmung.kr/api/notice/search?keyword=${encodeURIComponent(
          search
        )}`
      );
      const { noticeList, totalCount } = response.data;
      setAnnouncements(noticeList);
      setTotalPages(Math.ceil(totalCount / itemsPerPage));
    } catch (error) {
      console.error("Error fetching search results:", error);
      setError("검색 결과를 불러오는 데 실패했습니다. 다시 시도해 주세요.");
    } finally {
      setLoading(false);
    }
  };

  const handleSearchClick = () => {
    if (search.trim()) {
      fetchSearchResults();
    }
  };

  const handlePageChange = (pageNumber) => {
    if (search.trim()) {
      fetchSearchResults();
    } else {
      setCurrentPage(pageNumber);
    }
  };

  const renderPageButton = () => {
    const currentGroup = Math.ceil(currentPage / group);
    const startPage = (currentGroup - 1) * group + 1;
    const endPage = Math.min(currentGroup * group, totalPages);

    return Array.from({ length: endPage - startPage + 1 }, (_, index) => {
      const pageNumber = startPage + index;
      return (
        <button
          key={pageNumber}
          onClick={() => handlePageChange(pageNumber)}
          className={currentPage === pageNumber ? "active" : ""}
        >
          {pageNumber}
        </button>
      );
    });
  };

  // 스크롤 위치 복원
  // const handleNavigate = () => {
  //   const scrollY = window.scrollY;
  //   console.log(scrollY);
  //   sessionStorage.setItem("scrollPosition", scrollY);
  // };

  return (
    <div className="announce-list-container">
      {/* <div className="announce-list-write-button">
        <Link to="/write" state={{ source: "announce" }}>
          <button>글쓰기</button>
        </Link>
      </div> */}
      <div className="announce-list-title">
        <div className="announce-list-title-text">
          <p>공지사항</p>
        </div>
        <div className="announce-list-title-search">
          <input
            type="search"
            placeholder="검색어를 입력하세요"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <button onClick={handleSearchClick}>검색</button>
        </div>
      </div>

      {loading ? (
        <p>로딩 중...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <div>
          {announcements.map((item) => (
            <Link
              to={`/announce/detail/${item.noticeid}`}
              key={item.noticeid}
              // onClick={handleNavigate}
            >
              <AnnounceItem
                key={item.noticeid}
                id={item.noticeid}
                imageUrl={item.imageUrl || defaultImage}
                title={item.title}
                author={item.author}
                date={item.date}
                content={item.content}
              />
            </Link>
          ))}
        </div>
      )}

      {!loading && !error && (
        <div className="pagination">
          {currentPage > group && (
            <button onClick={() => handlePageChange(currentPage - group)}>
              이전
            </button>
          )}
          {renderPageButton()}
          {currentPage < totalPages - group + 1 && (
            <button onClick={() => handlePageChange(currentPage + group)}>
              다음
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default AnnounceList;
