import React from 'react';
import "./News.css";
import NewsPrimary from "../../components/News/NewsPrimary"
import NewsList from '../../components/News/NewsList';

const NewsMainPage = () => {
  return (
    <>
        <NewsPrimary />
        <NewsList />
    </>
  );
};
export default NewsMainPage;
