import './Volunteering.css'
import {VolunteeringBanner, VolunterringRecruitment, VolunteeringNav} from '../../components/Volunteering';
import { Outlet } from "react-router-dom";
import Percent from "../../components/Main/Percent/percent";

const Volunteering = () => {
  return (
    <>
      <main className="volunteering-page-container">
        <VolunteeringBanner />
        <VolunterringRecruitment />
        <VolunteeringNav />
        <Outlet />
        <Percent />
      </main>
    </>
  );
};
export default Volunteering;


