import { useEffect } from "react";
import {
  TemporaryBanner,
  TemporaryInfo,
} from "../../components/Payment/TemporaryPay";
import "./TemporaryPay.css";

const TemporaryPay = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="temporaryPay-main">
        <TemporaryBanner />
        <TemporaryInfo />
      </div>
    </>
  );
};
export default TemporaryPay;
