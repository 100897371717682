import React from "react";
import "./AdminMain.css";

const AdminMain = () => {
  const donationData = [
    {date : "2025년 1월 16일", amount: '1000000', donor : "67"
  } ]

  // const processedData = donationData.map(item => {
  //   const [year, rest] = item.date.split('년 ');
  //   const [month, day] = rest.split('월 ');
  //   return {
  //     ...item,
  //     year: year,
  //     monthDay: `${month}월 ${day}`
  //   };
  // });

  // const data = [
  //   { type: '입양하기 신청', count: '01' },
  //   { type: '문의하기 미답변', count: '23' },
  //   { type: '봉사활동 신청', count: '45' },
  // ];

  return (
    <div className="admin-table-container">
      <div style={{marginRight:"5%"}}>
        <div style={{borderTop: '1px solid #ccc'}}></div>
        {donationData.map((item) => (
        <div key={item.date} className="admin-table-row">
          <span className="admin-table-cell">{item.date} 후원금</span>
          <span className="admin-table-cell">{item.amount}원</span>
          <span className="admin-table-cell">도움주신 분 : {item.donor}명</span>
        </div>
        ))}

      {/* {data.map((item) => (
        <div key={item.type} className="admin-table-row">
          <span className="admin-table-cell">{item.type}</span>
          <span className="admin-table-cell">{item.count}건</span>
          <span className="admin-table-cell"></span>
        </div>
      ))} */}
    </div>
    </div>
  );
};

export default AdminMain;