const HeritageSubBanner = () => {
  return (
    <section className="heritage-sub-banner">
      <div className="heritage-sub-banner__content">
        <h1 className="heritage-sub-banner__title">유산기부란?</h1>
      </div>
      <div className="heritage-sub-banner__description-content">
        <p className="heritage-sub-banner__description">
          유산 기부는 계획기부의 일종으로 <br />
          개인이 사후에 자신의 재산 일부 또는 전부를
          <br /> 비영리기관, 복지재단, 재단으로 기부하는 것을 의미합니다.
        </p>
      </div>
    </section>
  );
};

export default HeritageSubBanner;
