import React from "react";
import "./OneToOne.css";
import "./OneToOneContent.css";
import { Link } from "react-router-dom";

const OneToOneContent = ({
  index,
  name,
  kind,
  age,
  gender,
  donator,
  image,
}) => {
  return (
    <div className="one-to-one-card">
      <span className="one-to-one-text-1">안녕하세요!</span>
      <span className="one-to-one-text-1">
        저는 <span className="one-to-one-text-2">{name}</span> 입니다.
      </span>
      <span className="one-to-one-text-3">
        {kind} / {age} / {gender}
      </span>
      <img src={image} alt="profile" className="one-to-one-image" />
      <div style={{ margin: "5px 0 10px 0" }}>
        <span className="one-to-one-text-1">{name} 후원자</span>
        <span className="one-to-one-text-5">{donator} 님</span>
      </div>
      <button className="OtoO-btn">
        <Link to={`/onetoone/list/${index}`}>상세보기</Link>
      </button>
      {/* 나중에 1:1 후원하기로 수정해야함 */}
      <button className="OtoO-btnn">
        <Link to="/AdoptionPage">입양하기</Link>
      </button>
    </div>
  );
};

export default OneToOneContent;
