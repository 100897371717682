import { useState, useEffect } from "react";
import SmartEditor from "../../components/SmartEditor/SmartEditor";
import "./Write.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

const Write = () => {
  const [title, setTitle] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [attachFile, setAttachFile] = useState(null);
  const [content, setContent] = useState("");
  const location = useLocation();
  const navigator = useNavigate();
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    const source = location.state?.source;
    if (source) {
      switch (source) {
        case "announce":
          setSelectedOption("option1");
          break;
        case "news":
          setSelectedOption("option2");
          break;
        case "uselist":
          setSelectedOption("option3");
          break;
        default:
          setSelectedOption("");
      }
    }
  }, [location]);

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
    setContent("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!title || !content || !selectedOption) {
      alert("제목, 내용, 카테고리를 모두 입력해주세요");
      return;
    }

    const isConfirmed = window.confirm("작성하시겠습니까?");

    if (isConfirmed) {
      const formData = new FormData();
      const jsonData = { title: title, content: content };
      if (imageFile) formData.append("imageData", imageFile);
      if (attachFile) formData.append("attachData", attachFile);

      let apiUrl;
      switch (selectedOption) {
        case "option1":
          apiUrl = "https://forcatmung.kr/api/notice/create";
          formData.append(
            "notice",
            new Blob([JSON.stringify(jsonData)], { type: "application/json" })
          );
          break;
        case "option2":
          apiUrl = "https://forcatmung.kr/api/news/create";
          formData.append(
            "news",
            new Blob([JSON.stringify(jsonData)], { type: "application/json" })
          );
          break;
        case "option3":
          apiUrl = "https://forcatmung.kr/api/dntExpense/create";
          formData.append(
            "dntExpense",
            new Blob([JSON.stringify(jsonData)], { type: "application/json" })
          );
          break;
        default:
      }

      try {
        const response = await axios.post(apiUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("게시글 작성 성공", response.data);
        switch (selectedOption) {
          case "option1":
            navigator("/announce/list");
            window.scrollTo(0, 0);
            break;
          case "option2":
            navigator("/news/list");
            window.scrollTo(0, 0);
            break;
          case "option3":
            navigator("/uselist/list");
            window.scrollTo(0, 0);
            break;
          default:
        }
      } catch (error) {
        console.error("작성 실패", error);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <main className="write-main">
        <div className="write-header">
          <h2>게시글 작성</h2>
        </div>
        <div className="write-category">
          <select
            className="write-select"
            value={selectedOption}
            onChange={handleSelectChange}
          >
            <option value="" disabled>
              게시판 선택
            </option>
            <option value="option1" className="write-select-option">
              공지사항
            </option>
            <option value="option2" className="write-select-option">
              센터소식
            </option>
            <option value="option3" className="write-select-option">
              후원내역
            </option>
          </select>
        </div>
        <div className="write-title">
          <p>제목</p>
          <textarea
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="제목을 입력해주세요."
          />
        </div>
        <div className="write-img-select">
          <p>대표 이미지 선택</p>
          <input
            type="file"
            id="imageInput"
            accept="image/*"
            onChange={(e) => setImageFile(e.target.files[0])}
            placeholder="이미지 선택"
          />
        </div>
        <p style={{ fontSize: "12px" }}>&#8251;10MB이하의 사진만 넣어주세요.</p>

        <div className="write-attachment-select">
          <p>첨부파일 선택</p>
          <input
            type="file"
            id="attachmentInput"
            onChange={(e) => setAttachFile(e.target.files[0])}
            placeholder="이미지 선택"
          />
        </div>
        <div className="write-content">
          <p>내용</p>
          <SmartEditor onContentChange={setContent} />
        </div>
      </main>
    </form>
  );
};

export default Write;
