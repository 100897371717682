import EditOneToMany from "../../../components/Support/OneToMany/EditOneToMany";
import "../../Main/Main.css";

const EditOneToManyPage = () => {
    return (
        <>
            <main className="main">
                <EditOneToMany />
            </main>
        </>
    );
}

export default EditOneToManyPage;