import "./MyPageDetail.css";
import numberIcon from "./assets/number.png";
import storyIcon from "./assets/history.png";
import personalityIcon from "./assets/personality.png";
import medicalIcon from "./assets/medical.png";
import Modal from "./Modal";
import { useState } from "react";

const MyPageDetail = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isStopConfirmed, setIsStopConfirmed] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const confirmStop = () => {
    setIsModalOpen(false);
    setIsStopConfirmed(true);
  };
  const closeStopConfirmModal = () => setIsStopConfirmed(false);

  const dummyImage = "https://picsum.photos/300";
  const animalName = "튼튼이";
  const animalIntro = "튼튼이는 4살 남자아이 입니다.";
  const animalNumber = "CY0001";
  const animalStory = "20년 3월 21일에 태어났어요.";
  const animalPersonality = "겁이 많지만 사람을 좋아하고 엄청 활발해요.";
  const animalMedical = "슬개골 탈구 수술을 한적이 있어요.";
  const payment = "카드결제";
  const payday = "매월 10일";

  // 테이블 데이터
  const headers = ["일자", "사용내역", "사용금액"];
  const data = [
    ["2024-01-10", "사료", "30,000원"],
    ["2024-02-10", "장난감", "10,000원"],
    ["2024-03-10", "병원비", "50,000원"],
    ["2024-03-10", "병원비", "50,000원"],
    ["2024-02-10", "장난감", "10,000원"],
    ["2024-03-10", "병원비", "50,000원"],
    ["2024-03-10", "병원비", "50,000원"],
    ["2024-02-10", "장난감", "10,000원"],
    ["2024-03-10", "병원비", "50,000원"],
    ["2024-03-10", "병원비", "50,000원"],
    ["2024-02-10", "장난감", "10,000원"],
    ["2024-03-10", "병원비", "50,000원"],
    ["2024-03-10", "병원비", "50,000원"],
    ["2024-03-10", "병원비", "50,000원"],
  ];

  return (
    <div className="my-page-detail-whole-container">
      <h1 className="my-page-detail-title">후원 상세내역</h1>
      <div className="my-page-detail-divider"></div>
      <div className="protection-animal-title">보호동물정보</div>

      <div className="my-page-detail-container">
        <div className="my-page-detail-information">
          <img
            src={dummyImage}
            alt="보호동물"
            className="my-page-detail-protection-animal-image"
          />
          <div className="my-page-detail-greeting">안녕하세요 후원자님!</div>
          <div className="my-page-detail-name">저는 {animalName} 입니다!</div>
          <div className="my-page-detail-intro">{animalIntro}</div>

          <div className="my-page-detail-all">
            <img src={numberIcon} alt="동물번호" />
            동물번호 {animalNumber}
          </div>
          <div className="my-page-detail-all">
            <img src={storyIcon} alt="동물기록" />
            {animalStory}
          </div>
          <div className="my-page-detail-all">
            <img src={personalityIcon} alt="동물성격" />
            {animalPersonality}
          </div>
          <div className="my-page-detail-all">
            <img src={medicalIcon} alt="의료기록" />
            {animalMedical}
          </div>
        </div>
        <div className="my-page-detail-support-usage-container">
          <div className="my-page-detail-support">
            <div className="my-page-support-info-title">후원 정보</div>
            <div>
              <span className="my-page-payment-method-text">결제방법</span>
              {payment}
            </div>
            <div>
              <span className="my-page-payment-date-text">결제일자</span>
              {payday} 후원금 결제
            </div>
          </div>
          <div className="my-page-detail-usage">
            <div className="protection-animal-title">후원금 사용 내역</div>
            <div className="my-page-table-container">
              <table className="my-page-detail-table">
                <thead>
                  <tr>
                    {headers.map((header, index) => (
                      <th key={index}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.map((row, index) => (
                    <tr key={index}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex}>{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="cancel-support-text">
            후원 동물의 후원을 중지하시려면
            <a
              href="/"
              className="cancel-support-link"
              onClick={(e) => {
                e.preventDefault();
                openModal();
              }}
            >
              후원 중지
            </a>
          </div>
        </div>
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <div className="support-stop-modal-content">
            <strong className="support-stop-modal-title">
              정말 후원 중지 하시겠습니까?
            </strong>
            <p className="support-stop-description">
              -
              <br />
              후원을 중지하시면 아래 내용이 적용됩니다.
              <br />
              <br />
              후원금 지원이 다음 결제일부터 중단되며
              <br />
              후원 내역은 기록으로만 보관됩니다.
              <br />
              <br />
              후원을 이어가주신다면,
              <br />
              보호 동물들에게 큰 희망이 됩니다.
            </p>
            <div className="support-stop-button-container">
              <button
                onClick={closeModal}
                className="support-cancel-cancel-button"
              >
                취소
              </button>
              <button
                onClick={confirmStop}
                className="support-cancel-confirm-button"
              >
                중지하기
              </button>
            </div>
          </div>
        </Modal>
        <Modal isOpen={isStopConfirmed} onClose={closeStopConfirmModal}>
          <div className="support-stop-modal-content">
            <p className="support-stop-final-title">
              후원중지가 완료되었습니다.
              <br />
              <br />
              언제든 따뜻한 마음으로
              <br />
              함께해 주시길 기다리겠습니다.
            </p>
            <div className="support-stop-button-container">
              <button
                onClick={closeStopConfirmModal}
                className="support-cancel-confirm-button"
              >
                닫기
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default MyPageDetail;
