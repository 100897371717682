const RewardGridItem = ({ imageUrl, title, descript }) => {
    return (
        <div className="pet-reward__grid-item">
            <img src={imageUrl} alt="이미지" className="reward__grid-image" />
            <div className="reward__grid-descript">
                <h3>{title}</h3>
                <p>{descript}</p>
            </div>
        </div>
    );
};

const FanClubReword = ({ onOpenModal }) => {
    return (
        <section className="pet-reward" style={{ backgroundImage: "none" }}>
            <h2 className="pet-reward__title">여러분의 소중한 후원금은<br />이렇게 쓰여요!</h2>
            <div className="pet-reward__grid">
                <RewardGridItem
                    imageUrl={"/images/pet-reward1.png"}
                    title={"안정적인 보금자리 제공"}
                    descript={
                        <>
                            사료, 패드(모래), 간식 등 후원동물에 따른 맞춤제공 <br /> L사료 후원 닥터독
                        </>
                    }
                />
                <RewardGridItem imageUrl={"/images/pet-reward2.png"} title={"의료 혜택 제공"} descript={<>건강검진, 중성화, 예방접종, 질병에 따른 수술 및 치료</>} />
                <RewardGridItem imageUrl={"/images/pet-reward3.png"} title={"그루밍 케어"} descript="위생케어, 목욕, 미용관리로 쾌적한 삶을 유지" />
                <RewardGridItem imageUrl={"/images/pet-reward4.png"} title={"정서 프로그램"} descript="교육 / 펀케어 / 삶에 질을 올려주는 놀이 진행" />
                <RewardGridItem
                    imageUrl={"/images/pet-reward5.png"}
                    title={"영구 보호"}
                    descript={
                        <>
                            입양불가한 아이들에게 마지막 순간까지 <br />
                            지켜주고 보호
                        </>
                    }
                />
                <RewardGridItem imageUrl={"/images/pet-reward6.png"} title={"사후 장례"} descript="따뜻한 배웅을 제공하기 위해 사후 장례 진행" />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '110px' }}>
                <button className="fan-club-middle-button custom-button" onClick={onOpenModal}>후원 신청하기 {">"}</button>
            </div>
        </section>
    );
}

export default FanClubReword;