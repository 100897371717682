// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sub-donate-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 15px;
}

.sub-donate-detail {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 40px;
}

.sub-donate-title {
    font-size: 15px;
    font-weight: bold;
}

.sub-donate-content {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 20px;
}

.sub-donate-content>p:nth-of-type(1) {
    font-size: 13px;
    font-weight: 600;
    color: #333;
}

.sub-donate-content>p:nth-of-type(2) {
    font-size: 12px;
    color: #005EB8;
}

@media (max-width: 1025px) {
    .sub-donate-info {
        margin-top: 15px;
        padding: 10px;
    }

    .sub-donate-detail {
        align-items: flex-start;
        gap: 20px;
    }

    .sub-donate-content {
        flex-direction: column;
        gap: 5px;
        align-items: flex-start;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/MyPage/SubscriptionDonation.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,qBAAqB;IACrB,SAAS;AACb;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,qBAAqB;IACrB,SAAS;AACb;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI;QACI,gBAAgB;QAChB,aAAa;IACjB;;IAEA;QACI,uBAAuB;QACvB,SAAS;IACb;;IAEA;QACI,sBAAsB;QACtB,QAAQ;QACR,uBAAuB;IAC3B;AACJ","sourcesContent":[".sub-donate-info {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    padding: 15px;\n}\n\n.sub-donate-detail {\n    display: flex;\n    flex-direction: row;\n    align-items: flex-end;\n    gap: 40px;\n}\n\n.sub-donate-title {\n    font-size: 15px;\n    font-weight: bold;\n}\n\n.sub-donate-content {\n    display: flex;\n    flex-direction: row;\n    align-items: flex-end;\n    gap: 20px;\n}\n\n.sub-donate-content>p:nth-of-type(1) {\n    font-size: 13px;\n    font-weight: 600;\n    color: #333;\n}\n\n.sub-donate-content>p:nth-of-type(2) {\n    font-size: 12px;\n    color: #005EB8;\n}\n\n@media (max-width: 1025px) {\n    .sub-donate-info {\n        margin-top: 15px;\n        padding: 10px;\n    }\n\n    .sub-donate-detail {\n        align-items: flex-start;\n        gap: 20px;\n    }\n\n    .sub-donate-content {\n        flex-direction: column;\n        gap: 5px;\n        align-items: flex-start;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
