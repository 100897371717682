const AwardsGridItem = ({ icon, title, description }) => {
  return (
    <div className="awards__grid-item">
      <img src={icon} alt="아이콘" className="awards__grid-item-img" />
      <h3 className="awards__grid-item-title title-blue">{title}</h3>
      <p className="awards__grid-item-description">{description}</p>
    </div>
  );
};

const OneToOneDetailAward = () => {
  return (
    <>
      <section className="award-info">
        <div className="award-upper-info">
          <div className="gradiant__white">
            <div className="one-to-one-mid__contant">
              <div className="one-to-one-mid__title title-blue">아이들의 인생을 지켜줄수있는</div>
              <div className="one-to-one-mid__title">후원자님이 되어주세요</div>
              <p>
                아이들의 하루 하루가 안정적으로 유지될 수 있도록
                <br /> 지원해주는 후원제도입니다
              </p>
            </div>
          </div>
        </div>
        <div className="award-info-awards">
          <div className="awards__grid">
            <AwardsGridItem
              icon="/icons/check-icon.svg"
              title={
                <>
                  후원 인증 뱃지
                  <br /> 제공
                </>
              }
              description={
                <>
                  후원자님의 아이디에 <br />
                  뱃찌를 넣어드립니다.
                </>
              }
            />
            <div className="awards__grid-divide"></div>
            <AwardsGridItem
              icon="/icons/heart-icon.svg"
              title={
                <>
                  후원 동물의
                  <br /> 발도장 편지
                </>
              }
              description={
                <>
                  후원자님의 생일에
                  <br />
                  발도장편지를 보내드립니다.
                </>
              }
            />
            <div className="awards__grid-divide"></div>
            <AwardsGridItem
              icon="/icons/camera-icon.svg"
              title={
                <>
                  후원 동물의
                  <br /> 사진 전송
                </>
              }
              description={
                <>
                  후원동물의 이쁜 사진을
                  <br />
                  우편으로 보내드립니다.
                </>
              }
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default OneToOneDetailAward;
