import React from 'react'
import './MyPageBanner.css'

const MyPageBanner = () => {
    return (
        <div className='mypage-banner-container'>
            <div className='mypage-banner-text'>
                <p className='mypage-banner-main-text'>마이페이지</p>
                <p className='mypage-banner-explain-text'>동물보호의 뜻을 함께해주셔서 감사합니다.</p>
            </div>
        </div>
    )
}

export default MyPageBanner;