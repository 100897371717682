import React, { useState } from 'react';
import './Calendar.css'; // 스타일링

const Calendar = ({ startDate, endDate, onDateSelect, closeCalendar }) => {
  const today = new Date();
  const [selectedDate, setSelectedDate] = useState(today);
  const [currentYear, setCurrentYear] = useState(today.getFullYear());
  const [currentMonth, setCurrentMonth] = useState(today.getMonth());

  // startDate와 endDate를 Date 객체로 변환
  const minDate = new Date(startDate);
  const maxDate = new Date(endDate);

  const daysOfWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  const monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const handlePrevMonth = () => {
    const newDate = new Date(currentYear, currentMonth - 1, 1);
    if (newDate >= minDate) {
      setCurrentMonth(newDate.getMonth());
      setCurrentYear(newDate.getFullYear());
    }
  };

  const handleNextMonth = () => {
    const newDate = new Date(currentYear, currentMonth + 1, 1);
    if (newDate <= maxDate) {
      setCurrentMonth(newDate.getMonth());
      setCurrentYear(newDate.getFullYear());
    }
  };

  const handlePrevYear = () => {
    if (currentYear > minDate.getFullYear()) {
      setCurrentYear(currentYear - 1);
    }
  };

  const handleNextYear = () => {
    if (currentYear < maxDate.getFullYear()) {
      setCurrentYear(currentYear + 1);
    }
  };

  const handleMonthChange = (event) => {
    setCurrentMonth(Number(event.target.value));
  };

  const handleYearChange = (event) => {
    setCurrentYear(Number(event.target.value));
  };

  const handleDateSelect = (day) => {
    const newDate = new Date(currentYear, currentMonth, day);
    if (newDate >= minDate && newDate <= maxDate) {
      setSelectedDate(newDate);
    }
  };

  const handleOkClick = () => {
    if (selectedDate) {
      onDateSelect(selectedDate); // 선택된 날짜를 부모 컴포넌트에 전달
    }
    // 캘린더 닫기 로직 추가 필요
  };

  const handleClearClick = () => {
    setCurrentYear(today.getFullYear());
    setCurrentMonth(today.getMonth());
    setSelectedDate(today); // 오늘 날짜로 설정
  };

  const handleCancelClick = () => {
    closeCalendar();
  };

  const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();
  const daysInMonth = getDaysInMonth(currentYear, currentMonth);

  const daysArray = Array.from({ length: firstDayOfMonth }, () => null).concat(
    Array.from({ length: daysInMonth }, (_, i) => i + 1)
  );

  return (
    <div className="calendar">
      <div className="calendar-header">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <button onClick={handlePrevMonth} disabled={new Date(currentYear, currentMonth - 1) < minDate} style={{fontSize:"1rem"}}>
          <svg width="24" height="24" viewBox="0 0 24 24">
            <path d="M15 18l-6-6 6-6" fill="none" stroke="#005EB8" strokeWidth="2"/>
          </svg>
          </button>
          <select value={currentMonth} onChange={handleMonthChange}>
            {monthNames.map((monthName, index) => (
              <option key={index} value={index}>{monthName}</option>
            ))}
          </select>
          <button onClick={handleNextMonth} disabled={new Date(currentYear, currentMonth + 1) > maxDate}>
          <svg width="24" height="24" viewBox="0 0 24 24">
            <path d="M9 18l6-6-6-6" fill="none" stroke="#005EB8" strokeWidth="2"/>
          </svg>
          </button>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <button onClick={handlePrevYear} disabled={currentYear <= minDate.getFullYear()}>
          <svg width="24" height="24" viewBox="0 0 24 24">
            <path d="M15 18l-6-6 6-6" fill="none" stroke="#005EB8" strokeWidth="2"/>
          </svg>
          </button>
          <select value={currentYear} onChange={handleYearChange}>
            {Array.from({ length: maxDate.getFullYear() - minDate.getFullYear() + 1 }, (_, i) => (
              <option key={i} value={minDate.getFullYear() + i}>{minDate.getFullYear() + i}</option>
            ))}
          </select>
          <button onClick={handleNextYear} disabled={currentYear >= maxDate.getFullYear()}>
          <svg width="24" height="24" viewBox="0 0 24 24">
            <path d="M9 18l6-6-6-6" fill="none" stroke="#005EB8" strokeWidth="2"/>
          </svg>
          </button>
        </div>
      </div>
      <div className="calendar-days">
        {daysOfWeek.map((day, index) => (
          <div key={`${day}-${index}`} className="day-label">
            {day}
          </div>
        ))}
        {daysArray.map((day, index) => {
          const date = day ? new Date(currentYear, currentMonth, day) : null;
          const isDisabled = !day || date < minDate || date > maxDate;
          const isSelected = day === selectedDate.getDate() &&
                             currentMonth === selectedDate.getMonth() &&
                             currentYear === selectedDate.getFullYear();
          return (
            <div
              key={index}
              className={`calendar-cell ${isSelected ? 'selected' : ''} ${isDisabled ? 'disabled' : ''}`}
              onClick={!isDisabled ? () => handleDateSelect(day) : undefined}
            >
              {day}
            </div>
          );
        })}
      </div>
      <div className="calendar-footer">
      <button className="footer-left" onClick={handleClearClick}>Clear</button>
        <div style={{ display: 'flex', gap: '8px' }}>
      <button onClick={handleCancelClick}>Cancel</button>
      <button onClick={handleOkClick}>OK</button>
      </div>
    </div>
    </div>
  );
};

export default Calendar;