// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.one-to-many-footer {
    background-color: white;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    padding: 30px;
}

.one-to-many-footer-title {
    text-align: center;
    margin-top: 20px;
    font-size: 20px;
    font-weight: bold;
}

.one-to-many-footer-grid {
    display: flex;
    justify-content: center;
}

.one-to-many-footer-grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 65px;
    transform: scale(0.9);
}

.one-to-many-footer-grid-item {
    width: 100%;
    max-width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
    object-fit: contain;
    display: block;
    margin: 0 auto;
}

@media (max-width: 1025px) {
    .one-to-many-footer {
        padding: 0;
    }

    .one-to-many-footer-title {
        font-size: 20px;
    }

    .one-to-many-footer-grid-container {
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Support/OneToMany/OneToManyFooter.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,wBAAwB;IACxB,2BAA2B;IAC3B,4BAA4B;IAC5B,aAAa;IACb,sBAAsB;IACtB,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,qCAAqC;IACrC,SAAS;IACT,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;IACnB,cAAc;IACd,cAAc;AAClB;;AAEA;IACI;QACI,UAAU;IACd;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,qCAAqC;QACrC,SAAS;IACb;AACJ","sourcesContent":[".one-to-many-footer {\n    background-color: white;\n    background-size: contain;\n    background-position: center;\n    background-repeat: no-repeat;\n    display: flex;\n    flex-direction: column;\n    padding: 30px;\n}\n\n.one-to-many-footer-title {\n    text-align: center;\n    margin-top: 20px;\n    font-size: 20px;\n    font-weight: bold;\n}\n\n.one-to-many-footer-grid {\n    display: flex;\n    justify-content: center;\n}\n\n.one-to-many-footer-grid-container {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    gap: 65px;\n    transform: scale(0.9);\n}\n\n.one-to-many-footer-grid-item {\n    width: 100%;\n    max-width: 100%;\n    height: auto;\n    aspect-ratio: 1 / 1;\n    object-fit: contain;\n    display: block;\n    margin: 0 auto;\n}\n\n@media (max-width: 1025px) {\n    .one-to-many-footer {\n        padding: 0;\n    }\n\n    .one-to-many-footer-title {\n        font-size: 20px;\n    }\n\n    .one-to-many-footer-grid-container {\n        grid-template-columns: repeat(1, 1fr);\n        gap: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
