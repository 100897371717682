import HeritageBanner from "./HeritageBanner";
import HeritageBottomBanner from "./HeritageBottomBanner";
import HeritageHowToParticipate from "./HeritageHowToParticipate";
import HeritageReward from "./HeritageReward";
import HeritageSubBanner from "./HeritageSubBanner";
import HeritageSupportType from "./HeritageSupportType";
import "./heritage.css";

const Heritage = () => {
  return (
    <main className="md-main">
      <HeritageBanner />
      <HeritageSubBanner />
      <HeritageSupportType />
      <HeritageHowToParticipate />
      <HeritageReward />
      <HeritageBottomBanner />
    </main>
  );
};

export default Heritage;
