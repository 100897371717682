import './AdoptionField.css'
import FileSelectImage from '../assets/image 91.png'

const AdoptionCarerHouseImageField = ({ carerHouseImages, setCarerHouseImages }) => {
    const handleImageChange = (e) => {
        setCarerHouseImages(Array.from(e.target.files)); // 파일들을 배열로 설정
    };
    return (
        <div className='adoption-house-image-form-row'>
            <label>집안 내부 사진</label>
            <div className='adoption-form-image-file-box'>
                <input
                    id="custom-file-input"
                    type='file'
                    onChange={handleImageChange}
                    style={{ display: 'none' }}
                    multiple
                />
                {/* 커스텀 버튼 */}
                <label htmlFor="custom-file-input" className="adoption-custom-file-input">
                    <img
                        src={FileSelectImage}
                        alt="파일 선택 버튼"
                        style={{ width: "150px", cursor: "pointer" }}
                    />
                </label>
                <span>* 아이들이 입양가서 사는 집 내부모습을 보기 위함입니다 *</span>
                {/* 선택된 이미지 파일 이름과 개수 */}
                <div style={{ fontSize: 12 }}>
                    {carerHouseImages.length > 0 &&
                        <p>선택된 이미지: {carerHouseImages.length}개</p>}
                    {carerHouseImages && carerHouseImages.length > 0 && (
                        <ul>
                            {Array.from(carerHouseImages).map((file, index) => (
                                <li key={index}>{file.name}</li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
        </div>
    )
}

export default AdoptionCarerHouseImageField