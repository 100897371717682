import React from 'react'
import './AdoptionCheckList.css'

const AdoptionCheckList = ({ onClose, onNext }) => {
    return (
        <div className='adoption-check-list-container'>
            <div className='check-list-content-container'>
                <div className='adoption-check-list-header'>
                    <h1>입양 신청 확인사항</h1>
                    <h3>안녕하세요. 사단법인 포캣멍센터입니다.<br /></h3>
                    <br />
                    <h3>입양 신청 후 바로 입양 확정이 되는 것이 아니며,</h3>
                    <h3>1. 신청서 작성 / 2. 유선 통화 / 3. 입양확정으로 진행됩니다.</h3>
                </div>
                <div className='check-list-body'>
                    <div>
                        <h2>입양 책임비</h2>
                        <ul>
                            <li>아이들의 입양 책임비는 30만원입니다.<br />
                                6개월 이내 중성화, 건강검진 후 서류 제출 시 15만원 반환되며, 남은 15만 원은 포캣멍센터의 전액 기부됩니다.<br />
                                후원금 내역은 홈페이지 개설 후 투명하게 공개합니다.
                            </li>
                        </ul>
                    </div>
                    <br />
                    <div>
                        <h2>입양 조건</h2>
                        <ul>
                            <li>예방접종 및 구충, 중성화 수술, 마이크로칩 등록, 연 1회 이상 건강검진</li>
                            <li>거짓 입양 문의가 잦아져 아이들의 안전한 입양을 위해 신청자님 신분이 확인되는 명함 등 신분 확인이 가능한 자</li>
                            <li>3차 입양 확정 시 입양 서류작성/신분증 사진/입양 과정 사진, 동영상 촬영이 가능한 자<br />
                                <strong style={{ fontSize: '13px', color: '#000', background: '#fff', textDecoration: 'none' }}>(입양 동물 학대 안전 문제 방지를 위해 센터의 장기 보관 됩니다.)</strong>
                            </li>
                            <li>주보호자가 만 19세 이상인 자</li>
                        </ul>
                    </div>
                    <div className='adoption-caution-list'>
                        <p>- 파양시 타인에게 재입양 불가 포캣멍센터에 아이 인계하는 계약서 작성</p>
                        <p>- 미성년자 신청이 불가함으로 주보호자가 직접 신청해주세요</p>
                        <p>- 파양시 타인에게 재입양 불가 포캣멍센터에 아이 인계하는 계약서 작성</p>
                    </div>
                </div>
            </div>
            <div className='check-list-footer'>
                <h3>위 내용을 숙지하였으며, 계속 진행하길 원하신다면</h3>
                <h3>다음으로 버튼을 눌러주세요</h3>
            </div>
            <div className='adoption-frame-button-container'>
                <button className='button close' onClick={onClose}>닫기</button>
                <button className='button next' onClick={onNext}>다음으로</button>
            </div>
        </div>
    )
}

export default AdoptionCheckList