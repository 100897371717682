const AboutOrganization = () => {
  return (
    <>
      <section class="org-chart__section">
        <div className="org-chart">
          <div class="org-chart__node org-chart__node--top">총회</div>
          <div class="org-chart__line"></div>
          <div class="org-chart__node">이사장</div>
          <div class="org-chart__line"></div>
          <div class="org-chart__node">이사회</div>
          <div class="org-chart__line"></div>
          <div class="org-chart__teams">
            <div className="teams__contant">
              <div class="org-chart__line"></div>
              <div class="org-chart__team">경영팀</div>
            </div>
            <div className="teams__contant">
              <div class="org-chart__line"></div>
              <div class="org-chart__team">관리팀</div>
            </div>
            <div className="teams__contant">
              <div class="org-chart__line"></div>
              <div class="org-chart__team">구조팀</div>
            </div>
          </div>
        </div>
      </section>

      <section class="org-table">
        <h2 class="org-table__title">이사회</h2>
        <table class="org-table__content">
          <thead>
            <tr>
              <th>구분</th>
              <th>이름</th>
              <th>직책</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>이사진</td>
              <td>양인영</td>
              <td>이사장</td>
            </tr>
            <tr>
              <td></td>
              <td>김한별</td>
              <td>이사</td>
            </tr>
            <tr>
              <td></td>
              <td>박혜령</td>
              <td>이사</td>
            </tr>
            <tr>
              <td></td>
              <td>안선우</td>
              <td>이사</td>
            </tr>
            <tr>
              <td></td>
              <td>조성연</td>
              <td>이사</td>
            </tr>
            <tr>
              <td></td>
              <td>김헤진</td>
              <td>이사</td>
            </tr>
          </tbody>
        </table>
      </section>
    </>
  );
};

export default AboutOrganization;
