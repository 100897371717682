import "./TemporaryBanner.css";

const SupportPageBanner = () => {
  return (
    <div className="temporary-banner-container">
      <div className="temporary-banner-text">
        <p className="temporary-banner-main-text">일시후원</p>
        <p className="temporary-banner-explain-text">
          한 번의 따뜻한 손 길을 내밀어주세요
        </p>
      </div>
    </div>
  );
};

export default SupportPageBanner;
