// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-table-container {
    margin-top: 15%;
}

.admin-table-row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding: 2vh 0;
  }
  
  .admin-table-cell {
    flex: 1 1;
    text-align: center;
  }
  
  .admin-table-cell:empty {
    visibility: hidden;
  }

  @media (min-width: 1025px) {
    .admin-table-container {
      margin-top: 15vh;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/Admin/AdminMain.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,6BAA6B;IAC7B,cAAc;EAChB;;EAEA;IACE,SAAO;IACP,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE;MACE,gBAAgB;IAClB;EACF","sourcesContent":[".admin-table-container {\n    margin-top: 15%;\n}\n\n.admin-table-row {\n    display: flex;\n    justify-content: space-between;\n    border-bottom: 1px solid #ccc;\n    padding: 2vh 0;\n  }\n  \n  .admin-table-cell {\n    flex: 1;\n    text-align: center;\n  }\n  \n  .admin-table-cell:empty {\n    visibility: hidden;\n  }\n\n  @media (min-width: 1025px) {\n    .admin-table-container {\n      margin-top: 15vh;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
