import React from 'react';
import "./AdminAdoption.css"

const AdminAdoption = () => {
    const donationData = [
        { animalImage : "https://forcatmung.s3.ap-northeast-2.amazonaws.com/news/20250118075650875.png", animalName: 'test1', animalId : "26", adoptionId : "30"},
        { animalImage : "https://forcatmung.s3.ap-northeast-2.amazonaws.com/news/20250118075650875.png", animalName: 'test2', animalId : "27", adoptionId : "31"},
        { animalImage : "https://forcatmung.s3.ap-northeast-2.amazonaws.com/news/20250118075650875.png", animalName: 'test3', animalId : "28", adoptionId : "32"},
        { animalImage : "https://forcatmung.s3.ap-northeast-2.amazonaws.com/news/20250118075650875.png", animalName: 'test4', animalId : "29", adoptionId : "33"},
        { animalImage : "https://forcatmung.s3.ap-northeast-2.amazonaws.com/news/20250118075650875.png", animalName: 'test5', animalId : "30", adoptionId : "34"},
        { animalImage : "https://forcatmung.s3.ap-northeast-2.amazonaws.com/news/20250118075650875.png", animalName: 'test6', animalId : "31", adoptionId : "35"},
        { animalImage : "https://forcatmung.s3.ap-northeast-2.amazonaws.com/news/20250118075650875.png", animalName: 'test7', animalId : "32", adoptionId : "36"},
    ]


    return (
        <div className="admin-adoption-table-container">
            <h2 className='admin-adoption-table'>입양관리</h2>
            <div style={{marginRight:"5%"}}>
            <div style={{borderTop: '1px solid #ccc'}}></div>
            {donationData.map((item) => (
            <div key={item.date} className="admin-adoption-table-row">
                <span className="admin-adoption-table-cell"><img src={item.animalImage} alt="Animal" className="admin-adoption-image" /></span>
                <span className="admin-adoption-table-cell">{item.animalName}</span>
                <span className="admin-adoption-table-cell"> 입양신청서 보기</span>
            </div>
            ))}
            </div>
          </div>
    )
}


export default AdminAdoption;