import axios from "axios";

const BASE_URL = "https://forcatmung.kr/";

const getOneToOneDetailById = async (animalid) => {
  try {
    const response = await axios.get(`${BASE_URL}api/animaldetail/onetoone/detail`, {
      params: { animalid },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching animal details:", error);
    throw new Error("동물 정보를 불러오는 데 실패했습니다.");
  }
};

export { getOneToOneDetailById };
