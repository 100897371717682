import React from "react";
import "./TalentField.css";
import TalentSelectField from "./TalentSelectField";
import CoporationSlider from "./CoporationSlider";

const TalentField = () => {
  return (
    <>
      <main className="">
        <div className="talent-Select-Field-container">
          <div className="select-banner-text">
            <p className="select-banner-main-text">재능 기부 참여하기</p>
            <p className="select-banner-explain-text">
              포캣멍센터에서는 다양한 분야에서 도움을 주실 수 있는 분들을 항상
              찾고 있습니다.
              <br />
              다양한 분야로 도움을 주실 분들은 작성해주시면 연락 드리겠습니다.
            </p>
          </div>
        </div>
        <div className="talent-container">
          <div className="rounded-rectangle">
            <p className="select-category-text">
              도움을 주실 수 있는 분야를 눌러 신청해주세요
            </p>
          </div>
          <TalentSelectField />
          <div className="rounded-rectangle-small">
            <p className="select-category-text">
              주기적으로 도움을 주시는 기업들
            </p>
          </div>
          <CoporationSlider />
        </div>
      </main>
    </>
  );
};

export default TalentField;
