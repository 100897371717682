import { useState } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import { menuItems } from "./menuItems";
import logo from "../../assets/mainLogo.png";

const Header = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [isNavActive, setIsNavActive] = useState(false);

  const toggleNav = () => {
    setIsNavActive(!isNavActive);
  };

  const handleLinkClick = () => {
    setIsNavActive(false);
  };

  return (
    <header className="total-header">
      <div className="bottom-header">
        <div className="bottom-context">
          <div className="logo">
            <Link to="/" className="header-main-link">
              <img src={logo} alt="로고" className="logo-img" />
            </Link>
          </div>
          <div className="hamburger" onClick={toggleNav}>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <nav className={`main-nav ${isNavActive ? "active" : ""}`}>
            <ul className="nav-links">
              {menuItems.map((item, index) => (
                <li
                  key={index}
                  onMouseEnter={() => setActiveIndex(index)}
                  onMouseLeave={() => setActiveIndex(null)}
                >
                  <Link
                    to={item.path}
                    className="header-main-link"
                    onClick={handleLinkClick}
                  >
                    {item.name}
                  </Link>
                  {(isNavActive || activeIndex === index) &&
                    item.subItems.length > 0 && (
                      <ul className="sub-menu">
                        {item.subItems.map((subItem, subIndex) => (
                          <li key={subIndex}>
                            <Link
                              to={subItem.path}
                              className="header-main-link-drop"
                              onClick={handleLinkClick}
                            >
                              {subItem.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                </li>
              ))}
              <li className="nav-donation">
                <Link to="/donation" onClick={handleLinkClick}>
                  바로 후원하기
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
