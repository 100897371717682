import { useEffect, useState } from "react";
import SubscribeDonation from "./subscribeDonation";

const Step1 = ({ handleData, handleNext, data }) => {
  const [amount, setAmount] = useState("");
  const [selectDonation, setSelectDonation] = useState("");
  const [essential, setEssential] = useState(false);
  const { handleBilling } = SubscribeDonation();

  useEffect(() => {
    if (data) {
      if (data.pay_amount !== 0) {
        setAmount(data.pay_amount);
      }
      setSelectDonation(data.pay_type);
    }
  }, [data]);

  // 금액 버튼 클릭/입력시 금액 선택
  const handleAmountClick = (value) => {
    setAmount(value);
  };
  //   정기 / 일시후원 선택
  const handleDonationClick = (buttonId) => {
    setSelectDonation(buttonId);
  };

  //미입력시 브레이크
  const handleDataSend = () => {
    if (
      amount === null ||
      amount === "0" ||
      amount === "" ||
      selectDonation === ""
    ) {
      setEssential(true);
    } else {
      handleData({ pay_amount: amount, pay_type: selectDonation });
      handleNext();
    }
  };

  return (
    <div>
      <h2>후원정보</h2>
      <div className="donation-type">
        <button
          className={`type-btn ${
            selectDonation === "정기후원" ? "active" : ""
          }`}
          // 일시후원만 받을거라 막아둠.
          onClick={() => handleDonationClick("정기후원")}
        >
          정기후원
        </button>
        <button
          className={`type-btn ${
            selectDonation === "일시후원" ? "active" : ""
          }`}
          onClick={() => handleDonationClick("일시후원")}
        >
          일시후원
        </button>
        <p style={{ fontSize: "13px" }}>
          &#8251; 현재 테스트용입니다.
          <br /> 실제로 결제가 이루어지지 않습니다.
        </p>
      </div>
      <div className="donation-amount">
        <button className="amount-btn" onClick={() => handleAmountClick(10000)}>
          10,000원
        </button>
        <button className="amount-btn" onClick={() => handleAmountClick(20000)}>
          20,000원
        </button>
        <button className="amount-btn" onClick={() => handleAmountClick(30000)}>
          30,000원
        </button>
        <button className="amount-btn" onClick={() => handleAmountClick(50000)}>
          50,000원
        </button>
        <input
          type="number"
          placeholder="직접 입력"
          step="1000"
          min="0"
          value={amount}
          className="amount-input"
          onChange={(e) => handleAmountClick(e.target.value)}
        />
      </div>
      <button className="next-btn" onClick={() => handleDataSend()}>
        다음 &gt;
      </button>
      {essential && (
        <p className="essential">&#8251; 모든 정보를 입력해주세요.</p>
      )}
      <p>
        <button onClick={handleBilling} className="next-btn">
          정기결제 테스트용입니다.
        </button>
      </p>
    </div>
  );
};

export default Step1;
