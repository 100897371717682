import './AdoptionField.css'

const AdoptionReasonForAdoptionField = ({ formData, setFormData }) => {
    return (
        <div className='adoption-reason-form-row'>
            <label>입양 이유</label>
            <textarea
                value={formData.reasonForAdoption}
                placeholder='입양하고자 하는 이유를 성의껏 작성 부탁드립니다.'
                onChange={(e) => setFormData({ ...formData, reasonForAdoption: e.target.value })}
            />
        </div>
    )
}

export default AdoptionReasonForAdoptionField