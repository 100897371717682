import "./one-to-one-detail.css";

const GridComponent = ({ imageUrl, imageName, imageDescription }) => {
  return (
    <>
      <div className="grid-item">
        <img src={imageUrl} alt={imageName} className="grid-item__image" />
        <span className="grid-item__description">{imageDescription}</span>
        <div className="gradiant__image"></div>
      </div>
    </>
  );
};

const OneToOneMidBanner = () => {
  return (
    <>
      <section className="one-to-one-mid__banner">
        <div className="gradiant__banner">
          <div className="one-to-one-mid__contant">
            <div className="one-to-one-mid__title title-blue">입양이 어렵거나 불가한 보호 동물에게</div>
            <div className="one-to-one-mid__title">든든한 후원자가 되어주세요</div>
            <p>
              아이들의 하루 하루가 안정적으로 유지될 수 있도록
              <br /> 지원해주는 후원제도입니다
            </p>
          </div>
        </div>
        <section className="grid-container">
          <GridComponent imageUrl={"/images/one-to-one-mid-img.png"} imageName={"메롱이"} imageDescription={"노령 동물"} />
          <GridComponent imageUrl={"/images/one-to-one-mid-img2.png"} imageName={"메롱이"} imageDescription={"장애 동물"} />
          <GridComponent imageUrl={"/images/one-to-one-mid-img3.png"} imageName={"메롱이"} imageDescription={"호스피스"} />
          <GridComponent imageUrl={"/images/one-to-one-mid-img4.png"} imageName={"메롱이"} imageDescription={"구조 동물"} />
          <GridComponent imageUrl={"/images/one-to-one-mid-img5.png"} imageName={"메롱이"} imageDescription={"은퇴 동물"} />
        </section>
      </section>
    </>
  );
};

export default OneToOneMidBanner;
