import Slider from "react-slick";

const logos = [
  { id: 1, name: "페스룸", description: "사료 후원", logo: "/logos/companys/pethroom.png" },
  { id: 2, name: "로얄캐닌", description: "간식 후원", logo: "/logos/companys/royalcanin.png" },
  { id: 3, name: "닥터독", description: "용품 후원", logo: "/logos/companys/dr-dog.png" },
  { id: 4, name: "개선장군", description: "장소 후원", logo: "/logos/companys/generaldog.png" },
  { id: 5, name: "아미오", description: "사료 후원", logo: "/logos/companys/amio.png" },
  { id: 6, name: "페스룸", description: "간식 후원", logo: "/logos/companys/pethroom.png" },
];

const settings = {
  infinite: true,
  centerMode: true,
  centerPadding: "0",
  slidesToShow: 5,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 3000,
  arrows: false,
  dots: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const CompanySponsorshipSlider = () => {
  return (
    <div className="slider__container">
      <section className="logo-slider__section">
        <Slider {...settings}>
          {logos.map((logo) => (
            <div key={logo.id} className="logo-slide">
              <div className="logo-image__container">
                <img src={logo.logo} alt={logo.name} className="logo-image" />
              </div>
              <div className="logo-slide__description">{logo.description}</div>
            </div>
          ))}
        </Slider>
      </section>
    </div>
  );
};

export default CompanySponsorshipSlider;
