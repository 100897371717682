import "./SupportPageBanner.css";

const SupportPageBanner = () => {
  return (
    <div className="support-banner-container">
      <div className="support-banner-text">
        <p className="support-banner-main-text">후원하기</p>
        <p className="support-banner-explain-text">
          보호동물에게 한줄기의 빛이 되어주세요
        </p>
      </div>
    </div>
  );
};

export default SupportPageBanner;
