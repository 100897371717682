
import React, { useState } from 'react';
import Calendar from './Calendar'; // 위에서 제공한 Calendar 컴포넌트
import axios from 'axios'; // API 요청을 위해 axios 사용

const AdminDay = () => {
    const [showCalendar, setShowCalendar] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
  
    const handleButtonClick = () => {
      setShowCalendar(true);
    };
  
    const handleDateSelect = (date) => {
      setSelectedDate(date);
      setShowCalendar(false);
      
      // 선택된 날짜를 API 요청 형식으로 변환
      const formattedDate = date.toISOString().split('T')[0]; // YYYY-MM-DD 형식
      const apiUrl = `https://forcatmung.kr/api/pay/todayInfo/endpoint?start=${formattedDate}&end=${formattedDate}`;
      
      // API 요청
      axios.get(apiUrl)
        .then(response => {
          console.log('API 응답:', response.data);
          // 여기에서 응답 데이터 처리
        })
        .catch(error => {
          console.error('API 요청 오류:', error);
        });
    };
  
    const closeCalendar = () => {
      setShowCalendar(false);
    };
  
    return (
      <div>
        <button onClick={handleButtonClick}>일자 선택</button>
        {showCalendar && (
          <Calendar
            startDate="2024-01-01" // 시작 날짜 설정
            endDate="2024-12-31" // 종료 날짜 설정
            onDateSelect={handleDateSelect}
            closeCalendar={closeCalendar}
          />
        )}
        {selectedDate && (
          <p>선택된 날짜: {selectedDate.toLocaleDateString()}</p>
        )}
      </div>
    );
  };
  
export default AdminDay;

