// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mypage-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.mypage-content {
    display: flex;
    flex-direction: row;
    padding: 30px;
    flex: 1 1;
}

.mypage-outlet {
    flex: 1 1;
    padding-top: 10px;
    margin-left: 100px;
}

.history-header {
    display: flex;
    gap: 15px;
    align-items: flex-end;
}

.history-header__donate-type {
    font-weight: 700;
    font-size: 2rem;
}

.history-header__description {
    font-size: 0.8rem;
    color: #005EB8;
}

@media (max-width: 1025px) {
    .mypage-content {
        flex-direction: column;
    }

    .mypage-outlet {
        margin-left: 0;
    }

    .history-header {
        flex-direction: column;
        align-items: start;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/MyPage/MyPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,SAAO;AACX;;AAEA;IACI,SAAO;IACP,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI;QACI,sBAAsB;IAC1B;;IAEA;QACI,cAAc;IAClB;;IAEA;QACI,sBAAsB;QACtB,kBAAkB;IACtB;AACJ","sourcesContent":[".mypage-container {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n}\n\n.mypage-content {\n    display: flex;\n    flex-direction: row;\n    padding: 30px;\n    flex: 1;\n}\n\n.mypage-outlet {\n    flex: 1;\n    padding-top: 10px;\n    margin-left: 100px;\n}\n\n.history-header {\n    display: flex;\n    gap: 15px;\n    align-items: flex-end;\n}\n\n.history-header__donate-type {\n    font-weight: 700;\n    font-size: 2rem;\n}\n\n.history-header__description {\n    font-size: 0.8rem;\n    color: #005EB8;\n}\n\n@media (max-width: 1025px) {\n    .mypage-content {\n        flex-direction: column;\n    }\n\n    .mypage-outlet {\n        margin-left: 0;\n    }\n\n    .history-header {\n        flex-direction: column;\n        align-items: start;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
