import React from 'react';
import './objective.css';
import Slider from 'react-slick';
import { objectivedummydata } from './objectivedummydata';

const Objective = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,  // 처음 5개의 카드만 보이게 설정
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,  // 슬라이드 화살표를 숨김
        draggable: false,  // 드래그로 슬라이드 넘기지 못하게 설정
    };

    return (
        <div className="objectives-slider">
            <Slider {...settings}>
                {objectivedummydata.map((item, index) => (
                    <div key={index}>
                        <div className="objective-card">
                            <div className="objective-image">
                                <img src={item.imageUrl} alt={item.title} />
                            </div>
                            <div className="objective-content">
                                <h3 className="objective-title">{item.title}</h3>
                                <p className="objective-donor">
                                    기부자: {item.donorName ? `${item.donorName} (님)` : "없음"}                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default Objective;