const AdoptionCompleteComment = ({ onClose }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
        margin: "20px auto",
        height: "25vh",
        width: "45vh",
        gap: "20px",
      }}
    >
      <h1
        style={{
          fontSize: "clamp(12px, 5vw, 18px)",
          fontWeight: 600,
          margin: "20px auto",
        }}
      >
        입양 신청이 완료되었습니다.
      </h1>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          fontSize: "clamp(10px, 5vw, 15px)",
          textAlign: "center",
          gap: "5px",
        }}
      >
        <h3>소중한 결정을 내려주셔서 진심으로 감사합니다.</h3>
        <h3>보호소의 담당자가 신청 내용을 검토한 후,</h3>
        <h3>빠른 시일 내에 연락드리겠습니다.</h3>
      </div>
      <div
        style={{
          margin: "25px auto",
        }}
      >
        <button
          style={{
            height: "auto",
            width: 150,
            fontSize: "18px",
            cursor: "pointer",
            color: "#fff",
            backgroundColor: "#000",
          }}
          onClick={onClose}
        >
          닫기
        </button>
      </div>
    </div>
  );
};

export default AdoptionCompleteComment;
