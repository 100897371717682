import { useState, useEffect } from 'react';

// 화면 크기에 따른 항목 개수를 설정하는 커스텀 훅
const useResponsivePage = (defaultItemsPerPage = 8, mobileItemsPerPage = 5) => {
    const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 1025) {
                setItemsPerPage(mobileItemsPerPage);
            } else {
                setItemsPerPage(defaultItemsPerPage);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [defaultItemsPerPage, mobileItemsPerPage]);

    return itemsPerPage;
};

export default useResponsivePage;
