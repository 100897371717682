import './AdoptionField.css'

const AdoptionCarerHouseWidthField = ({ formData, setFormData }) => {
    return (
        <div className='adoption-form-row'>
            <label>주택 평수</label>
            <input
                placeholder="ex) 28평"
                value={formData.carerHouseWidth}
                style={{ height: 30, width: '40%', paddingLeft: 10 }}
                onChange={(e) => setFormData({ ...formData, carerHouseWidth: e.target.value })}
            />
        </div>
    )
}

export default AdoptionCarerHouseWidthField