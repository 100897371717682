import { useNavigate } from "react-router-dom";

const OneToOneDetailNav = () => {
  const navgate = useNavigate();

  const handleToList = () => {
    navgate(-1);
  };

  return (
    <nav className="detail-nav">
      <div className="detail-nav__content">
        <p className="detail-nav__subtitle">아이들의 가족이 되어주세요</p>
        <h1 className="detail-nav__title">1대 1 후원동물</h1>
      </div>
      <button type="button" onClick={handleToList} className="detail-nav__button">
        목록으로
      </button>
    </nav>
  );
};

export default OneToOneDetailNav;
