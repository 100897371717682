const AboutText = () => {
  return (
    <section className="about-text">
      <div className="about-text__content">
        <h1 className="about-text__title">
          유기동물 113,072마리 중 51,584마리가 <br /> 보호센터에서 생을 마감합니다.
        </h1>
        <h2 className="about-text__title about-text__title--sub">농림축산검역본부 ‘2023년 반려동물 보호·복지 실태조사’</h2>
      </div>
    </section>
  );
};

export default AboutText;
