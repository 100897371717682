// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-banner-container {
    width: 100%;
    height: 10vh;
    background-color: #005eb8;
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin-banner-text {
    color: white;
}

.admin-banner-main-text {
    font-weight: bold;
    font-size: 3vw;
}

@media (min-width: 601px) {
    .banner-container {
        height: 20vh;
    }
}

@media (min-width:1025px) {
    .admin-banner-container {
        height: 30vh;
    }
    
    .admin-banner-main-text {
        font-size: 2vw;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Admin/AdminBanner.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI;QACI,YAAY;IAChB;AACJ;;AAEA;IACI;QACI,YAAY;IAChB;;IAEA;QACI,cAAc;IAClB;AACJ","sourcesContent":[".admin-banner-container {\n    width: 100%;\n    height: 10vh;\n    background-color: #005eb8;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.admin-banner-text {\n    color: white;\n}\n\n.admin-banner-main-text {\n    font-weight: bold;\n    font-size: 3vw;\n}\n\n@media (min-width: 601px) {\n    .banner-container {\n        height: 20vh;\n    }\n}\n\n@media (min-width:1025px) {\n    .admin-banner-container {\n        height: 30vh;\n    }\n    \n    .admin-banner-main-text {\n        font-size: 2vw;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
