import React from 'react'

const FanClubCompleteComment = ({ onclose }) => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: "center",
            margin: '20px auto',
            height: '20vh',
            width: '30vh',
            gap: '25px'
        }}>
            <h1 style={{ fontSize: 'clamp(10px, 2vw, 15px)', fontWeight: 'bold' }}>따뜻한 후원에 진심으로 감사드립니다.</h1>
            <h3 style={{ fontSize: 'clamp(11px, 2vw, 17px)' }}>여러분의 따뜻한 마음이 보호소 동물들에게 <br />
                새로운 삶을 선물하고 있습니다.
            </h3>
            <div style={{
                width: 'auto',
                fontSize: 18,
                cursor: 'pointer',
                margin: '10px auto'
            }}>
                <button
                    style={{
                        height: 'clamp(20px, 10vw, 30px)',
                        width: 'clamp(100px, 10vw, 130px)',
                        backgroundColor: '#000',
                        color: '#fff'
                    }}
                    onClick={onclose}
                >닫기
                </button>
            </div>
        </div>
    )
}

export default FanClubCompleteComment