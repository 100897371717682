import CompanySponsorshipBanner from "./CompanySponsorshipBanner";
import CompanySponsorshipBottom from "./CompanySponsorshipBottom";
import CompanySponsorshipCompanys from "./CompanySponsorshipCompanys";
import CompanySponsorshipHeader from "./CompanySponsorshipHeader";
import CompanySponsorshipMidBanner from "./CompanySponsorshipMidBanner";
import CompanySponsorshipSlider from "./CompanySponsorshipSlider";
import "./company-sponsorship.css";
const CompanySponsorship = () => {
  return (
    <main className="md-main">
      <div className="company__container">
        <div className="bg-blue-dot"></div>
        <CompanySponsorshipBanner />
        <CompanySponsorshipHeader discription={"유기동물들의 복지 개선에 힘이 되어주세요"} subTitle={"기업후원"} />
        <CompanySponsorshipSlider />
      </div>
      <CompanySponsorshipCompanys />
      <CompanySponsorshipMidBanner />
      <CompanySponsorshipBottom />
    </main>
  );
};

export default CompanySponsorship;
