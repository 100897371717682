import { NavLink } from "react-router-dom";
import { useState } from "react";

const VolunteeringNav = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  return (
    <nav className="volunteering-nav">
      <button className="about-nav__toggle" onClick={toggleDropdown}>
        메뉴 ☰
      </button>
      <ul className={`volunteering-nav__list ${isOpen ? "volunteering-nav__list--open" : "" }`}>
        <li className="volunteering-nav__item">
          <NavLink to="/volunteering/beauty" className={({ isActive }) => (isActive ? "volunteering-nav__link volunteering-nav__link--active" : "volunteering-nav__link")} onClick={closeDropdown}>
            미용 봉사
          </NavLink>
        </li>
        <li className="volunteering-nav__item">
          <NavLink to="/volunteering/training" className={({ isActive }) => (isActive ? "volunteering-nav__link volunteering-nav__link--active" : "volunteering-nav__link")} onClick={closeDropdown}>
            훈련 봉사
          </NavLink>
        </li>
        <li className="volunteering-nav__item">
          <NavLink to="/volunteering/walk" className={({ isActive }) => (isActive ? "volunteering-nav__link volunteering-nav__link--active" : "volunteering-nav__link")} onClick={closeDropdown}>
            산책 봉사
          </NavLink>
        </li>
        <li className="volunteering-nav__item">
          <NavLink to="/volunteering/cleaning" className={({ isActive }) => (isActive ? "volunteering-nav__link volunteering-nav__link--active" : "volunteering-nav__link")} onClick={closeDropdown}>
            청소 봉사
          </NavLink>
        </li>
        <li className="volunteering-nav__item">
          <NavLink to="/volunteering/relocation" className={({ isActive }) => (isActive ? "volunteering-nav__link volunteering-nav__link--active" : "volunteering-nav__link")} onClick={closeDropdown}>
            이동 봉사
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default VolunteeringNav;
