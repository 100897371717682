import "./Volunteering.css";

const VolunteeringBanner = () => {
  return (
    <div className="volunteering-banner-container">
      <div className="volunteering-banner-text">
      <p className="volunteering-banner-main-text">봉사 활동</p>
        <p className="volunteering-banner-explain-text">
          우리의 작은 손길로, 소중한 생명을 지켜주세요
        </p>
      </div>
    </div>
  );
};

export default VolunteeringBanner;
