const HeritageHeader = ({ subTitle }) => {
  return (
    <div className="heritage-banner">
      <div className="heritage-banner__line"></div>
      <h2 className="heritage-banner__sub-title">{subTitle}</h2>
    </div>
  );
};

export default HeritageHeader;
