import React from 'react'
import './TalentBanner.css'

const TalentBanner = () => {
    return (
        <div className='talent-banner-container'>
            <div className='talent-banner-text'>
                <p className='talent-banner-main-text'>재능기부</p>
                <p className='talent-banner-explain-text'>다양한 분야에서 도움을 주실수 있는 분들을 항상 찾고 있습니다. </p>
            </div>
        </div>
    )
}

export default TalentBanner