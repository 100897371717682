// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.myactivity-slider-card-container {
    display: flex;
    flex-direction: column;

    gap: 2px;
}

.myactivity-slider-card-container h2 {
    font-size: 18px;
    font-weight: bold;
}

.myacitivity-slider-img-container {
    margin: 10px auto;
}

.myactivity-slider-card-container img {
    width: auto;
    height: 15vh;
}

.myactivity-slider-card-p-container {
    display: flex;
    flex-direction: row;

    gap: 10px
}

@media (max-width: 1600px) {
    .myactivity-slider-card-container {
        gap: 0px;
    }

    .myactivity-slider-card-container h2 {
        font-size: 15px;
    }

    .myactivity-slider-card-p-container {
        font-size: 12px;
    }
}

@media (max-width: 800px) {
    .myactivity-slider-card-container {
        gap: 0px;
    }

    .myactivity-slider-card-container h2 {
        font-size: 12px;
    }

    .myactivity-slider-card-p-container {
        font-size: 7px;
    }
}

@media (max-width: 600px) {
    .myactivity-slider-card-container img {
        height: 12vh;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/MyPage/MyActivitySlider.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;;IAEtB,QAAQ;AACZ;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;;IAEnB;AACJ;;AAEA;IACI;QACI,QAAQ;IACZ;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,QAAQ;IACZ;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,cAAc;IAClB;AACJ;;AAEA;IACI;QACI,YAAY;IAChB;AACJ","sourcesContent":[".myactivity-slider-card-container {\n    display: flex;\n    flex-direction: column;\n\n    gap: 2px;\n}\n\n.myactivity-slider-card-container h2 {\n    font-size: 18px;\n    font-weight: bold;\n}\n\n.myacitivity-slider-img-container {\n    margin: 10px auto;\n}\n\n.myactivity-slider-card-container img {\n    width: auto;\n    height: 15vh;\n}\n\n.myactivity-slider-card-p-container {\n    display: flex;\n    flex-direction: row;\n\n    gap: 10px\n}\n\n@media (max-width: 1600px) {\n    .myactivity-slider-card-container {\n        gap: 0px;\n    }\n\n    .myactivity-slider-card-container h2 {\n        font-size: 15px;\n    }\n\n    .myactivity-slider-card-p-container {\n        font-size: 12px;\n    }\n}\n\n@media (max-width: 800px) {\n    .myactivity-slider-card-container {\n        gap: 0px;\n    }\n\n    .myactivity-slider-card-container h2 {\n        font-size: 12px;\n    }\n\n    .myactivity-slider-card-p-container {\n        font-size: 7px;\n    }\n}\n\n@media (max-width: 600px) {\n    .myactivity-slider-card-container img {\n        height: 12vh;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
