import React, { useCallback, useEffect, useRef, useState } from "react";

const SmartEditor = ({ onContentChange, initialContent }) => {
  const editorRef = useRef(null); // SmartEditor 객체 참조
  const [isInitialized, setIsInitialized] = useState(false); // 초기화 상태

  useEffect(() => {
    if (isInitialized) return;

    const script = document.createElement("script");
    script.src = "/smarteditor/js/service/HuskyEZCreator.js";
    script.async = false;

    script.onload = () => {
      console.log("SmartEditor2 스크립트 로드 완료");

      if (window.nhn && window.nhn.husky && window.nhn.husky.EZCreator) {
        const oEditor = [];
        window.nhn.husky.EZCreator.createInIFrame({
          oAppRef: oEditor,
          elPlaceHolder: "editorPlaceholder",
          sSkinURI: "/smarteditor/SmartEditor2Skin.html",
          fCreator: "createSEditor2",
          htParams: {
            bUseToolbar: true,
            bUseVerticalResizer: true,
            bUseModeChanger: true,
          },
          fOnAppLoad: () => {
            console.log("SmartEditor2 초기화 완료");
            setIsInitialized(true);
            editorRef.current = oEditor[0];

            if (initialContent) {
              editorRef.current.exec("SET_IR", [initialContent]);
            }
          },
        });

        console.log("SmartEditor2 초기화 시작");
      }
    };

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [isInitialized, initialContent]);

  // 내용 업데이트 함수
  const handleContentUpdate = useCallback(() => {
    if (editorRef.current) {
      editorRef.current.exec("UPDATE_CONTENTS_FIELD", []);
      const content = editorRef.current.getIR(); // 에디터에서 내용 가져오기
      onContentChange(content);
    }
  }, [onContentChange]);

  // 작성 버튼 클릭 시 호출되는 함수
  const handleSubmit = () => {
    if (!isInitialized || !editorRef.current) {
      alert("에디터가 아직 초기화되지 않았습니다. 잠시만 기다려 주세요.");
      return;
    }
    handleContentUpdate(); // 내용 업데이트
  };

  return (
    <div className="editor-container">
      <textarea id="editorPlaceholder" style={{ display: "none" }} />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <button
          onClick={handleSubmit}
          style={{
            padding: "5px 10px",
            fontSize: "16px",
            backgroundColor: "#005bc0",
            color: "white",
            border: "1px solid #005bc0",
            cursor: "pointer",
          }}
        >
          작성하기
        </button>
      </div>
    </div>
  );
};

export default SmartEditor;
