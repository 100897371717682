import SupportBanner from "../Banner/SupportBanner";

const AboutIntro = () => {
  return (
    <>
      <section className="intro-text">
        <div className="intro-text__container">
          <h1 className="intro-text__title">
            안녕하세요.
            <br /> 사단법인 포캣멍센터입니다.
          </h1>
          <p>
            동물들에게도 "동물 소외계층"의 아이들이 있다는 사실 알고계시나요?{" "}
            <br />
            <br />
            노령, 장애, 각종 질병의 아이들에게는 가족을 만날 수 있는 기회조차
            어렵습니다.
            <br /> 아이들의 마지막 순간은 해피엔딩이 될 수 있도록 <br />
            <br /> 포캣멍센터와 동물보호 뜻을 함께해 주세요!
          </p>
        </div>
      </section>
      <SupportBanner />
    </>
  );
};

export default AboutIntro;
