import './Percent.css'
import React from 'react'
import image from './assets/DONATE.png'

const DonateGoalImage = () => {
    return (
        <div className='image-wrapper'>
            <img className='responsive-image' src={image} alt='포켓멍 센터의 목표'></img>
        </div>
    )
}

export default DonateGoalImage