import "./FanClubAward.css";

const FanClubAward = () => {
    return (
        <section className="fan-club-award-info">
            <div className="fan-club-award-upper-info">
                <div className="text-content">
                    <div className="one-to-one-mid__title title-blue">아이들의 인생을 지켜줄수있는</div>
                    <div className="one-to-one-mid__title">후원자님이 되어주세요</div>
                    <p>
                        아이들이 건강하게 갖정을 찾을 수 있게 도와주세요
                    </p>
                </div>

                <div className="image-section">
                    <img src="/images/award-banner-bg.png" alt="Award Banner" />
                </div>
            </div>
        </section>
    );
};

export default FanClubAward;
