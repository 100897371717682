import { NavLink } from "react-router-dom";
import { useState } from "react";

const AboutNav = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  return (
    <nav className="about-nav">
      <button className="about-nav__toggle" onClick={toggleDropdown}>
        메뉴 ☰
      </button>
      <ul className={`about-nav__ul ${isOpen ? "about-nav__list--open" : ""}`}>
        <li className="about-nav__item">
          <NavLink to="/about/intro" className={({ isActive }) => (isActive ? "about-nav__link about-nav__link--active" : "about-nav__link")} onClick={closeDropdown}>
            인사말
          </NavLink>
        </li>
        <li className="about-nav__item">
          <NavLink to="/about/organization" className={({ isActive }) => (isActive ? "about-nav__link about-nav__link--active" : "about-nav__link")} onClick={closeDropdown}>
            조직도
          </NavLink>
        </li>
        <li className="about-nav__item">
          <NavLink to="/about/history" className={({ isActive }) => (isActive ? "about-nav__link about-nav__link--active" : "about-nav__link")} onClick={closeDropdown}>
            연혁
          </NavLink>
        </li>
        <li className="about-nav__item">
          <NavLink to="/about/business" className={({ isActive }) => (isActive ? "about-nav__link about-nav__link--active" : "about-nav__link")} onClick={closeDropdown}>
            사업내용
          </NavLink>
        </li>
        <li className="about-nav__item">
          <NavLink to="/about/purpose" className={({ isActive }) => (isActive ? "about-nav__link about-nav__link--active" : "about-nav__link")} onClick={closeDropdown}>
            설립 취지
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default AboutNav;
