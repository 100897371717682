import './AdoptionField.css'

const AdoptionIsMarriedField = ({ formData, setFormData }) => {
    return (
        <div className='adoption-form-row'>
            <label>결혼 여부</label>
            <div className='adoption-married-form-row'>
                {['기혼', '미혼', '결혼 예정'].map((status) => (
                    <div key={status} className='adoption-married-radio-container'>
                        <input
                            type="radio"
                            value={status}
                            checked={formData.isMarried === status}
                            onChange={(e) => setFormData({ ...formData, isMarried: e.target.value })}
                        />
                        <p>{status}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default AdoptionIsMarriedField