import React, { useState, useEffect, useCallback } from "react";
import OneToOneContent from "../../Main/OneToOne/OneToOneContent";
import "./OneToOnePageGrid.css";
import OneToManyPaginationComponent from "../OneToMany/OneToManyPaginationComponent";

const ITEMS_PER_PAGE = 9; // 한 페이지에 표시할 항목 수

const OneToOnePageGrid = ({ listData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [currentData, setCurrentData] = useState([]);

  const updateCurrentData = useCallback(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    setCurrentData(listData.slice(startIndex, endIndex));
  }, [currentPage, listData]);

  useEffect(() => {
    setTotalPages(Math.ceil(listData.length / ITEMS_PER_PAGE));
    updateCurrentData();
  }, [listData, currentPage, updateCurrentData]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="one-to-one-page">
      <div className={"one-to-one-content-grid"}>
        {currentData.map((item) => (
          <div key={item.animalId} className="one-to-one-content-grid-item">
            <OneToOneContent
              index={item.animalId}
              name={item.animalName}
              kind={item.type}
              age={item.age}
              gender={item.gender}
              donator={item.donation}
              image={item.image}
            />
          </div>
        ))}
      </div>
      <div
        className="OneToOne-pagination-container"
        style={{ marginTop: "70px", marginBottom: "30px" }}
      >
        <OneToManyPaginationComponent
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default OneToOnePageGrid;
