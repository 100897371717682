import React, { useState, useEffect } from "react";
import TalentSliderData from "./TalentSliderData";

const TalentSlider = () => {
  const [donorCount, setDonorCount] = useState(0);
  const [isAdmin] = useState(false); // 관리자상태 여부
  const [slidersWithTooltips, setSlidersWithTooltips] = useState(TalentSliderData());
  const [currentIndex, setCurrentIndex] = useState(0);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  useEffect(() => {
    setDonorCount(25); // 기부자 수 초기값 설정
  }, []);

  // 이미지 수정 툴팁 텍스트
  const handleTooltipChange = (index, event) => {
    const updatedSliders = slidersWithTooltips.map((slider, i) =>
      i === index ? { ...slider, tooltip: event.target.value } : slider
    );
    setSlidersWithTooltips(updatedSliders);
  };

  // 이미지 추가
  const handleAddImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const newSlider = { image: reader.result, tooltip: "새로운 이미지" };

        // 기존 슬라이드 뒤에 새로운 슬라이드 추가
        const updatedSliders = [...slidersWithTooltips, newSlider];
        setSlidersWithTooltips(updatedSliders);
      };
      reader.readAsDataURL(file);
    }
  };

  // 이미지 수정
  const handleEditImage = (index, event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const updatedSliders = slidersWithTooltips.map((slider, i) =>
          i === index ? { ...slider, image: reader.result } : slider
        );
        setSlidersWithTooltips(updatedSliders);
      };
      reader.readAsDataURL(file);
    }
  };

  // 이미지 삭제
  const handleDeleteImage = (index) => {
    const updatedSliders = slidersWithTooltips.filter((_, i) => i !== index);
    setSlidersWithTooltips(updatedSliders);
  };

  // 슬라이드 오른쪽으로 이동 (하나씩 이동)
  const moveRight = () => {
    setCurrentIndex((prevIndex) =>
      (prevIndex + 1) % slidersWithTooltips.length
    );
  };

  // 슬라이드 왼쪽으로 이동 (하나씩 이동)
  const moveLeft = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slidersWithTooltips.length - 1 : prevIndex - 1
    );
  };

  // 현재 보이는 슬라이드 4개만 선택
  const currentSliders = slidersWithTooltips.slice(currentIndex, currentIndex + 4);
  if (currentSliders.length < 4) {
    currentSliders.push(...slidersWithTooltips.slice(0, 4 - currentSliders.length)); // 4개 미만일 경우 나머지 채우기
  }

  return (
    <div className="my-component">
      <div className="slider-container">
        <div className="circle-container">
          <button onClick={moveLeft} className="slider-button left"></button>
          {currentSliders.map((slider, index) => (
            <div
              key={index}
              className="circle-wrapper"
              onMouseEnter={() => isAdmin && setHoveredIndex(index)}
              onMouseLeave={() => isAdmin && setHoveredIndex(null)}
            >
              <div className={`circle ${index === 1 ? "circle-second" : ""}`}>
                <img
                  src={slider.image}
                  alt={`Slide ${index}`}
                  className="circle-image"
                />
                {isAdmin && hoveredIndex === index && (
                  <div className="edit-overlay">
                    {/* 수정 텍스트 상자 */}
                    <textarea
                      value={slider.tooltip}
                      onChange={(event) => handleTooltipChange(currentIndex + index, event)}
                      className="tooltip-textarea"
                    />
                    {/* 수정 버튼 */}
                    <label htmlFor={`file-input-edit-${currentIndex + index}`} className="edit-label">
                      수정
                    </label>
                    <input
                      type="file"
                      id={`file-input-edit-${currentIndex + index}`}
                      className="file-input"
                      accept="image/*"
                      onChange={(event) => handleEditImage(currentIndex + index, event)}
                    />
                    {/* 삭제 버튼 */}
                    <button
                      className="delete-button"
                      onClick={() => handleDeleteImage(currentIndex + index)}
                    >
                      삭제
                    </button>
                  </div>
                )}
                <div className="tooltip">
                  <p className="tooltip-text">{slider.tooltip}</p>
                </div>
              </div>
            </div>
          ))}
          <button onClick={moveRight} className="slider-button right"></button>
          <div className="talent-text">
            <p className="talent-text-first">함께 해주신 재능기부자분</p>
            <p className="talent-text-second">{donorCount}명</p>
            <p className="talent-text-third">감사합니다.</p>
          </div>
        </div>
      </div>

      {isAdmin && (
        <div className="add-image-container">
          <label htmlFor="file-input-add" className="add-image-label">
            이미지 추가
          </label>
          <input
            type="file"
            id="file-input-add"
            className="file-input"
            accept="image/*"
            onChange={handleAddImage}
          />
        </div>
      )}
    </div>
  );
};

export default TalentSlider;
