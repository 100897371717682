import React, { useState } from "react";
import "./TalentSelectField.css";
import Talent1 from "./assets/Talent1.png";
import Talent2 from "./assets/Talent2.png";
import Talent3 from "./assets/Talent3.png";
import Talent4 from "./assets/Talent4.png";
import Talent5 from "./assets/Talent5.png";

const TalentSelectField = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const [checkboxStates, setCheckboxStates] = useState({
    agree1: false,
    agree2: false,
    agree3: false,
    agree4: false,
    agree5: false,
  });

  const [inputValue, setInputValue] = useState("");

  const toggleAccordion = (index) => {
    if (activeIndex !== index) {
      setActiveIndex(index);
    }
  };

  const handleCheckboxChange = (id) => {
    setCheckboxStates({
      ...checkboxStates,
      [id]: !checkboxStates[id],
    });
  };

  const handleApplyButtonClick = (id) => {
    if (!checkboxStates[id]) {
      alert("회원가입 시 제공된 개인정보 이용에 동의해야 합니다.");
      return;
    }
    alert("재능기부 신청이 완료되었습니다.");
  };

  return (
    <div>
      {/* 첫 번째 */}
      <div className="talent-box">
        <div className="talent-header" onClick={() => toggleAccordion(0)}>
          <img src={Talent1} className="talent-image" alt="" />
          <p className="talent-title">저는 반려동물 미용에 재능있어요!</p>
          <button className="arrow-btn">▼</button>
        </div>
        {activeIndex === 0 && (
          <div>
            <p className="talent-description">
              반려동물 미용에 재능이 있으신 회원님!
              <br />
              재능기부 신청을 해주시면 관련된 절차와 필요한 사항에 대해 빠른
              시일 내에 연락드릴 예정입니다.
            </p>
            <div className="talent-details">
              <input
                type="checkbox"
                id="agree1"
                checked={checkboxStates.agree1}
                onChange={() => handleCheckboxChange("agree1")}
              />
              <label htmlFor="agree1">
                회원가입 시 제공된 개인정보는 서비스 제공, 회원 관리, 재능기부
                연락 목적으로 수집·이용됩니다.
                <br />
              </label>
              <button
                className="apply-btn"
                onClick={() => handleApplyButtonClick("agree1")}
              >
                재능기부 신청
              </button>
            </div>
          </div>
        )}
      </div>

      {/* 두 번째 */}
      <div className="talent-box">
        <div className="talent-header" onClick={() => toggleAccordion(1)}>
          <img src={Talent2} className="talent-image" alt="" />
          <p className="talent-title">저는 디자인에 재능있어요!</p>
          <button className="arrow-btn">▼</button>
        </div>
        {activeIndex === 1 && (
          <div>
            <p className="talent-description">
              디자인에 재능이 있으신 회원님!
              <br />
              재능기부 신청을 해주시면 관련된 절차와 필요한 사항에 대해 빠른
              시일 내에 연락드릴 예정입니다.
            </p>
            <div className="talent-details">
              <input
                type="checkbox"
                id="agree2"
                checked={checkboxStates.agree2}
                onChange={() => handleCheckboxChange("agree2")}
              />
              <label htmlFor="agree2">
                회원가입 시 제공된 개인정보는 서비스 제공, 회원 관리, 재능기부
                연락 목적으로 수집·이용됩니다.
                <br />
              </label>
              <button
                className="apply-btn"
                onClick={() => handleApplyButtonClick("agree2")}
              >
                재능기부 신청
              </button>
            </div>
          </div>
        )}
      </div>

      {/* 세 번째 */}
      <div className="talent-box">
        <div className="talent-header" onClick={() => toggleAccordion(2)}>
          <img src={Talent3} className="talent-image" alt="" />
          <p className="talent-title">저는 동물 훈련에 재능있어요!</p>
          <button className="arrow-btn">▼</button>
        </div>
        {activeIndex === 2 && (
          <div>
            <p className="talent-description">
              동물 훈련에 재능이 있으신 회원님!
              <br />
              재능기부 신청을 해주시면 관련된 절차와 필요한 사항에 대해 빠른
              시일 내에 연락드릴 예정입니다.
            </p>
            <div className="talent-details">
              <input
                type="checkbox"
                id="agree3"
                checked={checkboxStates.agree3}
                onChange={() => handleCheckboxChange("agree3")}
              />
              <label htmlFor="agree3">
                회원가입 시 제공된 개인정보는 서비스 제공, 회원 관리, 재능기부
                연락 목적으로 수집·이용됩니다.
                <br />
              </label>
              <button
                className="apply-btn"
                onClick={() => handleApplyButtonClick("agree3")}
              >
                재능기부 신청
              </button>
            </div>
          </div>
        )}
      </div>

      {/* 네 번째 */}
      <div className="talent-box">
        <div className="talent-header" onClick={() => toggleAccordion(3)}>
          <img src={Talent4} className="talent-image" alt="" />
          <p className="talent-title">저는 촬영에 재능있어요!</p>
          <button className="arrow-btn">▼</button>
        </div>
        {activeIndex === 3 && (
          <div>
            <p className="talent-description">
              촬영 재능이 있으신 회원님!
              <br />
              재능기부 신청을 해주시면 관련된 절차와 필요한 사항에 대해 빠른
              시일 내에 연락드릴 예정입니다.
            </p>
            <div className="talent-details">
              <input
                type="checkbox"
                id="agree4"
                checked={checkboxStates.agree4}
                onChange={() => handleCheckboxChange("agree4")}
              />
              <label htmlFor="agree4">
                회원가입 시 제공된 개인정보는 서비스 제공, 회원 관리, 재능기부
                연락 목적으로 수집·이용됩니다.
                <br />
              </label>
              <button
                className="apply-btn"
                onClick={() => handleApplyButtonClick("agree4")}
              >
                재능기부 신청
              </button>
            </div>
          </div>
        )}
      </div>

      {/* 다섯 번째 */}
      <div className="talent-box">
        <div className="talent-header" onClick={() => toggleAccordion(4)}>
          <img src={Talent5} className="talent-image" alt="" />
          <p className="talent-title">저는 다른 것에 재능있어요!</p>
          <button className="arrow-btn">▼</button>
        </div>
        {activeIndex === 4 && (
          <div>
            <p className="talent-description">
              회원님께서는 어떤 분야로 도움을 주고자 하시나요?
              <br />
              <br />
              <input
                type="text"
                placeholder="도움을 주실 수 있는 분야를 적어주세요."
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                className="input-box"
              />
              <br />
              재능기부 신청을 해주시면 관련된 절차와 필요한 사항에 대해 빠른
              시일 내에 연락드릴 예정입니다.
            </p>
            <div className="talent-details">
              <input
                type="checkbox"
                id="agree5"
                checked={checkboxStates.agree5}
                onChange={() => handleCheckboxChange("agree5")}
              />
              <label htmlFor="agree5">
                회원가입 시 제공된 개인정보는 서비스 제공, 회원 관리, 재능기부
                연락 목적으로 수집·이용됩니다.
                <br />
              </label>
              <button
                className="apply-btn"
                onClick={() => handleApplyButtonClick("agree5")}
              >
                재능기부 신청
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TalentSelectField;
