import React from "react";
import TalentBanner from "../../components/Talent/TalentBanner";
import TalentSlider from "../../components/Main/Talent/TalentSlider";
import TalentField from "../../components/Talent/TalentField";
import Percent from "../../components/Main/Percent/percent";
import "../../components/Main/Talent/TalentSlider.css";

const TalentPage = () => {
  return (
    <>
      <main className="md-main">
        <TalentBanner />
        <div className="talentPage">
          <TalentSlider />
        </div>
        <TalentField />
        <Percent />
      </main>
    </>
  );
};

export default TalentPage;
