const HistoryHeader = ({ donateType }) => {
    return (
        <div className="history-header">
            <span className="history-header__donate-type">{donateType}</span>
            <span className="history-header__description">
                후원 항목 클릭시, 후원금 사용내역을 보실 수 있습니다.
            </span>
        </div>
    );
};

export default HistoryHeader;
