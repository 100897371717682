import React from "react";
import "./AnnounceItem.css";
import DOMPurify from "dompurify";

const AnnounceItem = ({ id, imageUrl, title, author, date, content }) => {
  return (
    <div className="announce-item-container">
      {imageUrl && <img src={imageUrl} alt={title} />}
      <div className="announce-item-text">
        <div className="announce-item-text-title">{title}</div>
        <div className="announce-item-text-author">
          {author} | <span>{date}</span>
        </div>
        <div
          className="announce-item-text-content"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(content.substring(0, 100)),
          }}
        />
      </div>
    </div>
  );
};

export default AnnounceItem;
