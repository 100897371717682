import "./News.css";

const NewsBanner = () => {
  return (
    <div className="news-banner-container">
      <div className="news-banner-text">
        <p className="news-banner-main-text">센터 소식</p>
        <p className="news-banner-explain-text">
          포캣멍센터의 주요 소식을 전해드립니다
        </p>
      </div>
    </div>
  );
};

export default NewsBanner;
