import React, { useState } from "react";
import { Link } from "react-router-dom";

const FAQList = () => {
  const faqs = [
    {
      id: 1,
      question: "후원금 어디에 사용되나요?",
      answer: "후원금은 동물의 사료, 의료비, 구조비 등 필요한 곳에 투명하게 사용됩니다.",
    },
    {
      id: 2,
      question: "입양을 하고 싶습니다. 어떤 것이 필요하나요?",
      answer: (
        <p>
          입양하려는 반려동물의 특성과 이해도, 경제력 그리고 가족구성원의 동의 등이 있으며,
          <br />
          제일 중요한 한 생명을 책임지는 책임감이 필요합니다.
        </p>
      ),
    },
    {
      id: 3,
      question: "입양은 어렵지만 다른 방법으로 도움을 주려면 어떻게 하나요?",
      answer: (
        <p>
          도울 수 있는 방법은 너무나 많습니다. 대표적으로 구조픽업, 기부모금, 입양홍보, 임시보호 등이 있으며, <br />
          다양한 분야에서 도움을 주실수 있는 봉사자분들은 항상 찾고 있습니다. <br />
          다양한 분야로 도움을 주실 분들은 재능기부 페이지로 연락주시면 감사하겠습니다.
          <br /> <br />
          <Link to="/">
            <b>재능기부</b>
          </Link>
        </p>
      ),
    },
  ];

  const FAQItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleFAQ = () => {
      setIsOpen(!isOpen);
    };

    return (
      <li className="faq-item">
        <div className="faq-item__question" onClick={toggleFAQ}>
          <div className="item__content">
            <span className="item__q">Q</span>
            <p className="item__question">{question}</p>
          </div>
          <span className="faq-item__icon">{isOpen ? "▲" : "▼"}</span>
        </div>
        {isOpen && (
          <div className="faq-item__answer">
            <div className="item__content">
              <span className="item__a">A</span>
              <p className="item__answer">{answer}</p>
            </div>
          </div>
        )}
      </li>
    );
  };

  return (
    <ul className="faq-list">
      {faqs.map((faq) => (
        <FAQItem key={faq.id} question={faq.question} answer={faq.answer} />
      ))}
    </ul>
  );
};

export default FAQList;
