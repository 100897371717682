import { Outlet } from "react-router-dom";
import Percent from "../../../components/Main/Percent/percent";
import OneToManyPageBanner from "../../../components/Support/OneToMany/OneToManyPageBanner";
import "../../Main/Main.css";

const OneToManyPage = () => {
    return (
        <>
            <main className="md-main">
                <OneToManyPageBanner />
                <Outlet />
                <Percent />
            </main>
        </>
    );
}

export default OneToManyPage;