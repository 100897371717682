const CompanySponsorshipBottom = () => {
  return (
    <section className="bottom-img__section">
      <img src="/icons/buildings.svg" alt="빌딩 아이콘" className="bottom-buildings" />
      <div className="bottom-img__container">
        <div className="bottom-bg"></div>
        <div className="bottom-catdog-container">
          <p className="bottom-catdog__p">기업의 따뜻한 나눔, 동물들에게 희망을 선물하세요!</p>
          <img src="/icons/cat-dog.svg" alt="고양이 개 로고" className="bottom-catdog" />
          <p className="bottom-catdog__p">forcatmungcenter@naver.com로 문의 주세요</p>
        </div>
      </div>
    </section>
  );
};

export default CompanySponsorshipBottom;
