import './AdoptionField.css'

const AdoptionCarerEarningField = ({ formData, setFormData }) => {

    const handleIncomeChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            carerEarnings: e.target.value
        }));
    };

    return (
        <div className='adoption-earning-form-row'>
            <label>주 보호자 소득</label>
            <div className='adoption-earning-input-container'>
                {['~ 100만원', '100만원 ~ 200만원', '200만원 ~ 300만원', '300만원 ~ 500만원', '500만원 ~'].map((label, index) => (
                    <div key={index} className='adoption-earning-radio-container'>
                        <input
                            type="radio"
                            value={label}
                            checked={formData.carerEarnings === label}
                            onChange={handleIncomeChange}
                        />
                        <p>{label}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default AdoptionCarerEarningField