import "./SupportPageUse.css";
import supportUse from "./assets/supportUse.png";

const SupportPageUse = () => {
  return (
    <>
      <div className="support-use-main">
        <div className="support-use-line" />
        <div className="support-text">
          여러분의 후원 감사합니다
          <br />
          <span>후원금은 이렇게 사용 됩니다!</span>
        </div>
      </div>
      <img src={supportUse} alt="후원금 사용처" />
    </>
  );
};

export default SupportPageUse;
