import React from 'react'
import './AdminBanner.css'

const AdminBanner = () => {
    return (
        <div className='admin-banner-container'>
            <div className='admin-banner-text'>
                <p className='admin-banner-main-text'>forcatmung 관리자 페이지</p>
            </div>
        </div>
    )
}

export default AdminBanner;