import HeritageHeader from "./HeritageHeader";

const HeritageReward = () => {
  return (
    <section className="heritage-reward">
      <HeritageHeader subTitle={"후원혜택"} />
      <ul className="heritage-reward__list">
        <li className="heritage-reward__item">
          <img src="/images/heritage_1st.png" alt="" className="heritage-reward__image" />
          <div className="heritage-reward__description">
            <h3 className="heritage-reward__title">법률 자문 제공</h3>
            <p className="heritage-reward__text">
              유산 기부를 고려하는 기부자들을 위해 전문적인 법률 자문을 제공하여
              <br /> 기부 과정이 원활하고 신뢰롭게 진행될 수 있도록 돕습니다.
            </p>
          </div>
        </li>
        <li className="heritage-reward__item">
          <div className="heritage-reward__description">
            <h3 className="heritage-reward__title">후원 증서 발급</h3>
            <p className="heritage-reward__text">
              기부자 여러분의 소중한 나눔에 감사의 마음을 전하고,
              <br />
              후원 활동의 투명성을 높이기 위해 후원증서를 발급하고 있습니다.
            </p>
          </div>
          <img src="/images/heritage_2nd.png" alt="" className="heritage-reward__image" />
        </li>
        <li className="heritage-reward__item">
          <img src="/images/heritage_3rd.png" alt="" className="heritage-reward__image" />
          <div className="heritage-reward__description">
            <h3 className="heritage-reward__title">기념 액자 제작</h3>
            <p className="heritage-reward__text">
              유산 기부를 고려하는 기부자들을 위해 전문적인 법률 자문을 제공하여
              <br />
              기부 과정이 원활하고 신뢰롭게 진행될 수 있도록 돕습니다.
            </p>
          </div>
        </li>
      </ul>
    </section>
  );
};

export default HeritageReward;
