import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OneToManyContent from '../../Main/OneToMany/OneToManyContent';
import OneToManyPaginationComponent from './OneToManyPaginationComponent';
import './OneToManyPageList.css';

const OneToManyPageList = ({ isAdmin }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 4;

    const [stories, setStories] = useState([
        {
            id: 1,
            title: "쿠팡 봉지에 싸여 버려진",
            catName: "모세",
            description: "비오는 날 쿠팡봉지에 버려진 채 발견된 아이 모세입니다.",
            imageUrl: "https://picsum.photos/350"
        },
        {
            id: 2,
            title: "사람의 도움이 절실한",
            catName: "명길이",
            description: "형제들과 함께 구조되었던 아이인 명길이 입니다.",
            imageUrl: "https://picsum.photos/350"
        },
        {
            id: 3,
            title: "사람의 도움이 절실한",
            catName: "명길이",
            description: "형제들과 함께 구조되었던 아이인 명길이 입니다.",
            imageUrl: "https://picsum.photos/350"
        },
        {
            id: 4,
            title: "사람의 도움이 절실한",
            catName: "명길이",
            description: "형제들과 함께 구조되었던 아이인 명길이 입니다.",
            imageUrl: "https://picsum.photos/350"
        },
        {
            id: 5,
            title: "사람의 도움이 절실한",
            catName: "명길이",
            description: "형제들과 함께 구조되었던 아이인 명길이 입니다.",
            imageUrl: "https://picsum.photos/350"
        },
        {
            id: 6,
            title: "사람의 도움이 절실한",
            catName: "명길이",
            description: "형제들과 함께 구조되었던 아이인 명길이 입니다.",
            imageUrl: "https://picsum.photos/350"
        },

    ]);

    const totalPages = Math.ceil(stories.length / itemsPerPage);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
            navigate(`?page=${page}`);
        }
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const pageFromUrl = parseInt(queryParams.get('page')) || 1;
        setCurrentPage(pageFromUrl);
    }, [location.search]);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = stories.slice(indexOfFirstItem, indexOfLastItem);

    const handleDescriptionChange = (id, newDescription) => {
        setStories((prevStories) =>
            prevStories.map((story) =>
                story.id === id ? { ...story, description: newDescription } : story
            )
        );
    };

    //description 수정사항 서버반영
    const saveDescriptionToServer = async (id, description) => {
        /*try {
            const response = await fetch(`/api/stories/${id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ description }),
            });

            if (!response.ok) throw new Error('Failed to save description');
            alert('Description saved successfully!');
        } catch (error) {
            console.error(error);
            alert('Failed to save description.');
        }*/
    };

    return (
        <div>
            <div style={{ margin: "10% auto" }}>
                <div className='list-header'>
                    <div className='list-header-text'>
                        <span className='list-header-text-top'>치료와 교육을 받을 수 있도록 도와주세요</span>
                        <span className='list-header-text-bottom'>1:N 후원하기</span>
                    </div>
                    <hr className='list-header-divider' />
                </div>
                {currentItems.map((story, index) => (
                    <div className={`one-to-many-content-container`} key={story.id}>
                        <OneToManyContent
                            id={story.id}
                            title={story.title}
                            catName={story.catName}
                            description={
                                isAdmin ? (
                                    <>
                                        <textarea
                                            className='one-to-many-content-update-textarea'
                                            value={story.description}
                                            onChange={(e) =>
                                                handleDescriptionChange(story.id, e.target.value)
                                            }
                                        />
                                        <button
                                            className="save-button"
                                            onClick={() =>
                                                saveDescriptionToServer(story.id, story.description)
                                            }
                                        >
                                            Save
                                        </button>
                                    </>
                                ) : (
                                    story.description
                                )
                            }
                            imageUrl={story.imageUrl}
                        />
                        {index < currentItems.length - 1 && <hr className="one-to-many-content-divider" />}
                    </div>
                ))}
            </div>
            <div className='one-to-many-pagination-container'>
                <OneToManyPaginationComponent
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
            </div>
        </div>
    );
};

export default OneToManyPageList;
