import "./OneToOneBanner.css";

const OneToOneBanner = () => {
  return (
    <>
      <section className="one-to-one-banner">
        <div className="one-to-one-banner__content">
          <h1 className="one-to-one-banner__title">1:1 후원이란?</h1>
          <h2 className="one-to-one-banner__title one-to-one-banner__title--sub">
            장애 또는 중증질환을 가진 아이들 그리고 노령의 나이로
            <br /> 장기간 입양이 어려운 아이들에게 <br />
            평범하고 일상이 이어갈 수 있도록
            <br /> 따뜻한 하루 하루를 선물해주는 후원 방식입니다.
          </h2>
        </div>
      </section>
    </>
  );
};
export default OneToOneBanner;
