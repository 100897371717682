// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-adoption-table-container {
    margin-top: 15vh;
}

.admin-adoption-table-row {
    display: flex; /* Flexbox 사용 */
    align-items: center; /* 수직 중앙 정렬 */
    border-bottom: 1px solid #ccc;
    padding: 2vh 0;
  }
  
  .admin-adoption-table-cell {
    flex: 1 1;
    text-align: center;
  }
  
  .admin-adoption-table-cell:empty {
    visibility: hidden;
  }

  .admin-adoption-table {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 5%;
  }

  .admin-adoption-image {
    width: 20%;
    height: 20%;
    margin : 1% 0 1% 0;
  }`, "",{"version":3,"sources":["webpack://./src/components/Admin/Post/AdminAdoption.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa,EAAE,eAAe;IAC9B,mBAAmB,EAAE,aAAa;IAClC,6BAA6B;IAC7B,cAAc;EAChB;;EAEA;IACE,SAAO;IACP,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;IACjB,iBAAiB;IACjB,iBAAiB;EACnB;;EAEA;IACE,UAAU;IACV,WAAW;IACX,kBAAkB;EACpB","sourcesContent":[".admin-adoption-table-container {\n    margin-top: 15vh;\n}\n\n.admin-adoption-table-row {\n    display: flex; /* Flexbox 사용 */\n    align-items: center; /* 수직 중앙 정렬 */\n    border-bottom: 1px solid #ccc;\n    padding: 2vh 0;\n  }\n  \n  .admin-adoption-table-cell {\n    flex: 1;\n    text-align: center;\n  }\n  \n  .admin-adoption-table-cell:empty {\n    visibility: hidden;\n  }\n\n  .admin-adoption-table {\n    font-size: 1.5rem;\n    font-weight: bold;\n    margin-bottom: 5%;\n  }\n\n  .admin-adoption-image {\n    width: 20%;\n    height: 20%;\n    margin : 1% 0 1% 0;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
