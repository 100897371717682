import DonationBanner from "./DonationBanner";

const BusinessCard = ({ title, subdescription, description }) => {
  return (
    <>
      <li className="business-card">
        <h3 className="business-card__title">{title}</h3>
        <div className="business-card__contant">
          <p className="business-card__sub-description">{subdescription}</p>
          <p className="business-card__description">{description}</p>
        </div>
      </li>
    </>
  );
};

const AboutBusiness = () => {
  return (
    <>
      <section className="business-title-section">
        <h1 className="business-title">
          우리는 이를 위해 다음의 사업을
          <br /> 중점적으로 추진하고자 합니다
        </h1>
      </section>
      <section className="business-card-section">
        <ul className="business-card-section__list">
          <BusinessCard
            title={"제 1 사업"}
            description={
              <>
                유기동물의 <br />
                구조, 보호 <br />
                입양사업
              </>
            }
          />
          <BusinessCard
            title={"제 2 사업"}
            description={
              <>
                길고양이 <br />
                돌봄 사업
              </>
            }
          />
          <BusinessCard
            title={"제 3 사업"}
            subdescription={"동물보호 · 복지 관련"}
            description={
              <>
                정책개선 제안, <br />
                홍보 및 교육사업
              </>
            }
          />

          <div></div>
          <div></div>

          <BusinessCard
            title={"제 4 사업"}
            subdescription={"법인의 목적 달성에 필요한"}
            description={
              <>
                경비마련을 위한 <br />
                바자회 개최 사업
              </>
            }
          />
          <BusinessCard
            title={"제 5 사업"}
            description={
              <>
                동물보호 단체 및 <br />
                지자체들과의 <br />
                협력 및 교류
              </>
            }
          />
          <BusinessCard
            title={"제 6 사업"}
            description={
              <>
                기타 본회의 <br />
                목적 달성에 필요한 <br />
                사업
              </>
            }
          />
        </ul>
        <div className="back-bar bar-up"></div>
        <div className="back-bar bar-down"></div>
      </section>
      <DonationBanner />
    </>
  );
};

export default AboutBusiness;
