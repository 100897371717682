import React, { useState } from 'react'
import './MyActivityComponent.css'
import MyPageTable from './MyPageTable'
import usePagination from './UsePagination';
import MyActivitySlider from './MyActivitySlider';

const MyActivityComponent = () => {
    const headers = ['후원 동물', '후원 시작일', '후원 종료일', '결제금액'];

    //후원내역 데이터
    const allData = [
        ['메롱이', '2024-12-23', '2025-12-22', '220,000원'],
        ['순자', '2023-12-23', '2024-12-22', '110,000원'],
        ['순자', '2023-12-23', '2024-12-22', '110,000원'],
        ['메롱이', '2024-12-23', '2025-12-22', '220,000원'],
        ['메롱이', '2024-12-23', '2025-12-22', '220,000원'],
        ['메롱이', '2024-12-23', '2025-12-22', '220,000원'],
        ['메롱이', '2024-12-23', '2025-12-22', '220,000원'],
        ['메롱이', '2024-12-23', '2025-12-22', '220,000원'],
        ['메롱이', '2024-12-23', '2025-12-22', '220,000원'],
    ];

    const { currentData, currentPage, totalPages, handlePageChange } = usePagination(allData, 8);

    //후원내역클릭시
    const handleCellClick = (rowIndex, cellIndex, cellValue) => {
        console.log(`Row: ${rowIndex}, Cell: ${cellIndex}, Value: ${cellValue}`);
    };

    //내 활동 데이터
    const donateList = [
        {
            image: 'https://picsum.photos/350',
            catName: '메롱이',
            donateCategory: '1 : N',
            donation: '22'
        },
        {
            image: 'https://picsum.photos/350',
            catName: '메롱이',
            donateCategory: '1 : N',
            donation: '22'
        },
        {
            image: 'https://picsum.photos/350',
            catName: '헤롱이',
            donateCategory: '1 : 1',
            donation: '30'
        },
        {
            image: 'https://picsum.photos/350',
            catName: '헤롱이',
            donateCategory: '1 : 1',
            donation: '30'
        },
        {
            image: 'https://picsum.photos/350',
            catName: '헤롱이',
            donateCategory: '1 : 1',
            donation: '30'
        },
        {
            image: 'https://via.placeholder.com/100x100/ffffff/000000?text=Image+6',
            catName: '헤롱이',
            donateCategory: '1 : 1',
            donation: '30'
        },
    ];

    const isEmptyActivity = donateList.length === 0;

    const [currentIndex, setCurrentIndex] = useState(0);

    // 오른쪽 버튼 클릭 시 처리
    const moveRight = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % donateList.length);
    };

    // 왼쪽 버튼 클릭 시 처리
    const moveLeft = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? donateList.length - 1 : prevIndex - 1
        );
    };

    const currentImages = [
        donateList[(currentIndex) % donateList.length],
        donateList[(currentIndex + 1) % donateList.length],
        donateList[(currentIndex + 2) % donateList.length],
    ];

    return (
        <div>
            <div className='myactivity-header-container'>
                <span>내 후원 내역</span>
                <div className='myactivity-donate-list-container'>
                    <MyPageTable
                        headers={headers}
                        data={currentData}
                        itemsPerPage={8}
                        currentPage={currentPage}
                        totalPage={totalPages}
                        onPageChange={handlePageChange}
                        onCellClick={handleCellClick}
                    />
                </div>
            </div>
            <div>
                <div className='myactivity-header-container'>
                    <span>내 활동</span>
                    <hr />
                    {isEmptyActivity ? ( // 데이터가 없을 때 표시할 문구
                        <>
                            <div className="empty-message">
                                활동 내역이 없습니다.
                            </div>
                        </>
                    ) : (
                        <div className='myactiivity-content-container'>
                            <p>후원 아이들</p>
                            <div className='myactivity-slider-container'>
                                <button onClick={moveLeft} className='myactivity-slider-left-btn'></button>
                                {currentImages.map((donateList, index) => (
                                    <div key={index}>
                                        <MyActivitySlider
                                            sliderImage={donateList.image}
                                            catName={donateList.catName}
                                            donateCategory={donateList.donateCategory}
                                            donation={donateList.donation}
                                        />
                                    </div>
                                ))}
                                <button onClick={moveRight} className='myactivity-slider-right-btn'></button>
                            </div>
                        </div>)}
                    <hr />
                </div>
            </div>
        </div>
    )
}

export default MyActivityComponent
