import React from 'react';

const FanClubDonateTermsContent = () => {
    return (
        <div style={{
            width: 'clamp(200px, 30vw, 400px)',
            height: '500px',
            overflow: 'auto',
            padding: '10px',
            backgroundColor: '#f9f9f9',
            borderRadius: '8px',
            border: '1px solid #ddd'
        }}>
            <h2 style={{ margin: '20px auto', fontSize: 'clamp(12px, 5vw, 22px)', textAlign: 'center', fontWeight: 700 }}>이용약관</h2>
            <p>제1조 (목적)<br />
                본 약관은 팬클럽 기부 플랫폼(이하 '서비스')의 이용과 관련하여 회사와 이용자 간의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
            </p>
            <br />
            <p>제2조 (정의)<br />
                1. "이용자"란 본 약관에 따라 서비스를 이용하는 회원 및 비회원을 말합니다.<br />
                2. "회원"이란 서비스를 이용하기 위해 회사와 이용 계약을 체결한 자를 의미합니다.
            </p>
            <br />
            <p>제3조 (이용계약의 체결)<br />
                이용계약은 이용자가 본 약관의 내용에 동의한 후 이용 신청을 하고 회사가 이를 승낙함으로써 체결됩니다.
            </p>
            <br />
            <p>제4조 (서비스의 제공 및 변경)<br />
                회사는 다음과 같은 서비스를 제공합니다.<br />
                - 팬클럽 기부 서비스<br />
                - 기부 인증서 발급 서비스<br />
                회사는 서비스의 내용을 변경할 수 있으며, 변경 시에는 변경 내용과 시행일을 사전에 공지합니다.
            </p>
            <br />
            <p>제5조 (서비스 이용 제한)<br />
                이용자가 본 약관을 위반하거나 법령을 위반하는 경우 회사는 서비스 이용을 제한할 수 있습니다.
            </p>
        </div>
    );
};

export default FanClubDonateTermsContent;
