import "./AnnounceBanner.css";

const AnnounceBanner = () => {
  return (
    <div className="announce-banner-container">
      <div className="announce-banner-text">
        <p className="announce-banner-main-text">공지사항</p>
        <p className="announce-banner-explain-text">
          포캣멍센터의 공지사항을 전해드립니다
        </p>
      </div>
    </div>
  );
};
export default AnnounceBanner;
