import React, { useState } from 'react';
import FanClubCard from './FanClubCard';
import './FanClubGrid.css';
import OneToManyPaginationComponent from '../Support/OneToMany/OneToManyPaginationComponent';
import usePagination from '../MyPage/UsePagination';

const FanClubGrid = () => {
    const fanClubData = [
        { image: 'https://picsum.photos/350', starName: 'Star 1', donationDate: '2025-01-01', message: 'Message 1' },
        { image: 'https://picsum.photos/350', starName: 'Star 2', donationDate: '2025-01-02', message: 'Message 2' },
        { image: 'https://picsum.photos/350', starName: 'Star 3', donationDate: '2025-01-03', message: 'Message 3' },
        { image: 'https://picsum.photos/350', starName: 'Star 4', donationDate: '2025-01-04', message: 'Message 4' },
        { image: 'https://picsum.photos/350', starName: 'Star 5', donationDate: '2025-01-05', message: 'Message 5' },
        { image: 'https://picsum.photos/350', starName: 'Star 6', donationDate: '2025-01-06', message: 'Message 6' },
        { image: 'https://picsum.photos/350', starName: 'Star 7', donationDate: '2025-01-07', message: 'Message 7' },
        { image: 'https://picsum.photos/350', starName: 'Star 8', donationDate: '2025-01-08', message: 'Message 8' },
        { image: 'https://picsum.photos/350', starName: 'Star 9', donationDate: '2025-01-09', message: 'Message 9' },
        { image: 'https://picsum.photos/350', starName: 'Star 10', donationDate: '2025-01-10', message: 'Message 10' },
        { image: 'https://picsum.photos/350', starName: 'Star 11', donationDate: '2025-01-11', message: 'Message 11' },
    ];

    const [searchQuery, setSearchQuery] = useState('');
    const filteredData = fanClubData.filter(data =>
        data.starName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        data.message.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const { currentData, currentPage, totalPages, handlePageChange } = usePagination(filteredData, 6);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    return (
        <div className="fan-club-grid__container">
            <hr className="fan-club-grid__divide" />
            <div className="fan-club-grid__search-container">
                <div className="fan-club-grid__search">
                    <input
                        type="search"
                        placeholder="스타 또는 팬덤이름을 검색해주세요"
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                    <button>검색</button>
                </div>
            </div>
            <div className="fan-club-grid">
                {currentData.map((data, index) => (
                    <FanClubCard
                        key={index}
                        image={data.image}
                        starName={data.starName}
                        donationDate={data.donationDate}
                        message={data.message}
                    />
                ))}
            </div>
            <OneToManyPaginationComponent
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
            />
            <hr className="fan-club-grid__divide" />
        </div>
    );
};

export default FanClubGrid;
