const FanClubMiddleBanner = ({ onOpenModal }) => {

    return (
        <div className="fan-club-middle-banner">
            <div className="fan-club-middle-banner-content">
                <h1 className="fan-club-banner-title">스타 팬클럽 후원이란?</h1>
                <h2 className="fan-club-banner-subtitle">스타의 특별한 날 동물보호에 팬클럽분들과 함께 동참하는 기념하는 후원입니다.</h2>
                <button className="fan-club-middle-button" onClick={onOpenModal}>후원 신청하기 {">"}</button>
            </div>
        </div>
    );
}

export default FanClubMiddleBanner;