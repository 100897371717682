import './AdoptionField.css'

const AdoptionCarerHousingTypeField = ({ formData, setFormData }) => {
    return (
        <div className='adoption-housing-type-form-row'>
            <label>주거 형태</label>
            <button
                type="button"
                className={`adoption-housing-type-toggle-button ${formData.carerHousingType === '다가구' ? 'active' : ''}`}
                onClick={() => setFormData((prev) => ({ ...prev, carerHousingType: '다가구' }))}>
                다가구
            </button>
            <button
                type="button"
                className={`adoption-housing-type-toggle-button ${formData.carerHousingType === '아파트' ? 'active' : ''}`}
                onClick={() => setFormData((prev) => ({ ...prev, carerHousingType: '아파트' }))}>
                아파트
            </button>
            <button
                type="button"
                className={`adoption-housing-type-toggle-button ${formData.carerHousingType === '단독주택' ? 'active' : ''}`}
                onClick={() => setFormData((prev) => ({ ...prev, carerHousingType: '단독주택' }))}>
                단독 주택
            </button>
        </div>
    )
}

export default AdoptionCarerHousingTypeField