// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mypage-deleteid-content-container {
    height: 100%;
    overflow: auto;
}

.mypage-deleteid-content-header {
    display: flex;
    height: 250px;
    flex-direction: column;
    justify-content: flex-end;
    text-align: center;
    background-color: #005eb8;
    padding-bottom: 25px;

    color: #fff;
    font-size: 30px;
    font-weight: bold;
}

.mypage-deleteid-content-text {
    display: flex;
    height: 450px;
    flex-direction: column;
    margin: 50px auto;
    text-align: center;
    font-size: 20px;

    gap: 5px;
}

.mypage-deleteid-content-button-box {
    margin: 60px;
}

.mypage-deleteid-content-button {
    background-color: #005eb8;
    color: #fff;
    height: 30px;
    width: 20%;
}

@media (max-width: 1000px) {

    .mypage-deleteid-content-header {
        height: 150px;
    }

    .mypage-deleteid-content-text {
        display: flex;
        height: 300px;
        flex-direction: column;
        margin: 50px auto;
        text-align: center;
        font-size: 15px;

        gap: 5px;
    }

    .mypage-deleteid-content-button {
        width: 30%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/MyPage/MyPageDeleteIdContent.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,yBAAyB;IACzB,kBAAkB;IAClB,yBAAyB;IACzB,oBAAoB;;IAEpB,WAAW;IACX,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;;IAEf,QAAQ;AACZ;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,UAAU;AACd;;AAEA;;IAEI;QACI,aAAa;IACjB;;IAEA;QACI,aAAa;QACb,aAAa;QACb,sBAAsB;QACtB,iBAAiB;QACjB,kBAAkB;QAClB,eAAe;;QAEf,QAAQ;IACZ;;IAEA;QACI,UAAU;IACd;AACJ","sourcesContent":[".mypage-deleteid-content-container {\n    height: 100%;\n    overflow: auto;\n}\n\n.mypage-deleteid-content-header {\n    display: flex;\n    height: 250px;\n    flex-direction: column;\n    justify-content: flex-end;\n    text-align: center;\n    background-color: #005eb8;\n    padding-bottom: 25px;\n\n    color: #fff;\n    font-size: 30px;\n    font-weight: bold;\n}\n\n.mypage-deleteid-content-text {\n    display: flex;\n    height: 450px;\n    flex-direction: column;\n    margin: 50px auto;\n    text-align: center;\n    font-size: 20px;\n\n    gap: 5px;\n}\n\n.mypage-deleteid-content-button-box {\n    margin: 60px;\n}\n\n.mypage-deleteid-content-button {\n    background-color: #005eb8;\n    color: #fff;\n    height: 30px;\n    width: 20%;\n}\n\n@media (max-width: 1000px) {\n\n    .mypage-deleteid-content-header {\n        height: 150px;\n    }\n\n    .mypage-deleteid-content-text {\n        display: flex;\n        height: 300px;\n        flex-direction: column;\n        margin: 50px auto;\n        text-align: center;\n        font-size: 15px;\n\n        gap: 5px;\n    }\n\n    .mypage-deleteid-content-button {\n        width: 30%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
