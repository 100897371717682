import React from 'react'
import "../Main/Main.css";
import MyPageBanner from '../../components/MyPage/MyPageBanner';
import MyPageDeleteIdContent from '../../components/MyPage/MyPageDeleteIdContent';

const MyPageDeleteIdPage = () => {
  return (
    <>
      <main className='md-main'>
        <MyPageBanner />
        <MyPageDeleteIdContent />
      </main>
    </>
  )
}

export default MyPageDeleteIdPage
