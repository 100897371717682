import React from "react";
import "./DonationUseList.css";
import DOMPurify from "dompurify";

const DonationUseListItem = ({ imageUrl, title, author, date, content}) => {
  return (
    <div className="donation-uselist-item-container">
      {imageUrl && <img src={imageUrl} alt={title} />}
      <div className="donation-uselist-item-text">
        <div className="donation-uselist-item-text-title">{title}</div>
        <div className="donation-uselist-item-text-author">
          {author} | <span>{date}</span>
        </div>
        <div className="donation-uselist-item-text-content" dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(content.substring(0, 100)),
          }}
        />
      </div>
    </div>
  );
};
export default DonationUseListItem;
