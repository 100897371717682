// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

.pagination button {
    padding: 5px 10px;
    border: 1px solid #ccc;
    background-color: white;
    cursor: pointer;
    color: #000;
}

.pagination button.active {
    border: 1px solid #007bff;
    color: #007bff;
}

.pagination button span.arrow {
    color: #ccc;
    /* 화살표는 회색 */
}`, "",{"version":3,"sources":["webpack://./src/components/Support/OneToMany/OneToManyPaginationComponent.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,sBAAsB;IACtB,uBAAuB;IACvB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".pagination {\n    display: flex;\n    justify-content: center;\n    gap: 10px;\n    margin-top: 20px;\n}\n\n.pagination button {\n    padding: 5px 10px;\n    border: 1px solid #ccc;\n    background-color: white;\n    cursor: pointer;\n    color: #000;\n}\n\n.pagination button.active {\n    border: 1px solid #007bff;\n    color: #007bff;\n}\n\n.pagination button span.arrow {\n    color: #ccc;\n    /* 화살표는 회색 */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
