import "./SupportPageQA.css";
import QA from "./assets/Q&A.png";

const SupportPageQA = () => {
  return (
    <>
      <div className="support-QA-main">
        <div className="support-QA-line" />
        <div className="support-QA-text">
          후원에 대해 궁금한 점이 있나요?
          <br />
          <span>Q&A</span>
        </div>
        <div className="little-circle">
          <div className="little-in-circle"></div>
        </div>
      </div>
      <div className="img-div">
        <img src={QA} alt="Q&A" className="support-QA-img" />
        <div className="large-circle">
          <div className="large-in-circle" />
        </div>
      </div>
    </>
  );
};
export default SupportPageQA;
