import React, { useState, useEffect, useCallback} from 'react';
import { Link } from 'react-router-dom';
import AdminAniModal from './AdminAniModal'; // 모달 컴포넌트
import axios from 'axios';
import "./AdminAniList.css"

const AdminAniList = () => {
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [keyword, setKeyword] = useState(''); // 키워드 상태 추가

  const fetchItems = useCallback(async (searchKeyword) => {
    try {
      // keyword가 비어 있으면 기본 API 호출
      const url = `https://forcatmung.kr/api/animal/search/list/admin?keyword=${searchKeyword}`;

      const response = await axios.get(url);
      setItems(response.data);
    } catch (error) {
      console.error('API 호출 오류:', error);

    } 
  }, []);

  useEffect(() => {
    fetchItems('');
  }, [fetchItems]);

  const handleSearch = () => {
    fetchItems(keyword); // 현재 keyword로 API 호출
  };
 
  const handleItemClick = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const handleDelete = async (id) => {
    await axios.delete(`/api/items/${id}`); // 삭제 API 호출
    setItems(items.filter(item => item.id !== id)); // 리스트 업데이트
  };

  return (
    <>
    <div className='admin-button-container'>
     <Link to="/mainmain/register">
        <button className='admin-regist-button'>등록하러 가기</button>
      </Link>
      <input className='admin-input-box'
        type="text"
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)} // 입력값 변경 시 keyword 업데이트
        placeholder="키워드를 입력하세요"
      />
      <button className="admin-input-button" onClick={handleSearch}>검색</button> {/* 검색 버튼 */}
    </div>
    <div className='admin-list-container'>
        {items.map(item => (
          <div key={item.animalId} onClick={() => handleItemClick(item)} className='admin-list-table-row'>
            <span className="admin-list-table-cell"><img src={item.animalImage} alt="Animal" className="admin-list-image" /></span>
            <span className="admin-list-table-cell">{item.animalName}</span>
          </div>
        ))}

      {isModalOpen && (
        <AdminAniModal 
          item={selectedItem} 
          onClose={() => setIsModalOpen(false)} 
          onDelete={handleDelete} 
        />
      )}
    </div>
    </>
  );
};

export default AdminAniList;