import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './MyPageNaviBar.css';

function MyPageNaviBar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleMenuItemClick = () => {
        setIsMenuOpen(false);
    };

    return (
        <div className="mypage-navi-bar">
            <button className="hamburger-button" onClick={toggleMenu}>
                ☰
            </button>
            <div className={`mypage-navi-content ${isMenuOpen ? 'open' : ''}`}>
                <strong className="mypage-navi-title">내 후원내역</strong>
                <ul className="mypage-navi-list">
                    <li className="mypage-navi-item">
                        <NavLink
                            to="/mypage/onetoone"
                            className={({ isActive }) => (isActive ? "mypage-navi-link mypage-navi-link--active" : "mypage-navi-link")}
                            onClick={handleMenuItemClick}
                        >
                            1 : 1 후원
                        </NavLink>
                    </li>
                    <li className="mypage-navi-item">
                        <NavLink
                            to="/mypage/onetomany"
                            className={({ isActive }) => (isActive ? "mypage-navi-link mypage-navi-link--active" : "mypage-navi-link")}
                            onClick={handleMenuItemClick}
                        >
                            1 : N 후원
                        </NavLink>
                    </li>
                    <li className="mypage-navi-item">
                        <NavLink
                            to="/mypage/onetimedonation"
                            className={({ isActive }) => (isActive ? "mypage-navi-link mypage-navi-link--active" : "mypage-navi-link")}
                            onClick={handleMenuItemClick}
                        >
                            일시 후원
                        </NavLink>
                    </li>
                    <li className="mypage-navi-item">
                        <NavLink
                            to="/mypage/subdonation"
                            className={({ isActive }) => (isActive ? "mypage-navi-link mypage-navi-link--active" : "mypage-navi-link")}
                            onClick={handleMenuItemClick}
                        >
                            정기 후원
                        </NavLink>
                    </li>
                </ul>
                <strong className="mypage-navi-title">설정</strong>
                <ul className="mypage-navi-list">
                    <li className="mypage-navi-item">
                        <NavLink
                            to="/mypage/editaccountinfo"
                            className={({ isActive }) => (isActive ? "mypage-navi-link mypage-navi-link--active" : "mypage-navi-link")}
                            onClick={handleMenuItemClick}
                        >
                            회원정보 수정
                        </NavLink>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default MyPageNaviBar;
