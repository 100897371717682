// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-register-container {
    margin-top: 15%;
}

.required-fields {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .required-field {
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 8px 12px;
  }
  
  .required-field label {
    font-weight: bold;
    margin-right: 8px;
    width: 150px; 
  }
  
  .required-field input,
  .required-field select { 
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 5px;
    font-size: 14px;
    flex-grow: 1;
    margin-right: 5%;
  }

  .required-button {
    width: 15%;
    border: none;
    background-color: #5d9cec;
    padding: 10px 20px;
    font-size: 1.2rem;
    color: white;
  }
  
  @media (min-width:1025px) {
    .admin-register-container {
        margin-top: 15vh;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/Admin/Post/AdminAniReg.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,SAAS;IACT,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;IACE,iBAAiB;IACjB,iBAAiB;IACjB,YAAY;EACd;;EAEA;;IAEE,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,UAAU;IACV,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,iBAAiB;IACjB,YAAY;EACd;;EAEA;IACE;QACI,gBAAgB;IACpB;EACF","sourcesContent":[".admin-register-container {\n    margin-top: 15%;\n}\n\n.required-fields {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 10px;\n    margin-bottom: 20px;\n  }\n  \n  .required-field {\n    display: flex;\n    align-items: center;\n    border-radius: 4px;\n    padding: 8px 12px;\n  }\n  \n  .required-field label {\n    font-weight: bold;\n    margin-right: 8px;\n    width: 150px; \n  }\n  \n  .required-field input,\n  .required-field select { \n    border: 1px solid #ccc;\n    border-radius: 3px;\n    padding: 5px;\n    font-size: 14px;\n    flex-grow: 1;\n    margin-right: 5%;\n  }\n\n  .required-button {\n    width: 15%;\n    border: none;\n    background-color: #5d9cec;\n    padding: 10px 20px;\n    font-size: 1.2rem;\n    color: white;\n  }\n  \n  @media (min-width:1025px) {\n    .admin-register-container {\n        margin-top: 15vh;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
