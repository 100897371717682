import React, { useState } from 'react';
import axios from 'axios';
import './AdminAniReg.css'

const AdminAnimalRegister = () => {
    const [formData, setFormData] = useState({
        name: '', age: '', gender: '', majorCategory: 'dog', subCategory: '', resDate: '', resLocation: '', weight: '0.0', neutering: true,
        etc1: '', etc2: '', etc3: '', etc4: '',
        image: null
      });

      const fieldLabels = {
        name: '이름',
        age: '나이',
        gender: '성별',
        majorCategory: '메인카테고리',
        subCategory: '서브카테고리',
        resDate: '구조날짜',
        resLocation: '구조장소',
        personality: '개인특징',
        needs: '건강특징',
        weight: '체중',
        neutering: '중성화여부'
      };

      const fieldLabelss = {
        etc1: '기타사항 1',
        etc2: '기타사항 2',
        etc3: '기타사항 3',
        etc4: '기타사항 4',
      };

      const handleInputChangeBoolean = (e) => {
        const { name, value } = e.target;
      
        // 'neutering' 필드의 경우 Boolean으로 변환
        if (name === 'neutering') {
          setFormData({ ...formData, [name]: value === 'true' }); // 'true'는 true로, 'false'는 false로 변환
        } else {
          setFormData({ ...formData, [name]: value });
        }
      };
    
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ 
          ...formData, 
          [name]: name === 'weight' ? parseFloat(value) || '' : value 
      });
  };
    
      const handleImageChange = (e) => {
        setFormData({ ...formData, image: e.target.files[0] });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        const apiformData = new FormData();

          apiformData.append("imageData", formData.image);


        const jsonData = {
          name: formData.name,
          animalType: formData.majorCategory,
          gender: formData.gender,
          birthDate: formData.age,
          weight: parseFloat(formData.weight) || 0.0,
          breeds: formData.subCategory,
          neutered: formData.neutering,
          rescueDate : formData.resDate,
          rescueLocation : formData.resLocation,
          adoptionStatus : "입양준비중",
          healthFeatures : formData.needs,
          animalDetailCharc1: formData.etc1,
          animalDetailCharc2: formData.etc2,
          animalDetailCharc3: formData.etc3,
          animalDetailCharc4: formData.etc4,
          animalDonationType : 0,
          personality: formData.personality,
        };
    
        apiformData.append('animal', new Blob([JSON.stringify(jsonData)], { type: "application/json" }));

        try {
          const response = await axios.post("https://forcatmung.kr/api/animal/create", apiformData, {
            headers: {
              "Content-Type": "multipart/form-data",
            }
          });
      
          console.log('등록 성공:', response.data);
        } catch (error) {
          console.error('등록 실패:', error);
        }

    };

      return (
        <div className='admin-register-container'>
        <form onSubmit={handleSubmit}>
            <h2 style={{marginBottom:"3%", fontSize:"1.5rem"}}>필수 영역</h2>
          {['name', 'age', 'gender', 'majorCategory', 'subCategory', 'resDate', 'resLocation', 'personality', 'needs', 'weight', 'neutering'].map(field => (
            <div key={field} className="required-field">
              <label htmlFor={field}>{fieldLabels[field]}:</label>
              {field === 'majorCategory' ? (
      <select
        id={field}
        name={field}
        value={formData[field] || ''}
        onChange={handleInputChange}
        required
      >
        <option value="dog">강아지</option>
        <option value="cat">고양이</option>
        <option value="etc">특수동물</option>
      </select>
    ) : field === 'neutering' ? (
      <select
        id={field}
        name={field}
        value={formData[field] || ''}
        onChange={handleInputChangeBoolean}
        required
      >
        <option value="true">O</option>
        <option value="false">X</option>
      </select>
    ) : field === 'weight' ? ( // weight 필드 처리
      <input
        type="number"
        id={field}
        name={field}
        value={formData[field]}
        onChange={handleInputChange}
        required
      />
    ) : field === 'age' || field === 'resDate' ? ( // age와 resDate에 대한 처리
      <input
        type="text" // type을 text로 설정하여 하이픈 입력을 허용
        id={field}
        name={field}
        value={formData[field]}
        onChange={handleInputChange}

        placeholder='"2025-02-19"처럼 "연도 - 월 - 일 " 형태로 입력해주세요. `-` 필수입니다'
        required
      />
    ) : (
      <input
        type="text"
        id={field}
        name={field}
        value={formData[field] || ''}
        onChange={handleInputChange}
        required
      />
    )}
  </div>
))}

            <div className='required-field'>
            <label htmlFor="image" >Image:</label>
            <input
              type="file"
              id="image"
              name="image"
              onChange={handleImageChange}
              accept="image/*"
              required
            />
          </div>

        <h2 style={{marginBottom:"3%", marginTop:"3%", fontSize:"1.5rem"}}>선택 영역</h2>
          {['etc1', 'etc2', 'etc3', 'etc4'].map(field => (
            <div key={field} className="required-field">
              <label htmlFor={field}>{fieldLabelss[field]}:</label>
              <input
                type="text"
                id={field}
                name={field}
                value={formData[field] || ''}
                onChange={handleInputChange}
              />
            </div>
          ))}

          <button type="submit" className="required-button" style={{marginTop:"3%", marginBottom:"3%"}}>등록하기</button>
        </form>
        </div>
      );
    };


export default AdminAnimalRegister;