import React, { useState } from "react";
import "./OneToOnePageList.css";
import OneToManyPaginationComponent from "../OneToMany/OneToManyPaginationComponent";
import { Link } from "react-router-dom";

const OneToOnePageList = ({ listData }) => {
  const itemsPerPage = 6;
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = listData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="one-to-one-list-container">
      <ul>
        {currentItems.map((animal) => (
          <li key={animal.animalId} className="animal-item">
            <div className="animal-container">
              <img
                src={animal.image}
                alt={animal.animalName}
                className="one-to-one-animal-image"
              />
              <div className="animal-info-container">
                <div className="one-to-one-list-animal-info">
                  <span className="one-to-one-list-animal-name">저는 </span>
                  <strong className="one-to-one-list-animal-name">
                    {animal.animalName}
                  </strong>
                  <span className="one-to-one-list-animal-name"> 입니다.</span>
                </div>

                <div className="one-to-one-animals-info">
                  {animal.type} / {animal.age} / {animal.gender}
                </div>
                <div className="one-to-one-animals-info">
                  <div className="one-to-one-animals-info">
                    <div className="one-to-one-animals-info-supporter-name">
                      {animal.animalName} 후원자
                    </div>
                    <strong className="one-to-one-animals-info-donator">
                      {animal.donation} (님)
                    </strong>
                  </div>
                </div>
              </div>
              <div className="one-to-one-list-button-container">
                <button className="one-to-one-list-detail-button">
                  <Link to={`/onetoone/list/${animal.animalId}`}>
                    자세히 보기
                  </Link>
                </button>
                {/* 나중에 1:1 후원하기로 수정해야함 */}
                <button className="one-to-one-list-support-button">
                  <Link to="/AdoptionPage">입양하기</Link>
                </button>
              </div>
            </div>
          </li>
        ))}
        <OneToManyPaginationComponent
          currentPage={currentPage}
          totalPages={Math.ceil(listData.length / itemsPerPage)}
          onPageChange={handlePageChange}
        />
      </ul>
    </div>
  );
};

export default OneToOnePageList;
