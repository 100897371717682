import { Link } from "react-router-dom";
import "./TemporaryInfo.css";

const TemporaryInfo = () => {
  return (
    <div className="temporary-main">
      <div className="temporary-header">
        <p>
          한 번의 따뜻한 손 길을 내밀어주세요
          <br />
          <span>일시후원</span>
        </p>
      </div>
      <div className="temporary-support-info">
        <div className="temporary-support-info-title">
          <p>후원정보</p>
        </div>
        <div className="temporary-support-info-amount">
          <div className="little-title">후원금액</div>
          <div className="temporary-support-info-amount-click">
            <div>
              <button className="temporary-support-info-amount-click-button temporary-support-info-amount-click-item">
                10,000원
              </button>
              <button className="temporary-support-info-amount-click-button temporary-support-info-amount-click-item">
                20,000원
              </button>
              <button className="temporary-support-info-amount-click-button temporary-support-info-amount-click-item">
                30,000원
              </button>
              <button className="temporary-support-info-amount-click-button temporary-support-info-amount-click-item">
                50,000원
              </button>
            </div>
            <input
              className="temporary-support-info-amount-click-input"
              placeholder="직접입력"
            />
          </div>
        </div>
      </div>
      <div className="temporary-user-info">
        <div className="temporary-user-info-title">
          <p>회원정보</p>
        </div>
        <div className="temporary-user-detail">
          <div className="temporary-user-detail-name">
            <div className="little-title">이름</div>
            <input
              className="temporary-user-detail-input"
              placeholder="정확하게 작성해주세요"
            />
            <input
              type="checkbox"
              className="temporary-user-detail-age-checkbox"
            />
            <p className="temporary-user-detail-age">만 14세 이상입니다</p>
          </div>
          <div className="temporary-user-detail-phone">
            <div className="little-title">연락처</div>
            <div className="temporary-user-detail-phone-num">
              <input
                type="tel"
                list="prefixes"
                placeholder="선택 또는 입력"
                className="temporary-user-detail-phone-prefixes-input"
              />
              <datalist id="prefixes">
                <option value="010" />
              </datalist>
              <p>-</p>
              <input type="tel" className="temporary-user-detail-phone-input" />
            </div>
          </div>
          <div className="temporary-user-detail-email">
            <div className="little-title">이메일</div>
            <input className="temporary-user-detail-email-input" />
          </div>
        </div>
      </div>
      <div className="temporary-support-info">
        <div className="temporary-support-info-title">
          <p>결제정보</p>
        </div>
        <div className="temporary-payment-info">
          <div className="little-title">결제방법</div>
          <div className="temporary-payment-info-click">
            <button className="temporary-payment-info-click-button temporary-support-info-amount-click-item">
              카드결제
            </button>
            <button className="temporary-payment-info-click-button temporary-support-info-amount-click-item">
              계좌이체
            </button>
            <button className="temporary-payment-info-click-button temporary-support-info-amount-click-item">
              카카오페이
            </button>
          </div>
        </div>
      </div>

      <div className="temporary-agreement">
        <div>
          <div className="temporary-agreement-terms">
            <input type="checkbox" className="checkpoint" />
            <div>
              <span className="red-span">[필수]</span> 이용약관 동의
            </div>
            <Link to="/terms" className="link-hover">
              [보기]
            </Link>
          </div>
          <div className="temporary-agreement-personal">
            <input type="checkbox" className="checkpoint" />
            <div>
              <span className="red-span">[필수]</span> 개인정보 처리방침 동의
            </div>
            <Link to="/personal" className="link-hover">
              [보기]
            </Link>
          </div>
        </div>
        <button className="temporary-pay-go">다음</button>
      </div>
    </div>
  );
};

export default TemporaryInfo;
