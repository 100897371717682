// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/mypagebanner.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mypage-banner-container {
    position: relative;
    width: 100%;
    height: 400px;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
}

.mypage-banner-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    /* 반투명 검은 오버레이 */
    z-index: 1;
}

.mypage-banner-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    z-index: 2;
    /* 텍스트를 오버레이 위에 표시 */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.mypage-banner-main-text {
    font-weight: bold;
    font-size: 35px;
}

.mypage-banner-explain-text {
    text-align: left;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.9);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    /* 그림자로 가독성 강화 */
}`, "",{"version":3,"sources":["webpack://./src/components/MyPage/MyPageBanner.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,yDAAkD;IAClD,sBAAsB;IACtB,2BAA2B;IAC3B,4BAA4B;IAC5B,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,YAAY;IACZ,kBAAkB;IAClB,UAAU;IACV,oBAAoB;IACpB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,QAAQ;AACZ;;AAEA;IACI,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,+BAA+B;IAC/B,2CAA2C;IAC3C,gBAAgB;AACpB","sourcesContent":[".mypage-banner-container {\n    position: relative;\n    width: 100%;\n    height: 400px;\n    background-image: url('./assets/mypagebanner.jpg');\n    background-size: cover;\n    background-position: center;\n    background-repeat: no-repeat;\n    overflow: hidden;\n}\n\n.mypage-banner-container::before {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.4);\n    /* 반투명 검은 오버레이 */\n    z-index: 1;\n}\n\n.mypage-banner-text {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    color: white;\n    text-align: center;\n    z-index: 2;\n    /* 텍스트를 오버레이 위에 표시 */\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 5px;\n}\n\n.mypage-banner-main-text {\n    font-weight: bold;\n    font-size: 35px;\n}\n\n.mypage-banner-explain-text {\n    text-align: left;\n    font-size: 12px;\n    color: rgba(255, 255, 255, 0.9);\n    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);\n    /* 그림자로 가독성 강화 */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
