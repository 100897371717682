import { useState } from "react";
import SmartEditor from "../../components/SmartEditor/SmartEditor";
import "./OnetoPen.css";
import axios from "axios";

const OnetoPen = () => {
  const [content, setContent] = useState("");
  const [name, setName] = useState("");
  const [animalSearch, setAnimalSearch] = useState("");
  const [animalDonationType] = useState(1);
  const [animalId, setAnimalId] = useState("");
  const [animalDetailTitle] = useState("");
  const [animalDetailSubTitle] = useState("");
  const [isDetail, setIsDetail] = useState(false);

  const handleAnimalSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(
        `https://forcatmung.kr/api/animal/search/simple?keyword=${animalSearch}`
      );
      const data = response.data[0];
      setName(data.animalName);
      setAnimalId(data.animalNumber);
      setIsDetail(data.isDetail);

      if (isDetail) {
        alert("이미 등록된 동물입니다.");
        setName("");
        setAnimalSearch("");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (!animalName) {
    //   alert("동물 이름을 입력해주세요.");
    //   return;
    // }

    if (!content) {
      alert("내용, 동물번호를 모두 입력해주세요");
      return;
    }

    const isConfirmed = window.confirm("작성하시겠습니까?");

    if (isConfirmed) {
      try {
        await axios.post(`https://forcatmung.kr/api/animaldetail/create`, {
          animalDetailTitle,
          animalDetailSubTitle,
          animalDetailContent: content,
          animalId,
          animalDonationType,
        });
        alert("작성에 성공하였습니다.");
      } catch (error) {
        console.error("작성 실패", error);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <main className="onetopen-main">
        <div className="onetopen-header">
          <h2>1:1후원 작성</h2>
        </div>
        <div className="onetopen-animal-search">
          <p>동물 검색</p>
          <input
            placeholder="동물이름입력"
            value={animalSearch}
            onChange={(e) => setAnimalSearch(e.target.value)}
          />
          <button type="button" onClick={handleAnimalSearch}>
            검색
          </button>
        </div>
        <div className="onetopen-title">
          <p>
            동물 이름 :{" "}
            <span style={{ color: "#005bc0", fontWeight: "bold" }}>{name}</span>
          </p>
        </div>
        <div className="onetopen-content">
          <p>내용</p>
          <SmartEditor onContentChange={setContent} />
        </div>
      </main>
    </form>
  );
};

export default OnetoPen;
