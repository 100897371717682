// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-navi-bar {
  width: 30vw;
  background-color: #f9f9f9;
  padding: 1vw 1vh;
  height: 100vh;
  box-sizing: border-box;
  text-align: left;
  margin-top: 10%;
}

.admin-navi-title {
  font-size: 3vw;
  font-weight: bold;
  color: #222;
  padding-left: 3%;
}

.admin-navi-list {
  list-style: none;
  margin: 0.5vh 0 3vh 0;
}

.admin-navi-item {
  padding: 0.5vw 0.9vh;
  font-size: 2vw;
  color: #8f8f8f;
  cursor: pointer;
  font-family: "Inter";
  font-style: normal;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-bottom: 0.5vh;
}

.admin-navi-item:hover,
.admin-navi-item:active {
  color: #222;
}

@media (min-width: 1025px) {
  .admin-navi-bar {
    width: 20vw;
    margin-top: 15vh;
    padding-left: 5vw;
  }

  .admin-navi-title {
    font-size: 2vw;
  }
  
  .admin-navi-list {
    margin: 1vh 0 4vh 0;
  }
  
  .admin-navi-item {
    font-size: 1.5vw;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Admin/AdminNav.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;EACzB,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,oBAAoB;EACpB,cAAc;EACd,cAAc;EACd,eAAe;EACf,oBAAoB;EACpB,kBAAkB;EAClB,uDAAuD;EACvD,oBAAoB;AACtB;;AAEA;;EAEE,WAAW;AACb;;AAEA;EACE;IACE,WAAW;IACX,gBAAgB;IAChB,iBAAiB;EACnB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".admin-navi-bar {\n  width: 30vw;\n  background-color: #f9f9f9;\n  padding: 1vw 1vh;\n  height: 100vh;\n  box-sizing: border-box;\n  text-align: left;\n  margin-top: 10%;\n}\n\n.admin-navi-title {\n  font-size: 3vw;\n  font-weight: bold;\n  color: #222;\n  padding-left: 3%;\n}\n\n.admin-navi-list {\n  list-style: none;\n  margin: 0.5vh 0 3vh 0;\n}\n\n.admin-navi-item {\n  padding: 0.5vw 0.9vh;\n  font-size: 2vw;\n  color: #8f8f8f;\n  cursor: pointer;\n  font-family: \"Inter\";\n  font-style: normal;\n  transition: background-color 0.3s ease, color 0.3s ease;\n  margin-bottom: 0.5vh;\n}\n\n.admin-navi-item:hover,\n.admin-navi-item:active {\n  color: #222;\n}\n\n@media (min-width: 1025px) {\n  .admin-navi-bar {\n    width: 20vw;\n    margin-top: 15vh;\n    padding-left: 5vw;\n  }\n\n  .admin-navi-title {\n    font-size: 2vw;\n  }\n  \n  .admin-navi-list {\n    margin: 1vh 0 4vh 0;\n  }\n  \n  .admin-navi-item {\n    font-size: 1.5vw;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
