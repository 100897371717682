import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getOneToOneDetailById } from "./apis/oneToOneDetailApi";

const OneToOneDetailInfo = () => {
  const { id } = useParams();
  const [animal, setAnimal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAnimalDetails = async () => {
      try {
        setLoading(true);
        setError(null);
        const data = await getOneToOneDetailById(id);
        setAnimal(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAnimalDetails();
  }, [id]);

  if (loading) {
    return <p>로딩 중입니다...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!animal) {
    return <p>해당 동물을 찾을 수 없습니다.</p>;
  }

  return (
    <>
      <section className="animal-detail">
        <div className="animal-detail__content">
          <img src={animal.image} alt={animal.name} className="animal-detail__image" />
          <div className="animal-detail__info">
            <div className="animal-detail__header">
              <h1 className="animal-detail__title">
                <div className="animal-detail__greeting">안녕하세요!</div>
                <div className="animal-detail__name">저는 {animal.name} 입니다!</div>
              </h1>
              <div className="animal-detail__description">
                {animal.name}은/는 {animal.age} {animal.gender} 아이입니다.
              </div>
            </div>
            <div className="animal-detail__details">
              <div className="animal-detail__row">
                <img src="/icons/pet-number-icon.svg" alt="동물번호" className="animal-detail__icon" />
                <div className="animal-detail__value">{animal.animalNumber}</div>
              </div>
              <div className="animal-detail__row">
                <img src="/icons/pet-date-icon.svg" alt="구조된 날짜 및 장소" className="animal-detail__icon" />
                <div className="animal-detail__value">
                  {animal.rescuedDate}에 {animal.rescuedPlace}
                </div>
              </div>
              <div className="animal-detail__row">
                <img src="/icons/pet-heart-icon.svg" alt="성격" className="animal-detail__icon" />
                <div className="animal-detail__value">{animal.personality}</div>
              </div>
              <div className="animal-detail__row">
                <img src="/icons/pet-disability-icon.svg" alt="장애" className="animal-detail__icon" />
                <div className="animal-detail__value">{animal.disability}</div>
              </div>
            </div>
            <div className="animal-detail__message">
              <p>
                아이들에게 안정적인 하루 하루를 선물해 주세요. <br />
                저의 따뜻한 후원자님을 기다립니다.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="animal-gallery">{/* <div className="animal-gallery__list">{animal.content}</div> */}</section>
    </>
  );
};

export default OneToOneDetailInfo;
