const AboutBanner = () => {
  return (
    <>
      <section className="about-banner">
        <div className="about-banner__content">
          <h1 className="about-banner__title">포캣멍 센터소개</h1>
          <h2 className="about-banner__title about-banner__title--sub">안락사 없는 보호소, 포캣멍센터를 소개합니다.</h2>
        </div>
      </section>
    </>
  );
};

export default AboutBanner;
