import React from "react";
import "./AdoptionBanner.css";

const AdoptionBanner = () => {
  return (
    <div className="adoption-banner-container">
      <div className="adoption-banner-text">
        <p className="adoption-banner-main-text">입양하기</p>
        <p className="adoption-banner-explain-text">
          아이들의 따뜻한 가정을 찾습니다
        </p>
      </div>
    </div>
  );
};

export default AdoptionBanner;
