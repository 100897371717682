import './SupportListOneToMany.css';
import OneToManyPerson from './assets/OneToManyPerson.png';  // 이미지 경로

const SupportListOneToMany = ({ onclick }) => {
    // 20개의 더미 데이터를 초기화
    const initialSupporters = Array.from({ length: 20 }, (_, index) => ({
        id: index + 1,
        name: `후원자 ${index + 1}`,
        src: OneToManyPerson,
    }));

    // 7개씩 한 줄에 배치
    const rows = [];
    for (let i = 0; i < initialSupporters.length; i += 6) {
        rows.push(initialSupporters.slice(i, i + 6));
    }

    return (
        <div className='support-list-container'>
            <div className='support-list-header'>
                <p className='support-list-text'>메롱이의 후원자분들</p>
                <hr className='support-list-hr' />
            </div>
            <div className='support-list'>
                {rows.map((row, rowIndex) => (
                    <div key={rowIndex} className='support-row'>
                        {row.map((supporter) => (
                            <div className='support-item' key={supporter.id}>
                                <img
                                    src={supporter.src}
                                    alt={supporter.name}
                                    className='support-item-img'
                                />
                                <p className='support-item-name'>{supporter.name}</p>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <div className='support-list-button'>
                <button
                    className="one-to-many-detail-button"
                    style={{ borderRadius: 5, boxShadow: 'none' }}
                >
                    후원하기
                </button>
                <button
                    className="one-to-many-donate-button"
                    onClick={onclick}
                    style={{ borderRadius: 5, boxShadow: 'none' }}
                >
                    목록으로
                </button>
            </div>
        </div>
    );
};

export default SupportListOneToMany;
