import { useParams } from "react-router-dom";
import RewardItemList from "../../../RewardItemList/RewardItemList";
import { useEffect, useState } from "react";
import { getOneToOneDetailById } from "./apis/oneToOneDetailApi";

const OneToOnePetReward = () => {
  const { id } = useParams();
  const [animal, setAnimal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAnimalDetails = async () => {
      try {
        setLoading(true);
        setError(null);
        const data = await getOneToOneDetailById(id);
        setAnimal(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAnimalDetails();
  }, [id]);

  if (loading) {
    return <p>로딩 중입니다...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!animal) {
    return <p>해당 동물을 찾을 수 없습니다.</p>;
  }

  return (
    <section className="pet-reward">
      <h2 className="pet-reward__title">{animal.name}(이/가) 보호자를 만나면?</h2>
      <RewardItemList />
    </section>
  );
};

export default OneToOnePetReward;
