import React, { useState } from 'react';
import MyPageTable from './MyPageTable';
import HistoryHeader from './HistoryHeader';
import usePagination from './UsePagination';
import Modal from './Modal';
import MyPageDetail from './MyPageDetail';
import useResponsivePage from './UseResponsivePage';

const MyPageOneToOne = () => {
    const headers = ['후원 동물', '후원 시작일', '후원 종료일', '결제금액'];
    const allData = [
        ['메롱이', '2024-12-23', '2025-12-22', '220,000원'],
        ['순자', '2023-12-23', '2024-12-22', '110,000원'],
        ['순자', '2023-12-23', '2024-12-22', '110,000원'],
        ['메롱이', '2024-12-23', '2025-12-22', '220,000원'],
        ['메롱이', '2024-12-23', '2025-12-22', '220,000원'],
        ['메롱이', '2024-12-23', '2025-12-22', '220,000원'],
        ['메롱이', '2024-12-23', '2025-12-22', '220,000원'],
        ['메롱이', '2024-12-23', '2025-12-22', '220,000원'],
        ['메롱이', '2024-12-23', '2025-12-22', '220,000원'],
    ];
    const itemsPerPage = useResponsivePage(8, 5)

    const { currentData, currentPage, totalPages, handlePageChange } = usePagination(allData, itemsPerPage);

    const [isModalOpen, setModalOpen] = useState(false);

    //const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const handleCellClick = (rowIndex, cellIndex, cellValue) => {
        setModalOpen(true);
    };

    return (
        <div>
            <HistoryHeader donateType={"1 : 1 후원내역"} />
            <MyPageTable
                headers={headers}
                data={currentData}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                totalPage={totalPages}
                onPageChange={handlePageChange}
                onCellClick={handleCellClick}
            />
            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <MyPageDetail />
            </Modal>
        </div>
    );
};

export default MyPageOneToOne;
