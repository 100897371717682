const HeritageBottomBanner = () => {
  return (
    <section className="heritage-bottom-banner__section">
      <div className="heritage-bottom-banner__content">
        <p className="heritage-bottom-banner__text">
          유산 기부는 따뜻한 마음을 영원히 간직하며,
          <br />더 많은 동물들에게 새로운 기회를 제공할 수 있는 소중한 선택입니다.
        </p>
        <p className="heritage-bottom-banner__sub-text">forcatmungcenter@naver.com로 문의 주세요 </p>
      </div>
    </section>
  );
};

export default HeritageBottomBanner;
