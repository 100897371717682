// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/Heart.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adoption-frame-header-image {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
}

/* 반응형 디자인 */
@media (max-width: 1024px) {
    .adoption-frame-header-image {
        width: 35px;
        height: 35px;
    }
   
}

@media (max-width: 768px) {
    .adoption-frame-header-image {
        width: 30px;
        height: 30px;
    }
    .frame-header-text{
        margin-left: 2vw;
    }
}

@media (max-width: 480px) {
    .adoption-frame-header-image {
        width: 25px;
        height: 25px;
    }
}

@media (max-width: 380px) {
    .adoption-frame-header-image {
        width: 20px;
        height: 20px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Adoption/AdoptionModalFrame.css"],"names":[],"mappings":"AAAA;IACI,yDAA2C;IAC3C,sBAAsB;IACtB,4BAA4B;IAC5B,WAAW;IACX,YAAY;AAChB;;AAEA,YAAY;AACZ;IACI;QACI,WAAW;QACX,YAAY;IAChB;;AAEJ;;AAEA;IACI;QACI,WAAW;QACX,YAAY;IAChB;IACA;QACI,gBAAgB;IACpB;AACJ;;AAEA;IACI;QACI,WAAW;QACX,YAAY;IAChB;AACJ;;AAEA;IACI;QACI,WAAW;QACX,YAAY;IAChB;AACJ","sourcesContent":[".adoption-frame-header-image {\n    background-image: url('./assets/Heart.png');\n    background-size: cover;\n    background-repeat: no-repeat;\n    width: 40px;\n    height: 40px;\n}\n\n/* 반응형 디자인 */\n@media (max-width: 1024px) {\n    .adoption-frame-header-image {\n        width: 35px;\n        height: 35px;\n    }\n   \n}\n\n@media (max-width: 768px) {\n    .adoption-frame-header-image {\n        width: 30px;\n        height: 30px;\n    }\n    .frame-header-text{\n        margin-left: 2vw;\n    }\n}\n\n@media (max-width: 480px) {\n    .adoption-frame-header-image {\n        width: 25px;\n        height: 25px;\n    }\n}\n\n@media (max-width: 380px) {\n    .adoption-frame-header-image {\n        width: 20px;\n        height: 20px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
