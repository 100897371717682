import { Outlet } from "react-router-dom";
import Percent from "../../components/Main/Percent/percent";
import AboutNav from "../../components/AboutPage/AboutNav";
import AboutText from "../../components/AboutPage/AboutText";
import AboutBanner from "../../components/AboutPage/AboutBanner";
import "./About.css";

const AboutPage = () => {
  return (
    <>
      <main className="md-main">
        <AboutBanner />
        <AboutText />
        <AboutNav />
        <Outlet />
        <Percent />
      </main>
    </>
  );
};

export default AboutPage;
