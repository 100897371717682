import './AdoptionField.css'

const AdoptionBirthDateField = ({ formData, setFormData }) => {
    const years = Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i);
    const months = Array.from({ length: 12 }, (_, i) => i + 1);
    const days = Array.from({ length: 31 }, (_, i) => i + 1);
    return (
        <div className="adoption-birth-form-row">
            <label>생년월일</label>
            <select
                name="year"
                value={formData.carerBirth.split('-')[0] || ''}
                onChange={(e) => setFormData({ ...formData, carerBirth: e.target.value })}
            >
                {years.map((year) => (
                    <option key={year} value={year}>{year}년</option>
                ))}
            </select>
            <select
                name="month"
                value={formData.carerBirth.split('-')[1] || ''}
                onChange={(e) => setFormData({ ...formData, carerBirth: formData.carerBirth.split('-')[0] + '-' + e.target.value })}
            >
                {months.map((month) => (
                    <option key={month} value={month}>{month}월</option>
                ))}
            </select>
            <select
                name="day"
                value={formData.carerBirth.split('-')[2] || ''}
                onChange={(e) => setFormData({ ...formData, carerBirth: formData.carerBirth.split('-')[0] + '-' + formData.carerBirth.split('-')[1] + '-' + e.target.value })}
            >
                {days.map((day) => (
                    <option key={day} value={day}>{day}일</option>
                ))}
            </select>
        </div>
    )
}

export default AdoptionBirthDateField