import React, { useEffect, useState } from 'react';
import './MyPageMainBanner.css';

const MyPageMainBanner = () => {
    const [userInfo, setUserInfo] = useState({ userName: '', userId: '' });
    const [animalData, setAnimalData] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0); // 현재 표시 중인 동물 인덱스
    const [isAnimating, setIsAnimating] = useState(false); // 애니메이션 상태

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userInfoData = {
                    userId: 'dlrkdxo',
                    userName: '이강태',
                };
                const allData = [
                    {
                        catName: '메롱이',
                        donateStartDate: '2024-12-22',
                    },
                    {
                        catName: '루비',
                        donateStartDate: '2020-11-22',
                    },
                    {
                        catName: '순자',
                        donateStartDate: '2023-10-01',
                    },
                ];

                // 각 동물별 경과 월수 계산
                const currentDate = new Date();
                const enrichedData = allData.map((animal) => {
                    const startDate = new Date(animal.donateStartDate);
                    const diffTime = Math.abs(currentDate.getTime() - startDate.getTime());
                    const diffMonths = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 30)); // 올림 처리
                    return {
                        ...animal,
                        months: diffMonths // 최소 1개월
                    };
                });

                setUserInfo(userInfoData);
                setAnimalData(enrichedData);
            } catch (error) {
                console.error('데이터를 가져오는 중 오류가 발생했습니다:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        // 5초마다 다음 동물로 변경
        const interval = setInterval(() => {
            setIsAnimating(true); // 애니메이션 시작
            setTimeout(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % animalData.length); // 다음 동물로 변경
                setIsAnimating(false); // 애니메이션 종료
            }, 500); // 애니메이션 지속 시간에 맞춤
        }, 5000);

        return () => clearInterval(interval); // 컴포넌트 언마운트 시 인터벌 제거
    }, [animalData]);

    if (animalData.length === 0) {
        return <div>Loading...</div>; // 데이터 로드 중 표시
    }

    const currentAnimal = animalData[currentIndex];

    return (
        <div className='mypage-main-banner-container'>
            <div className='mypage-main-banner-content-box'>
                <div className='mypage-main-banner-text-box'>
                    <span>안녕하세요</span>
                    <div className='mypage-main-banner-text-box-content'>
                        <span style={{ fontSize: 'clamp(12px, 2vw, 20px)' }}>
                            <strong style={{ fontSize: 'clamp(16px, 2vw, 30px)', fontWeight: 'bold' }}>
                                {userInfo.userName} 회원님
                            </strong> ({userInfo.userId})
                        </span>
                        <div className={`mypage-animal-content-box ${isAnimating ? 'slide-out' : 'slide-in'
                            }`}>
                            <span style={{ fontSize: 'clamp(12px, 2vw, 20px)', marginTop: '10px' }}>
                                {currentAnimal.catName}와 함께한지
                                <strong style={{ margin: '5px', fontSize: 'clamp(12px,2vw,20px)' }}>
                                    {currentAnimal.months}
                                </strong>
                                개월째
                            </span>
                        </div>
                    </div>
                </div>
                <hr className='main-banner-footer-line' />
            </div>
        </div>
    );
};

export default MyPageMainBanner;
