import React from "react";
import "./AskForHelpContent.css";

function AskForHelpContent() {
  return (
    <div>
      <div className="ask-for-help-header">
        <div className="ask-for-help-text-line">
          <hr className="ask-for-help-header-divider" />
          <span className="ask-for-help-header-title">구조 / 학대 제보</span>
        </div>
        <button className="report-abuse-button">제보하기</button>
      </div>
      <div className="what-is-animal-abuse">
        <div className="animal-abuse-title">동물학대란?</div>
        <div className="animal-abuse-description">
          동물을 대상으로 정당한 사유 없이 불필요하거나 피할 수 있는 <br />
          신체적 고통과 스트레스를 주는 행위 및 굶주림, 질병 등에 대하여 <br />
          적절한 조치를 게을리하거나 방치하는 행위를 말한다.
        </div>
      </div>
    </div>
  );
}

export default AskForHelpContent;
