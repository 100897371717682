import React from "react";
import "./Modal.css"; // 모달 스타일링을 위한 CSS

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null; // 모달이 열리지 않은 상태라면 아무것도 렌더링하지 않음

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-content-button-container">
          <button className="modal-close" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="modal-content-box">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
