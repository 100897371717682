import React from 'react';
import MyPageBanner from '../../components/MyPage/MyPageBanner';
import MyPageNaviBar from '../../components/MyPage/MyPageNaviBar';
import { Outlet } from 'react-router-dom'; // Outlet import
import "../Main/Main.css";
import MyPageMainBanner from '../../components/MyPage/MyPageMainBanner';
import "./MyPage.css";

const MyPage = () => {
    return (
        <>
            <main className="md-main">
                <div className="mypage-container">
                    <MyPageBanner />
                    <MyPageMainBanner />
                    <div className="mypage-content">
                        <MyPageNaviBar />
                        <div className="mypage-outlet">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default MyPage;
