const FaqHeader = () => {
  return (
    <section className="faq-header">
      <div className="faq-header__title faq-header__sub-title">궁금하신 내용이 있나요?</div>
      <h1 className="faq-header__title">FAQ</h1>
    </section>
  );
};

export default FaqHeader;
