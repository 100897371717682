import { useEffect, useState } from 'react'

const AdoptionUseFormValidation = ({ adoptionData, carerHouseImages }) => {
    const [missingFields, setMissingFields] = useState([]);
    const [isConsentChecked, setIsConsentChecked] = useState(true);

    useEffect(() => {
        const fields = [];
        let consentChecked = true;

        // adoptionData가 정의되어 있지 않으면 기본값을 사용
        if (adoptionData) {
            // 필수 필드 유효성 검사
            if (!adoptionData.carerName) fields.push('이름');
            if (!adoptionData.isAdult) fields.push('성인여부');
            if (!adoptionData.carerBirth) fields.push('생년월일');
            if (!adoptionData.carerAddress) fields.push('주소');
            if (!adoptionData.carerPhone) fields.push('연락처');
            if (!adoptionData.carerEmail) fields.push('이메일');
            if (!adoptionData.carerJob) fields.push('직업');
            if (!adoptionData.carerEarnings) fields.push('주 보호자 소득');
            if (!adoptionData.carerFamilyMember) fields.push('가족 구성원');
            if (!adoptionData.reasonForAdoption) fields.push('입양 이유');
            if (!adoptionData.carerComment) fields.push('그 외 하실 말씀');

            // 동의 항목 체크
            if (!adoptionData.agreeForFilming || !adoptionData.agreeToTermsOfUse || !adoptionData.agreePersonalInformation) {
                consentChecked = false;
            }
        }

        setMissingFields(fields);
        setIsConsentChecked(consentChecked);
    }, [adoptionData, carerHouseImages]);

    // 유효성 검사 결과 반환
    const validateFields = missingFields.length === 0 && isConsentChecked;

    return { validateFields, missingFields, isConsentChecked };
};


export default AdoptionUseFormValidation