import React from 'react';
import './OneToManyFooter.css';
import grid1 from './assets/grid_1.png';
import grid2 from './assets/grid_2.png';
import grid3 from './assets/grid_3.png';
import grid4 from './assets/grid_4.png';
import wave from './assets/wave.png';

const OneToManyFooter = ({ name }) => {
    return (
        <div
            className="one-to-many-footer"
            style={{ backgroundImage: `url(${wave})` }}
        >
            <div className="one-to-many-footer-title">
                {name}(가) 후원자님을 만나면?
            </div>

            <div className="one-to-many-footer-grid">
                <div className="one-to-many-footer-grid-container">
                    <img
                        src={grid1}
                        alt="grid 1"
                        className="one-to-many-footer-grid-item"
                    />
                    <img
                        src={grid2}
                        alt="grid 2"
                        className="one-to-many-footer-grid-item"
                    />
                    <img
                        src={grid3}
                        alt="grid 3"
                        className="one-to-many-footer-grid-item"
                    />
                    <img
                        src={grid4}
                        alt="grid 4"
                        className="one-to-many-footer-grid-item"
                    />
                </div>
            </div>
        </div>
    );
};

export default OneToManyFooter;
