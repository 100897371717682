import React, { useEffect, useState } from "react";
import "./OneToOneBanner.css";
import OneToOnePageGrid from "./OneToOnePageGrid";
import OneToOnePageList from "./OneToOnePageList"; // OneToOnePageList 컴포넌트 import
import axios from "axios";

const OneToOneMainPage = () => {
  const [isGridView, setIsGridView] = useState(true); // 그리드 뷰 상태 관리
  const [listData, setListData] = useState([]);

  const handleViewChange = (view) => {
    setIsGridView(view);
  };

  useEffect(() => {
    const fetchListData = async () => {
      try {
        const response = await axios.get(
          `https://forcatmung.kr/api/animal/onetoone/list`
        );
        setListData(response.data);
        // console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchListData();
  }, []);

  return (
    <div className="one-to-one-page">
      <div className="one-to-one-topper">
        <h1>따뜻한 후원의 손길을 기다리는 아이들을 소개합니다</h1>
        <h2>1:1 후원하기</h2>
        <div className="button-container">
          <button
            className={`list-button ${!isGridView ? "active" : ""}`}
            onClick={() => handleViewChange(false)} // 리스트 뷰로 변경
          ></button>
          <button
            className={`grid-button ${isGridView ? "active" : ""}`}
            onClick={() => handleViewChange(true)} // 그리드 뷰로 변경
          ></button>
        </div>
        <hr className="one-to-one-divider" />
      </div>
      {isGridView ? (
        <OneToOnePageGrid listData={listData} />
      ) : (
        <OneToOnePageList listData={listData} />
      )}{" "}
      {/* 조건에 따라 컴포넌트 렌더링 */}
    </div>
  );
};

export default OneToOneMainPage;
