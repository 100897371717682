import React from "react";
import './DeleteModalComponent.css'
import { useNavigate } from "react-router-dom";

const DeleteModalComponent = ({ onClose }) => {
    const navigate = useNavigate();

    const handleClickDeleteIdButton = () => {
        navigate('deleteid');
        onClose();
        window.scrollTo(0, 0);
    }
    return (
        <div className="delete-modal-component-container">
            <h1>정말 탈퇴 하시겠습니까?</h1>
            <hr />
            <p>탈퇴 시 아래 내용이 적용됩니다</p><br />
            <p>회원님의 모든 데이터가 삭제 되며</p>
            <p>진행 중인 후원이 중단됩니다.</p>
            <p>탈퇴후 동일한 계정으로 재가입은</p>
            <p>불가능할 수 있습니다.</p>
            <div className="delete-modal-component-button-container">
                <button className={`button cancel`} onClick={onClose}>취소</button>
                <button className={`button delete`} onClick={handleClickDeleteIdButton}>탈퇴</button>
            </div>
        </div>
    );
}

export default DeleteModalComponent;
