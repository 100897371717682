import React, { useState, useEffect, useCallback } from "react";
import "./News.css";
import NewsItem from "./NewsItem.jsx";
import axios from 'axios';
import { Link } from "react-router-dom";

const NewsList = () => {
  const [news, setNews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState("");
  const itemsPerPage = 10;
  const group = 5;


  // 디폴트 이미지 수정하기
  const defaultImage = `${process.env.PUBLIC_URL}/Forcatmung.png`;

  const fetchNews = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await axios.get(
        `https://forcatmung.kr/api/news/list?page=${
          currentPage - 1
        }&size=${itemsPerPage}`
      );
      const {
        currentPage: apiCurrentPage,
        totalPages,
        newsList,
      } = response.data;
      setNews(newsList);
      setCurrentPage(apiCurrentPage + 1);
      setTotalPages(totalPages);
    } catch (error) {
      console.error("Error fetching news:", error);
      setError("센터소식을 불러오는 데 실패했습니다. 다시 시도해 주세요.");
    } finally {
      setLoading(false);
    }
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    fetchNews();
  }, [fetchNews]);

  const fetchSearchResults = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await axios.get(
        `https://forcatmung.kr/api/news/search?keyword=${encodeURIComponent(
          search
        )}`
      );
      const { newsList, totalCount } = response.data;
      setNews(newsList);
      setTotalPages(Math.ceil(totalCount / itemsPerPage));
    } catch (error) {
      console.error("Error fetching search results:", error);
      setError("검색 결과를 불러오는 데 실패했습니다. 다시 시도해 주세요.");
    } finally {
      setLoading(false);
    }
  };

  const handleSearchClick = () => {
    if (search.trim()) {
      fetchSearchResults();
    }
  };

  const handlePageChange = (pageNumber) => {
    if (search.trim()) {
      fetchSearchResults();
    } else {
      setCurrentPage(pageNumber);
    }
  };

  const renderPageButton = () => {
    const currentGroup = Math.ceil(currentPage / group);
    const startPage = (currentGroup - 1) * group + 1;
    const endPage = Math.min(currentGroup * group, totalPages);

    return Array.from({ length: endPage - startPage + 1 }, (_, index) => {
      const pageNumber = startPage + index;
      return (
        <button
          key={pageNumber}
          onClick={() => handlePageChange(pageNumber)}
          className={currentPage === pageNumber ? "active" : ""}
        >
          {pageNumber}
        </button>
      );
    });
  };

    return (
    <div className="news-list-container">
      <div className="news-list-title">
        <div className="news-list-title-text">
        </div>
        <div className="news-list-title-search">
          <input type="search" placeholder="검색어를 입력하세요"  value={search} onChange={(e) => setSearch(e.target.value)}/>
          <button onClick={handleSearchClick}>검색</button>
        </div>
      </div>
      {loading ? (
        <p>로딩 중...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <div>
          {news.map((item) => (
            <Link
              to={`/news/list/${item.newsid}`}
              key={item.newsid}
              // onClick={handleNavigate}
            >
              <NewsItem
                key={item.newsid}
                id={item.newsid}
                imageUrl={item.imageUrl || defaultImage}
                title={item.title}
                author={item.author}
                date={item.date}
                content={item.content}
              />
            </Link>
          ))}
        </div>
      )}
      {!loading && !error && (
        <div className="pagination">
          {currentPage > group && (
            <button onClick={() => handlePageChange(currentPage - group)}>
              이전
            </button>
          )}
          {renderPageButton()}
          {currentPage < totalPages - group + 1 && (
            <button onClick={() => handlePageChange(currentPage + group)}>
              다음
            </button>
          )}
        </div>
      )}
    </div>
  );
};


export default NewsList;