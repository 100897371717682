// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-list-container {
    margin-top: 5vh;
}

.admin-button-container {
    margin-top: 5vh;
}

.admin-regist-button {
    width: 15%;
    border: none;
    background-color: #5d9cec;
    padding: 10px 20px;
    font-size: 1.2rem;
    color: white;
}

.admin-input-box {
    margin-left: 20%;
    width: 15%;
    padding: 10px 20px;
}

.admin-input-button {
    width: 15%;
    border: none;
    background-color: #5d9cec;
    padding: 10px 20px;
    font-size: 1.2rem;
    color: white;
    margin-left: 3%;
}

.admin-list-table-container {
    margin-top: 15vh;
}

.admin-list-table-row {
    display: flex; /* Flexbox 사용 */
    align-items: center; /* 수직 중앙 정렬 */
    border-bottom: 1px solid #ccc;
    padding: 2vh 0;
  }
  
  .admin-list-table-cell {
    flex: 1 1;
    text-align: center;
  }
  
  .admin-list-table-cell:empty {
    visibility: hidden;
  }

  .admin-list-table {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 5%;
  }

  .admin-list-image {
    width: 20%;
    height: 20%;
    margin : 1% 0 1% 0;
  }`, "",{"version":3,"sources":["webpack://./src/components/Admin/Post/AdminAniList.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,UAAU;IACV,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,iBAAiB;IACjB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa,EAAE,eAAe;IAC9B,mBAAmB,EAAE,aAAa;IAClC,6BAA6B;IAC7B,cAAc;EAChB;;EAEA;IACE,SAAO;IACP,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;IACjB,iBAAiB;IACjB,iBAAiB;EACnB;;EAEA;IACE,UAAU;IACV,WAAW;IACX,kBAAkB;EACpB","sourcesContent":[".admin-list-container {\n    margin-top: 5vh;\n}\n\n.admin-button-container {\n    margin-top: 5vh;\n}\n\n.admin-regist-button {\n    width: 15%;\n    border: none;\n    background-color: #5d9cec;\n    padding: 10px 20px;\n    font-size: 1.2rem;\n    color: white;\n}\n\n.admin-input-box {\n    margin-left: 20%;\n    width: 15%;\n    padding: 10px 20px;\n}\n\n.admin-input-button {\n    width: 15%;\n    border: none;\n    background-color: #5d9cec;\n    padding: 10px 20px;\n    font-size: 1.2rem;\n    color: white;\n    margin-left: 3%;\n}\n\n.admin-list-table-container {\n    margin-top: 15vh;\n}\n\n.admin-list-table-row {\n    display: flex; /* Flexbox 사용 */\n    align-items: center; /* 수직 중앙 정렬 */\n    border-bottom: 1px solid #ccc;\n    padding: 2vh 0;\n  }\n  \n  .admin-list-table-cell {\n    flex: 1;\n    text-align: center;\n  }\n  \n  .admin-list-table-cell:empty {\n    visibility: hidden;\n  }\n\n  .admin-list-table {\n    font-size: 1.5rem;\n    font-weight: bold;\n    margin-bottom: 5%;\n  }\n\n  .admin-list-image {\n    width: 20%;\n    height: 20%;\n    margin : 1% 0 1% 0;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
