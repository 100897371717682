import React from "react";
import AskForHelpBanner from "./AskForHelpBanner";
import AskForHelpContent from "./AskForHelpContent";

function AskForHelp() {
  return (
    <div>
      <AskForHelpBanner />
      <AskForHelpContent />
    </div>
  );
}

export default AskForHelp;
