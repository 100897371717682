const HeritageBanner = () => {
  return (
    <section className="faq-banner">
      <div className="faq-banner__content">
        <h1 className="faq-banner__title">유산기부</h1>
        <h2 className="faq-banner__title faq-banner__title--sub">사랑과 나눔으로 이어지는 특별한 선택</h2>
      </div>
    </section>
  );
};

export default HeritageBanner;
