import React from "react";
import "./VolunteerModal.css"; // 스타일 정의

const VolunteerModal = ({ onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <div className="modal-header">
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="volunteer-modal-content">
          <h2 className="volunteer-h2">봉사 신청이 완료되었습니다</h2>
          <p className="volunteer-p">소중한 결정을 내려주셔서 진심으로 감사합니다.</p>
          <p className="volunteer-p">보호소의 담당자가 신청 내용을 검토한 후,</p>
          <p className="volunteer-p" style={{marginBottom:"3%"}}>빠른 시일 내에 연락드리겠습니다.</p>
        </div>
        <div className="modal-footer">
          <button className="volunteer-close-button" onClick={onClose}>닫기</button>
        </div>
        </div>
      </div>
  );
};

export default VolunteerModal;