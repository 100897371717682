import React from 'react'
import './AdoptionModalFrame.css'

const AdoptionModalFrame = ({ children }) => {
    return (
        <div className='frame-container'>
            <div className='frame-header-container'>
                <div className='adoption-frame-header-image'></div>
                <div className='frame-header-text'>
                    <h1>입양하기</h1>
                    <h3>아이들의 가족이 되어주실 분들을 찾습니다.</h3>
                </div>
            </div>
            <div>
                {children}
            </div>
        </div>
    )
}

export default AdoptionModalFrame