import React from "react";
import AdoptionBanner from "../../components/Adoption/AdoptionBanner";
import AdoptionHeader from "../../components/Adoption/AdoptionHeader";
import AdoptionList from "../../components/Adoption/AdoptionList";

const AdoptionPage = () => {
  return (
    <>
      <main className="md-main">
        <AdoptionBanner />
        <AdoptionHeader />
        <AdoptionList />
      </main>
    </>
  );
};

export default AdoptionPage;
