import FAQList from "./FAQList";
import FaqBanner from "./FaqBanner";
import FaqHeader from "./FaqHeader";
import FaqMailAsk from "./FaqMailAsk";
import "./faq.css";
const Faq = () => {
  return (
    <main className="md-main">
      <FaqBanner />
      <FaqHeader />
      <FaqMailAsk />
      <FAQList />
    </main>
  );
};

export default Faq;
