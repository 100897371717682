import './AdoptionField.css'

const AdoptionJobField = ({ formData, setFormData }) => {
    return (
        <div className='adoption-job-form-row'>
            <label>직업</label>
            <input
                value={formData.carerJob}
                placeholder='직업을 작성해주세요'
                style={{ height: 30, paddingLeft: 15 }}
                onChange={(e) => setFormData({ ...formData, carerJob: e.target.value })}
            />
        </div>
    )
}

export default AdoptionJobField