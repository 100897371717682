// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.volunteering-page-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    background-color: white;
  }`, "",{"version":3,"sources":["webpack://./src/pages/Volunteering/Volunteering.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,uBAAuB;EACzB","sourcesContent":[".volunteering-page-container {\n    width: 100%;\n    margin: 0 auto;\n    display: flex;\n    flex-direction: column;\n    background-color: white;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
