import "./Volunteering.css";

const VolunteeringBottomBanner = () => {
  return (
<section className="volunteering-bottom-banner">
<div className="volunteering-bottom-banner__content">
  <h1 className="volunteering-bottom-banner__title1">함께하는 봉사로 따뜻한 사회를<br /></h1>
  <h1 className="volunteering-bottom-banner__title2">만들어가는 여정에 동참해주세요</h1>
  <p className="volunteering-bottom-banner__description">여러분들의 따뜻한 손길이 동물들에게 큰 기적이 됩니다.</p>
</div>
<div className="volunteering-bottom-banner__image-container">
    <div className="bottom-banner__image-blur"></div>
  <img src="/images/VolunteeringBottomBanner.png" alt="봉사활동 모집 중~" className="volunteering-bottom-banner__image" />
  <div className="bottom-banner__image-overlay"></div>
  <div className="bottom-banner__image-blur"></div>
</div>
</section>
  );
};

export default VolunteeringBottomBanner;

