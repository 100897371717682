import './DonationUseList.css'
import {DonationUseListBanner} from "../../components/DonationUseList"
import { Outlet } from "react-router-dom";

const DonationUseList = () => {
  return (
    <>
      <main className="donation-uselist-container">
        <DonationUseListBanner />
        <Outlet />
      </main>
    </>
  );
};
export default DonationUseList;


