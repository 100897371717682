// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adoption-header-container {
  margin-top: 5vw;
  margin-left: 2vw;
}

.adoption-header-mini-text {
  font-size: 1vw;
  color: #005eb8;
  margin-bottom: -0.5vw;
}

.adoption-header-main-text {
  font-size: 2vw;
  font-weight: bold;
  color: #005eb8;
  margin-bottom: 2vw;
}

.adoption-header-hr {
  border: 0;
  height: 4px;
  background-color: #005eb8;
}

/* 반응형 디자인 */
@media (max-width: 1024px) {
  .adoption-header-container {
    margin-top: 6vw;
    margin-left: 3vw;
  }

  .adoption-header-mini-text {
    font-size: 1.5vw;
  }

  .adoption-header-main-text {
    font-size: 3vw;
  }
}

@media (max-width: 768px) {
  .adoption-header-container {
    margin-top: 8vw;
    margin-left: 4vw;
  }
  .adoption-header-hr {
    height: 2px;
  }
}

@media (max-width: 480px) {
  .adoption-header-container {
    margin-top: 8vw;
    margin-left: 5vw;
  }
  .adoption-header-hr {
    height: 1.5px;
  }
}

@media (max-width: 380px) {
  .adoption-header-container {
    margin-top: 8vw;
    margin-left: 5vw;
  }
  .adoption-header-hr {
    height: 1.5px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Adoption/AdoptionHeader.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,SAAS;EACT,WAAW;EACX,yBAAyB;AAC3B;;AAEA,YAAY;AACZ;EACE;IACE,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,cAAc;EAChB;AACF;;AAEA;EACE;IACE,eAAe;IACf,gBAAgB;EAClB;EACA;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,eAAe;IACf,gBAAgB;EAClB;EACA;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,eAAe;IACf,gBAAgB;EAClB;EACA;IACE,aAAa;EACf;AACF","sourcesContent":[".adoption-header-container {\n  margin-top: 5vw;\n  margin-left: 2vw;\n}\n\n.adoption-header-mini-text {\n  font-size: 1vw;\n  color: #005eb8;\n  margin-bottom: -0.5vw;\n}\n\n.adoption-header-main-text {\n  font-size: 2vw;\n  font-weight: bold;\n  color: #005eb8;\n  margin-bottom: 2vw;\n}\n\n.adoption-header-hr {\n  border: 0;\n  height: 4px;\n  background-color: #005eb8;\n}\n\n/* 반응형 디자인 */\n@media (max-width: 1024px) {\n  .adoption-header-container {\n    margin-top: 6vw;\n    margin-left: 3vw;\n  }\n\n  .adoption-header-mini-text {\n    font-size: 1.5vw;\n  }\n\n  .adoption-header-main-text {\n    font-size: 3vw;\n  }\n}\n\n@media (max-width: 768px) {\n  .adoption-header-container {\n    margin-top: 8vw;\n    margin-left: 4vw;\n  }\n  .adoption-header-hr {\n    height: 2px;\n  }\n}\n\n@media (max-width: 480px) {\n  .adoption-header-container {\n    margin-top: 8vw;\n    margin-left: 5vw;\n  }\n  .adoption-header-hr {\n    height: 1.5px;\n  }\n}\n\n@media (max-width: 380px) {\n  .adoption-header-container {\n    margin-top: 8vw;\n    margin-left: 5vw;\n  }\n  .adoption-header-hr {\n    height: 1.5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
