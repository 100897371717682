import { Link } from "react-router-dom";

const AboutPurpose = () => {
  return (
    <>
      <section className="purpose-intro">
        <img src="/images/kangTe-nim2.png" alt="강태님" className="purpose-intro__image" />
        <div className="purpose-intro__contant">
          <div className="purpose-title__contant">
            <h1 className="purpose-title">
              <div className="purpose-title__highlight">사단 법인</div>
              포켓멍센터
            </h1>
          </div>
          <p className="purpose-intro__description">
            본 법인은 「민법」제3장 및 「농림축산식품부 장관 및 그 소속 청장 소관비영리법인의 설립 및 감독에 관한 규칙」에 의거생명존중의 정신으로 지역사회에서 동물복지 증진을 위하여버림받은 유기
            동물들과 열악한 환경하의 동물들의 보호와 안전을 도모하는 활동을체계적, 지속적으로 전개하여 유기 동물의 생명 보장과 동물보호 문화 확산으로사람과 동물이 함께하는 행복한 공존을
            위하여비영리법인을 설립했습니다.
          </p>
        </div>
      </section>
      <section className="purpose-images">
        <div className="purpose-images__contant">
          <img src="/images/purpose-img.png" alt="강태님" className="purpose-images__image" />
          <img src="/images/purpose-img1.png" alt="강태님" className="purpose-images__image" />
          <img src="/images/purpose-img2.png" alt="강태님" className="purpose-images__image" />
        </div>
      </section>
      <section className="purpose-to-join">
        <div className="purpose-title__contant">
          <h1 className="purpose-title purpose-title__right">
            <div className="purpose-title__highlight">동물보호의 뜻을</div>
            함께 해주시겠어요 ?
          </h1>
        </div>
        <Link to="/" class="text-button">
          참여하러 가기
          <span class="text-button__arrow"></span>
        </Link>
      </section>
    </>
  );
};

export default AboutPurpose;
