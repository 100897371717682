import React from 'react';
import './Volunteering.css';

const VolunteeringPrivacyPolicy = () => {
  return (
  <div>
    <p>Hello World!(개인정보 처리방침 페이지)</p>
  </div>
  );
};

export default VolunteeringPrivacyPolicy;