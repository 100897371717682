import React from 'react'
import './MyPageDeleteIdContent.css'
import { useNavigate } from 'react-router-dom'

const MyPageDeleteIdContent = () => {
  const navigate = useNavigate();

  const handleClickGoHomeButton = () => {
    navigate('/');
    window.scrollTo(0, 0);
  }
  return (
    <div className='mypage-deleteid-content-container'>
      <div className='mypage-deleteid-content-header'>
        <h1>회원 탈퇴가 완료되었습니다.</h1>
      </div>
      <div className='mypage-deleteid-content-text'>
        <h2>그동안 저희 동물 보호소를 위해 함께해 주셔서</h2>
        <h2>진심으로 감사드립니다.</h2>
        <h2>후원자님의 관심과 후원이 많은 동물들에게 큰 힘이 되었습니다.</h2>
        <br />
        <br />
        <h2>앞으로도 따뜻한 관심으로 동물들을 응원해 주시길 부탁드립니다.</h2>
        <h2>감사합니다.</h2>
        <br />
        <br />
        <h2>포캣멍센터 드림</h2>
        <div className='mypage-deleteid-content-button-box'>
          <button className='mypage-deleteid-content-button' onClick={handleClickGoHomeButton}>홈으로</button>
        </div>
      </div>
    </div>
  )
}

export default MyPageDeleteIdContent
