import PortOne from "@portone/browser-sdk/v2";

const SubscribeDonation = () => {
  const handleBilling = async () => {
    const issueResponse = await PortOne.requestIssueBillingKey({
      storeId: process.env.REACT_APP_STORE_ID,
      channelKey: process.env.REACT_APP_KAKAOCHANNEL_SUBSCRIBE_KEY,
      billingKeyMethod: "EASY_PAY",
      issueName: "빌링키 임시",
      displayAmount: 1000,
      currency: "CURRENCY_KRW",
    });
    if (issueResponse.code !== undefined) {
      return alert("빌링키 발급실패", `${issueResponse.message}`);
    }
  };

  return { handleBilling };
};

export default SubscribeDonation;
