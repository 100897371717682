// import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ImageSlider.css";
// import { NavLink } from "react-router-dom";

const Sliders = () => {
  const images = [
    "https://png.pngtree.com/thumb_back/fh260/background/20230609/pngtree-three-puppies-with-their-mouths-open-are-posing-for-a-photo-image_2902292.jpg",
    "https://www.shutterstock.com/image-photo/beautiful-golden-retriever-cute-puppy-260nw-2526542701.jpg",
    "https://png.pngtree.com/thumb_back/fh260/background/20230613/pngtree-small-white-rabbit-in-the-grass-image_2915502.jpg",
    "https://cdn.pixabay.com/photo/2024/02/26/19/39/monochrome-image-8598798_1280.jpg",
    "https://www.adobe.com/kr/creativecloud/photography/hub/features/roc/media_126f51234e424100112aecb4f61e26b3a2afc74d8.jpeg?width=750&format=jpeg&optimize=medium"
  ]

  // const [currentSlide, setCurrentSlide] = useState(1);

  // const handleDetailButton = () => {
  //   alert(`자세히 보기 ${currentSlide} 클릭!`);
  // };

  // const handleDonateButton = () => {
  //   alert(`후원하기 ${currentSlide} 클릭!`);
  // };

  // 이미지슬라이드 설정
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    // beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex + 1)
  };

  return (
    <div style={{ position: "relative", width: "100%", height: "20%", overflow: "hidden",  }}>
      <Slider {...settings} dotsClass="test-css">
        {images.map((url, index) => (
          <div key={index}>
            <img src={url} alt={`Slide ${index}`} style={{
              width: "100%",
              height: "600px",
            }} />
          </div>
        ))}
      </Slider>

      {/* 버튼들 */}
      <div className="btn-css">
        <button
          className="btn-detail" 
          >
          입양하기
        </button>
        <button className="btn-donate"
          >
          구조학대
        </button>
        {/* <NavLink to="/mainmain/donation">
        미지의 세계로!
        </NavLink> */}
      </div>
    </div>
  );
}
export default Sliders;