import React from 'react';
import "./Admin.css"
import { Outlet } from "react-router-dom";
import {AdminBanner, AdminNav} from "../../components/Admin"


const Admin = () => {
    return (
        <>
            <div className='admin-container'>
                <AdminBanner />
             <div className='admin-content'>
                    <AdminNav />
                    <div className='admin-outlet'>
                    <Outlet />
                  </div>
                </div>
            </div>
        </>
    );
};
  
  export default Admin;