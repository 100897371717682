import "./SupportPageType.css";
import { Link } from "react-router-dom";

const SupportPageType = () => {
  return (
    <div className="support-type-container">
      <div className="support-type-header">
        <p>
          포캣멍 센터의 후원에는
          <br />
          <span>어떤 종류가 있나요?</span>
        </p>
      </div>
      <div className="support-type-select">
        <Link to="/donation" className="support-type-select-regular">
          <div className="support-type-arrow" />
          <p>
            <span>정기후원하기</span>
            <br />
            정기후원에 대한 글을
            <br />
            적어주시면 될 것 같습니다
          </p>
        </Link>
        <div className="support-type-select-other">
          <Link to="/donation" className="support-type-select-other-temporary">
            <div className="support-type-arrow" />
            <p>
              <span>일시후원하기</span>
              <br />
              일시후원에 대한 글을
              <br />
              적어주시면 될 것 같습니다
            </p>
          </Link>
          <Link to="/donation" className="support-type-select-other-add">
            <div className="support-type-arrow" />
            <p>
              <span>후원증액하기</span>
              <br />
              후원자님의 후원금을 증액해주세요
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default SupportPageType;
