import "./FanClubBanner.css"

const FanClubBanner = () => {
    return (
        <div className="fan-club-banner">
            <div className="fan-club-banner-content">
                <h1 className="fan-club-banner-title">스타 팬클럽 후원</h1>
                <h2 className="fan-club-banner-subtitle">스타와 팬이 함께 나누는 따뜻한 손길</h2>
            </div>
        </div>
    );
}

export default FanClubBanner;