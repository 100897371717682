import React from 'react'
import './FanClubCheckList.css'

const FanClubCheckList = () => {
    return (
        <div className='check-list-container'>
            <div className='check-list-content-container'>
                <div className='check-list-header'>
                    <h1>후원 신청 확인사항</h1>
                </div>
                <div className='check-list-body'>
                    <div>
                        <h2>후원방법</h2>
                        <ul>
                            <li>기업은행 326-149252-04-016 (예금주 : 사단법인 포캣멍센터)로 입금 또는
                                일시후원 페이지에서 후원 가능합니다.
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h2>유의사항</h2>
                        <ul>
                            <li>후원 게시물에 등록될 이미지는 메일로 전달 부탁드립니다. (forcatmungcenter@naver.com)</li>
                            <li>신청서에 기입된 메일로 후원증서를 보내드립니다. (메일이 오지 않았을 경우 스팸메일함 확인 부탁드립니다.)</li>
                            <li>후원 게시물 등록 및 후원 완료까지는 1~2주 정도 소요됩니다.<strong>당일 등록및 완료는 불가합니다.</strong></li>
                            <li>홈페이지 및 채널 내 후원 게시물은 20만원 이상부터 게시 됩니다.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='check-list-footer'>
                <h3>위 내용을 숙지하였으며, 계속 진행하길 원하신다면</h3>
                <h3>다음으로 버튼을 눌러주세요</h3>
            </div>
        </div>
    )
}

export default FanClubCheckList