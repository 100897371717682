import React from "react";
import "./MyPageTable.css";
import OneToManyPaginationComponent from "../Support/OneToMany/OneToManyPaginationComponent";

const MyPageTable = ({
  headers,
  data,
  itemsPerPage,
  totalPage,
  currentPage,
  onPageChange,
  onCellClick,
}) => {
  const TOTAL_ROWS = itemsPerPage;

  const filledData = [...data];
  while (filledData.length < TOTAL_ROWS) {
    filledData.push(new Array(headers.length).fill(""));
  }

  const isEmptyTable = data.length === 0; // 데이터가 비어 있는지 확인

  return (
    <div className="table-container">
      <div className="table-header">
        {headers.map((header, index) => (
          <div key={index} className="table-header-cell">
            {header}
          </div>
        ))}
      </div>

      {isEmptyTable ? ( // 데이터가 없을 때 표시할 문구
        <>
          <div className="empty-message">
            최근 후원 내역이 없습니다.
          </div>
          <div className="table-footer-line" />
        </>
      ) : (
        filledData.map((row, rowIndex) => {
          const isEmptyRow = row.every((cell) => !cell);

          // 빈 행은 렌더링하지 않음
          if (isEmptyRow) return null;

          return (
            <div
              key={rowIndex}
              className={`table-row ${isEmptyRow ? 'empty-row' : ''}`}
              style={rowIndex === filledData.length - 1 ? { borderBottom: '1px solid #000' } : {}}
            >
              {row.map((cell, cellIndex) => (
                <div
                  key={cellIndex}
                  className={`table-cell ${cellIndex === 0 ? 'first-column' : ''}`}
                  data-label={headers[cellIndex]} // 헤더 이름 추가
                  onClick={() => onCellClick && onCellClick(rowIndex, cellIndex, cell)}
                  style={{ cursor: cell ? 'pointer' : 'default' }}
                >
                  {cell || " "}
                </div>
              ))}
            </div>
          );
        })
      )}

      <OneToManyPaginationComponent
        currentPage={currentPage}
        totalPages={totalPage}
        onPageChange={onPageChange}
      />
    </div>
  );
};

export default MyPageTable;