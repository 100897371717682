import './AdoptionField.css'

const AdoptionNameField = ({ formData, setFormData }) => {
    return (
        <div className="adoption-name-form-row">
            <label>이름</label>
            <input
                placeholder="정확하게 작성해주세요"
                value={formData.carerName}
                onChange={(e) => setFormData({ ...formData, carerName: e.target.value })}
            />
            <input
                type="checkbox"
                name="isAdult"
                style={{ width: 20 }}
                checked={formData.isAdult || false}
                onChange={(e) => setFormData({ ...formData, isAdult: e.target.checked })}
            />
            <p>만 19세 이상입니다</p>
        </div>
    )
}

export default AdoptionNameField