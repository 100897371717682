import { AnnounceBanner, AnnounceList } from "../../components/Announcement";
import "./AnnouncePage.css";

const AnnouncePage = () => {
  return (
    <>
      <main className="announce-main">
        <AnnounceBanner />
        <AnnounceList />
      </main>
    </>
  );
};
export default AnnouncePage;
