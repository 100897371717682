import React from "react";
import "./News.css";
import DOMPurify from "dompurify";

const NewsItem = ({ imageUrl, title, author, date, content}) => {

  return (
    <div className="news-item-container">
      {imageUrl && <img src={imageUrl} alt={title} />}
      <div className="news-item-text">
        <div className="news-item-text-title">{title}</div>
        <div className="news-item-text-author">
          {author} | <span>{date}</span>
        </div>
        <div className="news-item-text-content"dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(content.substring(0, 100)),
          }}
        />
      </div>
    </div>
  );
};
export default NewsItem;
