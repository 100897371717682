import { Outlet } from "react-router-dom";
import Percent from "../../../components/Main/Percent/percent";
import OneToOneBanner from "../../../components/Support/OneToOne/OneToOneBanner";
import "../../Main/Main.css";

const OneToOnePage = () => {
  return (
    <>
      <main className="md-main">
        <OneToOneBanner />
        <Outlet />
        <Percent />
      </main>
    </>
  );
};
export default OneToOnePage;
