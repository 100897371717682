// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.one-to-one-card {
    padding: 30px 20px;
    font-size: 14px;
    background-color: #fff;
    color: #005bac;
    border-width: 3px;
    border-color: #8c8c8c;
    border-radius: 10px;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Main/OneToOne/OneToOneContent.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;IACf,sBAAsB;IACtB,cAAc;IACd,iBAAiB;IACjB,qBAAqB;IACrB,mBAAmB;IACnB,aAAa;IACb,WAAW;IACX,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".one-to-one-card {\n    padding: 30px 20px;\n    font-size: 14px;\n    background-color: #fff;\n    color: #005bac;\n    border-width: 3px;\n    border-color: #8c8c8c;\n    border-radius: 10px;\n    display: flex;\n    width: 100%;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
