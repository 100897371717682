import './AdoptionField.css'

const AdoptionSnsField = ({ formData, setFormData }) => {
    return (
        <div className='adoption-sns-form-row'>
            <label>SNS 주소<br />
                <strong>(없을 시 작성 X)</strong>
            </label>
            <div className='adoption-sns-input-container'>
                <input
                    placeholder='SNS 주소를 입력해주세요'
                    value={formData.carerSnsAddress}
                    onChange={(e) => setFormData({ ...formData, carerSnsAddress: e.target.value })}
                />
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {['인스타그램', '페이스북', '블로그'].map((sns) => (
                        <div key={sns} className='adoption-sns-radio-container'>
                            <input
                                type="radio"
                                value={sns}
                                checked={formData.carerSns === sns}
                                onChange={(e) => setFormData({ ...formData, carerSns: e.target.value })}
                            />
                            <p>{sns}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default AdoptionSnsField